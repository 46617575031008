import React, { useState, useEffect, useContext } from "react";
import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import FilterSidebar from "../inc/FilterSidebar";
import {
  Container,
  Image,
  Badge,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Fade,
  Table,
  Row,
  Col,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import FilterIcon from "../images/filter.svg";
import { Link } from "react-router-dom";
import { FiMoreVertical } from "react-icons/fi";
import EditIcon from "../images/edit.svg";
import TrashIcon from "../images/trash.svg";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import RowsImg from "../images/rowtable.svg";
import { FaRegCircleCheck, FaCircleCheck } from "react-icons/fa6";
import { FaRegTimesCircle } from "react-icons/fa";
import BoxIcon from "../images/box.svg";
import DeliveryIcon from "../images/delivery.svg";
import MatIcon from "../images/maticon.svg";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import { FiClock } from "react-icons/fi";
import LoadingContext from "../services/LoadingContext";
import consoleService from "../../utility/services/consoleService";

import {
  get_carrier_quotes,
  approve_quote_by_carrier,
  decline_quote_by_carrier,
  delete_quote_by_carrier,
  launch_quote_by_carrier,
  deleteCarrierQuotes
} from "../services/ApiCalls";
import { AppStoreContext } from "../../store/AppStore";
import moment from "moment";
import Loader from "../../common/component/Loader";
import { ToastContainer, toast } from "react-toastify";
import CustomHookFunctions from "../services/CustomHookFunctions";
import ReactPaginate from "react-js-pagination";
import NotificationIcon from "../images/notification.svg";
import SearchIcon from "../images/searchicon.svg";
import { FiMenu } from "react-icons/fi";

const Home = () => {
  const [toggle, setToggle] = useState(false);
  const { setIsLoading } = useContext(LoadingContext);
  const { userDetail, toggleBodyClass } = useContext(AppStoreContext);

  // DATA VARIABLES
  const carrierID = userDetail._id;
  const userRole = userDetail.profile;
  const [quotesList, setQuotesList] = useState([]);
  const [quotesListForFilter, setQuotesListForFilter] = useState([]);

  const [all_orders, setAllOrders] = useState(0);
  const [newQuotes, setNewQuotes] = useState(0);
  const [waitingQuotes, setWaitingQuotes] = useState(0);
  const [readyQuotes, setReadyQuotes] = useState(0);
  const [declinedQuotes, setDeclineQuotes] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const list_row_limit = 10;
  const { Paginated_data, isValidString } = CustomHookFunctions();
  const List_data = Paginated_data(currentPage, list_row_limit, quotesList);


  // LOGICAL VARIABLES

  const [initialLoading, setInitialLoading] = useState(false);
  const isQuoteList = quotesList.length > 0;

  const [searchQuery, setSearchQuery] = useState("");

  const [show_confirm_delete_modal, setShowConfirmDeleteModal] =
    useState(false);
  const [show_confirm_list_modal, setShow_confirm_list_modal] =
    useState(false);

  const [opened_meterial_details_index, setOpenedMaterialIndex] = useState("");

  const [decline_quote_loading, setDeclineQuoteLoading] = useState(false);
  const [launch_mission_loading, setLaunchMissionLoading] = useState(false);
  const [selected_index_of_quotation, setSelectedIndexOfQuotation] =
    useState("");
  const [on_click, setOnClick] = useState("");
  const [accepting_quote_index, setAcceptingQuoteIndex] = useState("");

  const [price_value, setPriceValue] = useState("");

  const [accept_quote_loading, setAcceptQuoteLoading] = useState(false);

  const [selected_quote_payload_for_delete, setSelectedQuotePayloadForDelete] =
    useState(false);

  const [selected_tab_category, setSelectedTabCategory] =
    useState("all orders");

  useEffect(() => {
    if (toggle) {
      document.body.classList.add("filter_sidebar");
    } else {
      document.body.classList.remove("filter_sidebar");
    }
  }, [toggle]);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetch_initial_details(true);
  }, []);

  async function fetch_initial_details(loading, tab_name, req_type) {
    try {
      setIsLoading(loading);
      tab_name && setSelectedTabCategory(tab_name);
      const payload = {
        user_id: userDetail._id,
        req_type: req_type,
      };

      const res = await get_carrier_quotes(payload);
      if (res.status === 1) {
        const fetched_data = res.data;
        setQuotesList([...fetched_data.quotes_list]);
        setQuotesListForFilter([...fetched_data.quotes_list]);
        setAllOrders(fetched_data.all_orders);
        setNewQuotes(fetched_data.new);
        setWaitingQuotes(fetched_data.waiting);
        setReadyQuotes(fetched_data.ready);
        setDeclineQuotes(fetched_data.declined);
        setInitialLoading(true);
        setIsLoading(false);
        return;
      }
    } catch (err) {
      consoleService("error fetch_initial_details fun=  ", err);
    }
  }

  const handleSearch = (searchQuery) => {
    const search_query = searchQuery.toLowerCase();
    if (searchQuery.trim()) {
      const filteredResults = quotesListForFilter.filter((result) => {
        const loadingInfo = result.LOADING_INFORMATIONS[0];
        const deliveryInfo = result.DELIVERY_INFORMATIONS[0];
        const materialInfo = result.MaterialInformation;

        const loading_city = (
          isValidString(loadingInfo.loadingCity)
            ? loadingInfo.loadingCity.toString()
            : ""
        ).toLowerCase();

        const loading_country = (
          isValidString(loadingInfo.loadingCountry)
            ? loadingInfo.loadingCountry.toString()
            : ""
        ).toLowerCase();

        const delivery_city = (
          isValidString(deliveryInfo.deliveryCity)
            ? deliveryInfo.deliveryCity.toString()
            : ""
        ).toLowerCase();

        const delivery_country = (
          isValidString(deliveryInfo.deliveryCountry)
            ? deliveryInfo.deliveryCountry.toString()
            : ""
        ).toLowerCase();

        const delivery_state = (
          isValidString(deliveryInfo.deliveryState)
            ? deliveryInfo.deliveryState.toString()
            : ""
        ).toLowerCase();

        const category = (
          isValidString(result.category) ? result.category.toString() : ""
        ).toLowerCase();

        const total_weight_of_material = () => {
          const total_weight_in_kg = materialInfo.reduce((total, item) => {
            const weight = item.totalWeightOfPallets
              ? Number(item.totalWeightOfPallets)
              : 0;
            return (total = total + weight);
          }, 0);

          const convert_into_ton = total_weight_in_kg / 1000;
          const isInKg = convert_into_ton < 1;
          if (isInKg) {
            return `${total_weight_in_kg} Kg`.toLowerCase();
          } else {
            return `${convert_into_ton.toFixed(1)} T`.toLowerCase();
          }
        };

        const total_count_of_pallet = () => {
          const total_number_of_pallets = materialInfo.reduce((total, item) => {
            const pallets = item.numberOfPallets
              ? Number(item.numberOfPallets)
              : 0;
            return (total = total + pallets);
          }, 0);
          return total_number_of_pallets.toString();
        };

        const matchCondition =
          loading_city.startsWith(search_query) ||
          loading_country.startsWith(search_query) ||
          delivery_city.startsWith(search_query) ||
          delivery_state.startsWith(search_query) ||
          category.startsWith(search_query) ||
          delivery_country.startsWith(search_query) ||
          total_weight_of_material().startsWith(search_query) ||
          total_count_of_pallet().startsWith(search_query);
        if (matchCondition) {
          return result;
        }
      });
      setQuotesList([...filteredResults]);
    } else {
      setQuotesList([...quotesListForFilter]);
    }
  };

  const handleSideFilter = (filter_items) => {
    const isFilter = Object.keys(filter_items).length > 0;
    if (isFilter) {
      const {
        delivery_city,
        delivery_date,
        delivery_time,
        loading_city,
        loading_date,
        loading_time,
        vehicle_category,
      } = filter_items;

      const filteredResults = quotesListForFilter.filter((result) => {
        const loadingInfo = result.LOADING_INFORMATIONS[0];
        const deliveryInfo = result.DELIVERY_INFORMATIONS[0];

        const filter_arr = [];

        // DELIVERY CITY

        if (delivery_city) {
          const found_delivery_city = (
            isValidString(deliveryInfo.deliveryCity)
              ? deliveryInfo.deliveryCity.toString()
              : ""
          ).toLowerCase();

          const delivery_state = (
            isValidString(deliveryInfo.deliveryState)
              ? deliveryInfo.deliveryState.toString()
              : ""
          ).toLowerCase();

          const delivery_country = (
            isValidString(deliveryInfo.deliveryCountry)
              ? deliveryInfo.deliveryCountry.toString()
              : ""
          ).toLowerCase();

          const matchCondition =
            delivery_city == found_delivery_city ||
            delivery_city == delivery_state ||
            delivery_city == delivery_country;
          filter_arr.push(matchCondition);
        }

        // DELIVERY DATE

        if (delivery_date) {
          const found_delivery_date = (
            isValidString(deliveryInfo.delivery_Date)
              ? deliveryInfo.delivery_Date.toString()
              : ""
          ).toLowerCase();

          const matchCondition = delivery_date == found_delivery_date;
          filter_arr.push(matchCondition);
        }

        // DELIVERY TIME

        if (delivery_time) {
          const found_delivery_time = (
            isValidString(deliveryInfo.delivery_Hour)
              ? deliveryInfo.delivery_Hour.toString()
              : ""
          ).toLowerCase();

          const matchCondition = delivery_time == found_delivery_time;
          filter_arr.push(matchCondition);
        }

        // LOADING CITY

        if (loading_city) {
          const found_loading_city = (
            isValidString(loadingInfo.loadingCity)
              ? loadingInfo.loadingCity.toString()
              : ""
          ).toLowerCase();

          const found_loading_state = (
            isValidString(loadingInfo.loadingState)
              ? loadingInfo.loadingState.toString()
              : ""
          ).toLowerCase();

          const loading_country = (
            isValidString(loadingInfo.loadingCountry)
              ? loadingInfo.loadingCountry.toString()
              : ""
          ).toLowerCase();

          const matchCondition =
            loading_city == found_loading_city ||
            loading_city == found_loading_state ||
            loading_city == loading_country;
          filter_arr.push(matchCondition);
        }

        // LOADING DATE

        if (loading_date) {
          const found_loading_date = (
            isValidString(loadingInfo.Date) ? loadingInfo.Date.toString() : ""
          ).toLowerCase();

          const matchCondition = loading_date == found_loading_date;
          filter_arr.push(matchCondition);
        }

        // LOADING TIME

        if (loading_time) {
          const found_loading_time = (
            isValidString(loadingInfo.Hours) ? loadingInfo.Hours.toString() : ""
          ).toLowerCase();

          const matchCondition = loading_time == found_loading_time;
          filter_arr.push(matchCondition);
        }

        // VEHICLE CATEGORY

        if (vehicle_category) {
          const found_category = (
            isValidString(result.category) ? result.category.toString() : ""
          ).toLowerCase();

          const matchCondition = vehicle_category == found_category;
          filter_arr.push(matchCondition);
        }

        const Final_condition = filter_arr.every((condition) => {
          return condition == true;
        });

        if (Final_condition) {
          return result;
        }
      });

      setQuotesList([...filteredResults]);
    } else {
      setQuotesList([...quotesListForFilter]);
    }
  };

  const get_tag_class_name = (status) => {
    let className = "";

    switch (status) {
      case "New":
        className = "newstate";
        break;
      case "Ready":
        className = "newstate";
        break;
      case "Request":
        className = "request";
        break;
      case "Waiting":
        className = "waiting";
        break;
      case "Declined":
        className = "declined";
        break;
    }
    return className;
  };

  const get_quote_status_icon = (status) => {
    consoleService("status= ", status);
    let svg_icon = "";
    switch (status) {
      case "New":
        svg_icon = (
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M7.00584 13.2658C6.63834 13.2658 6.27667 13.1433 5.99084 12.8983L5.06917 12.1108C4.97584 12.0292 4.74251 11.9475 4.62001 11.9475H3.59917C2.73584 11.9475 2.03584 11.2475 2.03584 10.3842V9.38667C2.03584 9.26417 1.95417 9.03083 1.87834 8.94333L1.08501 8.01C0.606674 7.44417 0.606674 6.5575 1.08501 5.98583L1.87834 5.0525C1.95417 4.965 2.03584 4.73167 2.03584 4.60917V3.6175C2.03584 2.75417 2.73584 2.05417 3.59917 2.05417H4.60834C4.73084 2.05417 4.95834 1.96667 5.05751 1.885L5.97917 1.0975C6.55084 0.613333 7.44334 0.613333 8.01501 1.0975L8.93667 1.885C9.03001 1.96667 9.26917 2.04833 9.39167 2.04833H10.3833C11.2467 2.04833 11.9467 2.74833 11.9467 3.61167V4.60333C11.9467 4.72583 12.0342 4.95333 12.1158 5.0525L12.9033 5.97417C13.3933 6.55167 13.3875 7.44417 12.9033 8.01L12.1158 8.93167C12.0342 9.03083 11.9525 9.25833 11.9525 9.38083V10.3725C11.9525 11.2358 11.2525 11.9358 10.3892 11.9358H9.39751C9.27501 11.9358 9.04751 12.0233 8.94251 12.105L8.02084 12.8925C7.73501 13.1433 7.36751 13.2658 7.00584 13.2658ZM3.59917 2.92917C3.22001 2.92917 2.91084 3.23833 2.91084 3.6175V4.60917C2.91084 4.94167 2.75917 5.36167 2.54334 5.61833L1.75001 6.55167C1.55167 6.79083 1.55167 7.21083 1.75001 7.44417L2.53751 8.37167C2.74751 8.61083 2.90501 9.04833 2.90501 9.38083V10.3783C2.90501 10.7575 3.21417 11.0667 3.59334 11.0667H4.60834C4.93501 11.0667 5.36667 11.2242 5.62334 11.44L6.55084 12.2333C6.79001 12.4375 7.21001 12.4375 7.44917 12.2333L8.37084 11.4458C8.63334 11.2242 9.05917 11.0725 9.38584 11.0725H10.3775C10.7567 11.0725 11.0658 10.7633 11.0658 10.3842V9.3925C11.0658 9.06583 11.2233 8.64 11.4392 8.3775L12.2325 7.45C12.4367 7.21083 12.4367 6.79083 12.2325 6.55167L11.445 5.63C11.2233 5.3675 11.0717 4.94167 11.0717 4.615V3.6175C11.0717 3.23833 10.7625 2.92917 10.3833 2.92917H9.39167C9.05917 2.92917 8.62751 2.77167 8.37084 2.55583L7.44334 1.7625C7.20417 1.55833 6.79001 1.55833 6.54501 1.7625L5.62917 2.55583C5.36667 2.77167 4.94084 2.92917 4.60834 2.92917H3.59917Z"
              fill="#27AE60"
            />
            <path
              d="M6.99999 9.8405C6.67916 9.8405 6.41666 9.57799 6.41666 9.25716C6.41666 8.93633 6.67332 8.67383 6.99999 8.67383C7.32082 8.67383 7.58332 8.93633 7.58332 9.25716C7.58332 9.57799 7.32666 9.8405 6.99999 9.8405Z"
              fill="#27AE60"
            />
            <path
              d="M7 8.00302C6.76083 8.00302 6.5625 7.80469 6.5625 7.56552V4.74219C6.5625 4.50302 6.76083 4.30469 7 4.30469C7.23917 4.30469 7.4375 4.50302 7.4375 4.74219V7.55969C7.4375 7.80469 7.245 8.00302 7 8.00302Z"
              fill="#27AE60"
            />
          </svg>
        );
        break;
      case "Ready":
        svg_icon = <FaRegCircleCheck />;
        break;
      case "Request":
        svg_icon = (
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M7.81667 10.1612H6.3525C5.39583 10.1612 4.62 9.35622 4.62 8.36456C4.62 8.12539 4.81833 7.92706 5.0575 7.92706C5.29667 7.92706 5.495 8.12539 5.495 8.36456C5.495 8.87206 5.88 9.28622 6.3525 9.28622H7.81667C8.19583 9.28622 8.51083 8.94789 8.51083 8.53956C8.51083 8.03206 8.33 7.93289 8.0325 7.82789L5.68167 6.99956C5.22667 6.84206 4.61417 6.50372 4.61417 5.45956C4.61417 4.56122 5.32 3.83789 6.18333 3.83789H7.6475C8.60417 3.83789 9.38 4.64289 9.38 5.63456C9.38 5.87372 9.18167 6.07206 8.9425 6.07206C8.70333 6.07206 8.505 5.87372 8.505 5.63456C8.505 5.12706 8.12 4.71289 7.6475 4.71289H6.18333C5.80417 4.71289 5.48917 5.05122 5.48917 5.45956C5.48917 5.96706 5.67 6.06622 5.9675 6.17122L8.31833 6.99956C8.77333 7.15706 9.38583 7.49539 9.38583 8.53956C9.38 9.43206 8.68 10.1612 7.81667 10.1612Z"
              fill="#2F80ED"
            />
            <path
              d="M7 10.9375C6.76083 10.9375 6.5625 10.7392 6.5625 10.5V3.5C6.5625 3.26083 6.76083 3.0625 7 3.0625C7.23917 3.0625 7.4375 3.26083 7.4375 3.5V10.5C7.4375 10.7392 7.23917 10.9375 7 10.9375Z"
              fill="#2F80ED"
            />
            <path
              d="M6.99999 13.2702C3.54082 13.2702 0.729156 10.4585 0.729156 6.99935C0.729156 3.54018 3.54082 0.728516 6.99999 0.728516C10.4592 0.728516 13.2708 3.54018 13.2708 6.99935C13.2708 10.4585 10.4592 13.2702 6.99999 13.2702ZM6.99999 1.60352C4.02499 1.60352 1.60416 4.02435 1.60416 6.99935C1.60416 9.97435 4.02499 12.3952 6.99999 12.3952C9.97499 12.3952 12.3958 9.97435 12.3958 6.99935C12.3958 4.02435 9.97499 1.60352 6.99999 1.60352Z"
              fill="#2F80ED"
            />
          </svg>
        );
        break;
      case "Waiting":
        svg_icon = (
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M6.99999 13.2702C3.54082 13.2702 0.729156 10.4585 0.729156 6.99935C0.729156 3.54018 3.54082 0.728516 6.99999 0.728516C10.4592 0.728516 13.2708 3.54018 13.2708 6.99935C13.2708 10.4585 10.4592 13.2702 6.99999 13.2702ZM6.99999 1.60352C4.02499 1.60352 1.60416 4.02435 1.60416 6.99935C1.60416 9.97435 4.02499 12.3952 6.99999 12.3952C9.97499 12.3952 12.3958 9.97435 12.3958 6.99935C12.3958 4.02435 9.97499 1.60352 6.99999 1.60352Z"
              fill="#FF9900"
            />
            <path
              d="M9.16418 9.29253C9.08834 9.29253 9.01251 9.27503 8.94251 9.22836L7.13417 8.14919C6.68501 7.88086 6.35251 7.29169 6.35251 6.77253V4.38086C6.35251 4.14169 6.55084 3.94336 6.79001 3.94336C7.02918 3.94336 7.22751 4.14169 7.22751 4.38086V6.77253C7.22751 6.98253 7.40251 7.29169 7.58334 7.39669L9.39168 8.47586C9.60168 8.59836 9.66584 8.86669 9.54334 9.07669C9.45584 9.21669 9.31001 9.29253 9.16418 9.29253Z"
              fill="#FF9900"
            />
          </svg>
        );
        break;
      case "Declined":
        svg_icon = (
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M6.99999 13.2702C3.54082 13.2702 0.729156 10.4585 0.729156 6.99935C0.729156 3.54018 3.54082 0.728516 6.99999 0.728516C10.4592 0.728516 13.2708 3.54018 13.2708 6.99935C13.2708 10.4585 10.4592 13.2702 6.99999 13.2702ZM6.99999 1.60352C4.02499 1.60352 1.60416 4.02435 1.60416 6.99935C1.60416 9.97435 4.02499 12.3952 6.99999 12.3952C9.97499 12.3952 12.3958 9.97435 12.3958 6.99935C12.3958 4.02435 9.97499 1.60352 6.99999 1.60352Z"
              fill="#EB5757"
            />
            <path
              d="M5.34916 9.08927C5.23832 9.08927 5.12749 9.04844 5.03999 8.96094C4.87082 8.79177 4.87082 8.51177 5.03999 8.3426L8.34166 5.04094C8.51082 4.87177 8.79082 4.87177 8.95999 5.04094C9.12916 5.2101 9.12916 5.4901 8.95999 5.65927L5.65832 8.96094C5.57666 9.04844 5.45999 9.08927 5.34916 9.08927Z"
              fill="#EB5757"
            />
            <path
              d="M8.65082 9.08927C8.53999 9.08927 8.42916 9.04844 8.34166 8.96094L5.03999 5.65927C4.87082 5.4901 4.87082 5.2101 5.03999 5.04094C5.20916 4.87177 5.48916 4.87177 5.65832 5.04094L8.95999 8.3426C9.12916 8.51177 9.12916 8.79177 8.95999 8.96094C8.87249 9.04844 8.76166 9.08927 8.65082 9.08927Z"
              fill="#EB5757"
            />
          </svg>
        );
        break;
    }
    return svg_icon;
  };

  const decline_quotation_fun = async (quote_id) => {
    try {
      setOnClick("decline");
      setDeclineQuoteLoading(true);
      const payload = {
        carrier_id: carrierID,
        quote_id,
      };

      const res = await decline_quote_by_carrier(payload);
      if (res.status === 1) {
        await fetch_initial_details(false);
        setDeclineQuoteLoading(false);
        setOnClick("");
        toast("Declined successfully");
      }
    } catch (err) {
      consoleService("decline_quotation_fun error  = ", err);
    }
  };

  const acceptQuoteRequest = async (quotation_id, price, index) => {
    try {
      toast.dismiss();
      if (!price.trim()) return toast.error("Price should not empty.");
      const payload = {
        carrier_id: carrierID,
        quote_id: quotation_id,
        price,
      };

      setAcceptQuoteLoading(true);
      setAcceptingQuoteIndex(index);
      setSelectedIndexOfQuotation("");

      const res = await approve_quote_by_carrier(payload);

      if (res.status === 1) {
        await fetch_initial_details(false);
        setAcceptQuoteLoading(false);
        setAcceptingQuoteIndex("");
        setPriceValue("");
        setOnClick("");
        toast("Accept successfully");
      }
    } catch (err) {
      consoleService("error = acceptQuoteRequest ", err);
    }
  };

  const handleOnDeletePress = (quotation_id) => {
    const payload = {
      carrier_id: carrierID,
      quote_id: quotation_id,
    };
    setSelectedQuotePayloadForDelete(payload);
    setShowConfirmDeleteModal(true);
  };

  const launch_mission_fun = async (quotation_id, i) => {
    try {
      setOnClick("launch");
      setLaunchMissionLoading(true);
      setSelectedIndexOfQuotation(i);
      const payload = { carrier_id: carrierID, quote_id: quotation_id };
      const res = await launch_quote_by_carrier(payload);
      if (res.status === 1) {
        await fetch_initial_details(false);
        setLaunchMissionLoading(false);
        setOnClick("");
        toast("Launched successfully.");
      } else {
        consoleService("error in launch_mission_fun = ", res.message);
      }
    } catch (err) {
      consoleService("error in launch_mission_fun = ", err);
    }
  };

  const get_tab_class_name = (tab) => {
    if (selected_tab_category == tab) {
      return "active";
    } else {
      return "";
    }
  };



  // MODALS

  const ConfirmDeleteModal = (props) => {
    const [deleteQuoteLoading, setDeleteQuoteLoading] = useState(false);

    const delete_quote_fun = async () => {
      try {
        setDeleteQuoteLoading(true);
        const res = await delete_quote_by_carrier(
          selected_quote_payload_for_delete
        );
        if (res.status === 1) {
          await fetch_initial_details(false);
          setDeclineQuoteLoading(false);
          setShowConfirmDeleteModal(false);
          toast("Deleted Successfully.");
        }
      } catch (err) {
        consoleService("error in delete_quote_fun = ", err);
      }
    };
    return (
      <Modal {...props}>
        <Modal.Body className="p-3">
          <div className="deleteModal text-center">
            {/* <XCircle className="deletemodal_icon" /> */}
            <h2>Are You Sure ?</h2>
            <p>You will not be able to recover the deleted record!</p>
            <div className="btn-cancel-ok">
              <Button
                className="btn-cancel me-1"
                disabled={deleteQuoteLoading}
                onClick={() => {
                  setShowConfirmDeleteModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ width: 95 }}
                className="btn-ok"
                onClick={() => {
                  delete_quote_fun();
                }}
                disabled={deleteQuoteLoading}
              >
                {deleteQuoteLoading ? <Loader /> : "OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const [delID, setdelID] = useState(null);

  const ConfirmToDeleteListRecord = (props) => {
    const [deleteQuoteLoading, setDeleteQuoteLoading] = useState(false);
    const handleDelete = async (id) => {
      try {
        setDeleteQuoteLoading(true);
        const payload = { _id: id, userId: carrierID };
        await deleteCarrierQuotes(payload);
        toast.success("Deleted");
        setDeleteQuoteLoading(false);
        setShow_confirm_list_modal(false)
        fetch_initial_details(true);
      } catch (error) {
        console.error("Error while deleting from the list: ", error);
        toast.error("Failed to delete");
      }
    };

    return (
      <Modal {...props}>
        <Modal.Body className="p-3">
          <div className="deleteModal text-center">
            {/* <XCircle className="deletemodal_icon" /> */}
            <h2>Are You Sure ?</h2>
            <p>You will not be able to recover the deleted record!</p>
            <div className="btn-cancel-ok">
              <Button
                className="btn-cancel me-1"
                disabled={deleteQuoteLoading}
                onClick={() => {
                  setShow_confirm_list_modal(false)
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ width: 95 }}
                className="btn-ok"
                onClick={() => {
                  handleDelete(delID);
                }}
                disabled={deleteQuoteLoading}
              >
                {deleteQuoteLoading ? <Loader /> : "OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };



  // MAIN RETURN

  return (
    <React.Fragment>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          <div className="topheader">
            <div className="container-fluid">
              <div className="topheader_flex">
                <Button
                  variant="primary"
                  className="menuBtn"
                  onClick={toggleBodyClass}
                >
                  <FiMenu />
                </Button>
                <div className="inPutBox">
                  <Form.Control
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(text) => {
                      handleSearch(text.target.value);
                      setSearchQuery(text.target.value);
                    }}
                  />
                  <Image src={SearchIcon} alt="" />
                </div>
                <Link className="notiIcon">
                  <Image src={NotificationIcon} alt="" />
                  <Badge bg="danger">5</Badge>
                </Link>
              </div>
            </div>
          </div>
          <FilterSidebar
            close={handleToggle}
            handleFilterFun={handleSideFilter}
          />
          {initialLoading ? (
            <Container fluid className="pt-100">
              <div className="quotes_div">
                <div className="orderHeaderFlex mb-3">
                  <div className="orderHeader">
                    <h2
                      className={get_tab_class_name("all orders")}
                      onClick={() => {
                        fetch_initial_details(true, "all orders", "");
                      }}
                    >
                      All orders{" "}
                      <Badge pill bg="secondary">
                        {all_orders}
                      </Badge>
                    </h2>
                    <h2
                      className={get_tab_class_name("new")}
                      onClick={() => {
                        fetch_initial_details(true, "new", "New");
                      }}
                    >
                      New{" "}
                      <Badge pill bg="secondary">
                        {newQuotes}
                      </Badge>
                    </h2>
                    {/* <h2
                      className={get_tab_class_name("request")}
                      onClick={() => {
                        fetch_initial_details(true, "request", "Request");
                      }}
                    >
                      Request{" "}
                      <Badge pill bg="secondary">
                        0
                      </Badge>
                    </h2> */}
                    <h2
                      className={get_tab_class_name("waiting")}
                      onClick={() => {
                        fetch_initial_details(true, "waiting", "Waiting");
                      }}
                    >
                      Waiting{" "}
                      <Badge pill bg="secondary">
                        {waitingQuotes}
                      </Badge>
                    </h2>
                    <h2
                      className={get_tab_class_name("ready")}
                      onClick={() => {
                        fetch_initial_details(true, "ready", "Ready");
                      }}
                    >
                      Ready{" "}
                      <Badge pill bg="secondary">
                        {readyQuotes}
                      </Badge>
                    </h2>
                    <h2
                      className={get_tab_class_name("declined")}
                      onClick={() => {
                        fetch_initial_details(true, "declined", "Declined");
                      }}
                    >
                      Declined{" "}
                      <Badge pill bg="secondary">
                        {declinedQuotes}
                      </Badge>
                    </h2>
                  </div>
                  <div className="fiphone">
                    <Link onClick={handleToggle} className="filter_btn">
                      <Image src={FilterIcon} width={20} alt="" /> Filters
                    </Link>
                  </div>
                </div>
                <div className="react_table">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th></th>
                        <th>STATE</th>
                        <th>LOADING</th>
                        <th>ETA</th>
                        <th>VEHICLE</th>
                        <th>MATERIAL</th>
                        <th style={{ width: "230px", minWidth: "230px" }}>
                          ACTIONS
                        </th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    {!isQuoteList && (
                      <tr>
                        <td colSpan={9} className="text-center  pt-3 ">
                          No quotes
                        </td>
                      </tr>
                    )}
                    <tbody>
                      {List_data.result.map((item, i) => {

                        const Quotation_id = item._id;
                        const category = item.category;
                        const isCategoryTanker = category == "Tanker";
                        const isCategoryRefrigerated =
                          category == "Refrigerated";
                        const isCategoryGeneralCargo =
                          category == "General cargo";
                        const LoadingPointDetails =
                          item.LOADING_INFORMATIONS[0];
                        const delevryPointDetails =
                          item.DELIVERY_INFORMATIONS[0];
                        const materialInfo = item.MaterialInformation;
                        const total_weight_of_material = () => {
                          const total_weight_in_kg = materialInfo.reduce(
                            (total, item) => {
                              const weight = item.totalWeightOfPallets
                                ? Number(item.totalWeightOfPallets)
                                : 0;
                              return (total = total + weight);
                            },
                            0
                          );

                          const convert_into_ton = total_weight_in_kg / 1000;
                          const isInKg = convert_into_ton < 1;
                          if (isInKg) {
                            return `${total_weight_in_kg} Kg`;
                          } else {
                            return `${convert_into_ton.toFixed(1)} T`;
                          }
                        };

                        const total_weight_of_tanker_material = () => {
                          const total_weight_in_kg = materialInfo.reduce(
                            (total, item) => {
                              const weight = item.quantity
                                ? Number(item.quantity)
                                : 0;
                              return (total = total + weight);
                            },
                            0
                          );

                          return `${total_weight_in_kg.toFixed(0)} L`;
                        };

                        const total_count_of_pallet = () => {
                          const total_number_of_pallets = materialInfo.reduce(
                            (total, item) => {
                              const pallets = item.numberOfPallets
                                ? Number(item.numberOfPallets)
                                : 0;
                              return (total = total + pallets);
                            },
                            0
                          );
                          return total_number_of_pallets;
                        };

                        const loadingTime =
                          LoadingPointDetails && LoadingPointDetails.Hours;
                        const loadingConvertedTime = LoadingPointDetails.Hours
                          ? loadingTime.replace(":", "h")
                          : "";

                        const deliveryTime =
                          delevryPointDetails &&
                          delevryPointDetails.delivery_Hour;

                        const deliveryConvertedTime =
                          delevryPointDetails.delivery_Hour
                            ? deliveryTime.replace(":", "h")
                            : "";

                        const oldstate =
                          LoadingPointDetails && LoadingPointDetails.loadingCity
                            ? LoadingPointDetails.loadingCity
                            : LoadingPointDetails.loadingState;

                        const state =
                          LoadingPointDetails
                            ? LoadingPointDetails.loadingCity
                              ? LoadingPointDetails.loadingPostcode
                                ? `${LoadingPointDetails.loadingCity} ${LoadingPointDetails.loadingPostcode}`
                                : `${LoadingPointDetails.loadingCity}`
                              : LoadingPointDetails.loadingState
                                ? LoadingPointDetails.loadingPostcode
                                  ? `${LoadingPointDetails.loadingState} ${LoadingPointDetails.loadingPostcode}`
                                  : `${LoadingPointDetails.loadingState}`
                                : ''
                            : '';

                        const country =
                          LoadingPointDetails &&
                          LoadingPointDetails.loadingCountry &&
                          LoadingPointDetails.loadingCountry;

                        const olddelivery_state =
                          delevryPointDetails &&
                            delevryPointDetails.deliveryCity
                            ? delevryPointDetails.deliveryCity
                            : delevryPointDetails.deliveryState;

                        const delivery_state =
                          delevryPointDetails
                            ? delevryPointDetails.deliveryCity
                              ? delevryPointDetails.deliveryPostcode
                                ? `${delevryPointDetails.deliveryCity} ${delevryPointDetails.deliveryPostcode}`
                                : `${delevryPointDetails.deliveryCity}`
                              : delevryPointDetails.deliveryState
                                ? delevryPointDetails.deliveryPostcode
                                  ? `${delevryPointDetails.deliveryState} ${delevryPointDetails.deliveryPostcode}`
                                  : `${delevryPointDetails.deliveryState}`
                                : ''
                            : '';


                        const delivery_country =
                          delevryPointDetails &&
                          delevryPointDetails.deliveryCountry &&
                          delevryPointDetails.deliveryCountry;

                        const quoteStatus = item.quote_status;
                        const isNewQuote = quoteStatus == "New";
                        const isWaitingForConfermation =
                          quoteStatus == "Waiting";
                        const isQuoteNotAccepted = quoteStatus == "Declined";
                        const isReady = quoteStatus == "Ready";

                        const string_index = i.toString();

                        const isOpen =
                          opened_meterial_details_index == string_index;

                        const isAcceptPress = on_click == "accept";
                        const isLaunchPress = on_click == "launch";
                        const isDeclinePress = on_click == "decline";

                        const isIntendForAccept =
                          selected_index_of_quotation == string_index &&
                          isAcceptPress;

                        const accept_btn_loading =
                          accept_quote_loading &&
                          accepting_quote_index == string_index;

                        const decline_btn_loading =
                          decline_quote_loading &&
                          selected_index_of_quotation == string_index &&
                          isDeclinePress;

                        const launch_btn_loading =
                          launch_mission_loading &&
                          selected_index_of_quotation == string_index &&
                          isLaunchPress;

                        const carrierInQuote = item.carrier_in_quote;
                        const approved_price = carrierInQuote.Price;
                        const declinedBy = carrierInQuote.declined_by;
                        const isDeclinedByCarrier = declinedBy == carrierID;

                        return (
                          <React.Fragment>
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                <div
                                  className={`badegCus ${get_tag_class_name(
                                    quoteStatus
                                  )}`}
                                >
                                  {get_quote_status_icon(quoteStatus)}
                                  {quoteStatus}
                                </div>
                              </td>
                              <td>
                                <h4 className="name_text mb-1">
                                  {oldstate}
                                  {oldstate && country && "-"}
                                  {country}
                                </h4>
                                <h4 className="datetime_text">
                                  {moment(LoadingPointDetails.Date).format(
                                    "DD.MM.YYYY"
                                  )}{" "}
                                  -{loadingConvertedTime}
                                </h4>
                              </td>
                              <td>
                                <h4 className="name_text mb-1">
                                  {olddelivery_state}
                                  {olddelivery_state && delivery_country && "-"}
                                  {delivery_country}
                                </h4>
                                <h4 className="datetime_text">
                                  {moment(
                                    delevryPointDetails.delivery_Date
                                  ).format("DD.MM.YYYY")}{" "}
                                  -{deliveryConvertedTime}
                                </h4>
                              </td>
                              <td>
                                <h4 className="name_text">{category}</h4>
                              </td>
                              {isCategoryGeneralCargo && (
                                <td>
                                  <div className="materialBox">
                                    <div className="matInfoBoxItems">
                                      <div className="fixWith">
                                        <span>
                                          <svg
                                            width="21"
                                            height="21"
                                            viewBox="0 0 21 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M10.344 10.4002C9.89396 10.4002 9.43563 10.3168 9.07729 10.1585L4.16063 7.97516C2.91063 7.41683 2.72729 6.66683 2.72729 6.2585C2.72729 5.85016 2.91063 5.10016 4.16063 4.54183L9.07729 2.3585C9.80229 2.0335 10.894 2.0335 11.619 2.3585L16.544 4.54183C17.7856 5.09183 17.9773 5.85016 17.9773 6.2585C17.9773 6.66683 17.794 7.41683 16.544 7.97516L11.619 10.1585C11.2523 10.3252 10.8023 10.4002 10.344 10.4002ZM10.344 3.36683C10.0606 3.36683 9.78563 3.4085 9.58563 3.50016L4.66896 5.6835C4.16063 5.91683 3.97729 6.15016 3.97729 6.2585C3.97729 6.36683 4.16063 6.6085 4.66063 6.8335L9.57729 9.01683C9.97729 9.19183 10.7023 9.19183 11.1023 9.01683L16.0273 6.8335C16.5356 6.6085 16.719 6.36683 16.719 6.2585C16.719 6.15016 16.5356 5.9085 16.0273 5.6835L11.1106 3.50016C10.9106 3.41683 10.6273 3.36683 10.344 3.36683Z"
                                              fill="#1D244A"
                                            />
                                            <path
                                              d="M10.519 14.7415C10.2024 14.7415 9.88571 14.6748 9.58571 14.5415L3.92738 12.0248C3.06904 11.6498 2.39404 10.6082 2.39404 9.6665C2.39404 9.32484 2.67738 9.0415 3.01904 9.0415C3.36071 9.0415 3.64404 9.32484 3.64404 9.6665C3.64404 10.1248 4.01904 10.6998 4.43571 10.8915L10.094 13.4082C10.3607 13.5248 10.669 13.5248 10.944 13.4082L16.6024 10.8915C17.019 10.7082 17.394 10.1248 17.394 9.6665C17.394 9.32484 17.6774 9.0415 18.019 9.0415C18.3607 9.0415 18.644 9.32484 18.644 9.6665C18.644 10.6082 17.969 11.6498 17.1107 12.0332L11.4524 14.5498C11.1524 14.6748 10.8357 14.7415 10.519 14.7415Z"
                                              fill="#1D244A"
                                            />
                                            <path
                                              d="M10.519 18.9085C10.2024 18.9085 9.88571 18.8419 9.58571 18.7085L3.92738 16.1919C2.99404 15.7752 2.39404 14.8502 2.39404 13.8252C2.39404 13.4835 2.67738 13.2002 3.01904 13.2002C3.36071 13.2002 3.64404 13.4919 3.64404 13.8335C3.64404 14.3585 3.95238 14.8419 4.43571 15.0585L10.094 17.5752C10.3607 17.6919 10.669 17.6919 10.944 17.5752L16.6024 15.0585C17.0857 14.8419 17.394 14.3669 17.394 13.8335C17.394 13.4919 17.6774 13.2085 18.019 13.2085C18.3607 13.2085 18.644 13.4919 18.644 13.8335C18.644 14.8585 18.044 15.7835 17.1107 16.2002L11.4524 18.7169C11.1524 18.8419 10.8357 18.9085 10.519 18.9085Z"
                                              fill="#1D244A"
                                            />
                                          </svg>
                                          <h3>{total_count_of_pallet()}</h3>
                                        </span>
                                      </div>
                                      <div className="fixWith dividerbox">
                                        <div className="divider"></div>
                                      </div>
                                      <div className="fixWith">
                                        <h3>{total_weight_of_material()}</h3>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              )}
                              {isCategoryTanker && (
                                <td>
                                  <h3 className="name_text">
                                    {total_weight_of_tanker_material()}
                                  </h3>
                                </td>
                              )}
                              {isCategoryRefrigerated && (
                                <td>
                                  <div className="materialBox">
                                    <div className="matInfoBoxItems">
                                      <div className="fixWith">
                                        <span>
                                          <svg
                                            width="21"
                                            height="21"
                                            viewBox="0 0 21 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M10.344 10.4002C9.89396 10.4002 9.43563 10.3168 9.07729 10.1585L4.16063 7.97516C2.91063 7.41683 2.72729 6.66683 2.72729 6.2585C2.72729 5.85016 2.91063 5.10016 4.16063 4.54183L9.07729 2.3585C9.80229 2.0335 10.894 2.0335 11.619 2.3585L16.544 4.54183C17.7856 5.09183 17.9773 5.85016 17.9773 6.2585C17.9773 6.66683 17.794 7.41683 16.544 7.97516L11.619 10.1585C11.2523 10.3252 10.8023 10.4002 10.344 10.4002ZM10.344 3.36683C10.0606 3.36683 9.78563 3.4085 9.58563 3.50016L4.66896 5.6835C4.16063 5.91683 3.97729 6.15016 3.97729 6.2585C3.97729 6.36683 4.16063 6.6085 4.66063 6.8335L9.57729 9.01683C9.97729 9.19183 10.7023 9.19183 11.1023 9.01683L16.0273 6.8335C16.5356 6.6085 16.719 6.36683 16.719 6.2585C16.719 6.15016 16.5356 5.9085 16.0273 5.6835L11.1106 3.50016C10.9106 3.41683 10.6273 3.36683 10.344 3.36683Z"
                                              fill="#1D244A"
                                            />
                                            <path
                                              d="M10.519 14.7415C10.2024 14.7415 9.88571 14.6748 9.58571 14.5415L3.92738 12.0248C3.06904 11.6498 2.39404 10.6082 2.39404 9.6665C2.39404 9.32484 2.67738 9.0415 3.01904 9.0415C3.36071 9.0415 3.64404 9.32484 3.64404 9.6665C3.64404 10.1248 4.01904 10.6998 4.43571 10.8915L10.094 13.4082C10.3607 13.5248 10.669 13.5248 10.944 13.4082L16.6024 10.8915C17.019 10.7082 17.394 10.1248 17.394 9.6665C17.394 9.32484 17.6774 9.0415 18.019 9.0415C18.3607 9.0415 18.644 9.32484 18.644 9.6665C18.644 10.6082 17.969 11.6498 17.1107 12.0332L11.4524 14.5498C11.1524 14.6748 10.8357 14.7415 10.519 14.7415Z"
                                              fill="#1D244A"
                                            />
                                            <path
                                              d="M10.519 18.9085C10.2024 18.9085 9.88571 18.8419 9.58571 18.7085L3.92738 16.1919C2.99404 15.7752 2.39404 14.8502 2.39404 13.8252C2.39404 13.4835 2.67738 13.2002 3.01904 13.2002C3.36071 13.2002 3.64404 13.4919 3.64404 13.8335C3.64404 14.3585 3.95238 14.8419 4.43571 15.0585L10.094 17.5752C10.3607 17.6919 10.669 17.6919 10.944 17.5752L16.6024 15.0585C17.0857 14.8419 17.394 14.3669 17.394 13.8335C17.394 13.4919 17.6774 13.2085 18.019 13.2085C18.3607 13.2085 18.644 13.4919 18.644 13.8335C18.644 14.8585 18.044 15.7835 17.1107 16.2002L11.4524 18.7169C11.1524 18.8419 10.8357 18.9085 10.519 18.9085Z"
                                              fill="#1D244A"
                                            />
                                          </svg>
                                          <h3>{total_count_of_pallet()}</h3>
                                        </span>
                                      </div>
                                      <div className="fixWith dividerbox">
                                        <div className="divider"></div>
                                      </div>
                                      <div className="fixWith">
                                        <h3>{total_weight_of_material()}</h3>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              )}
                              <td>
                                {isNewQuote && !isIntendForAccept && (
                                  <div className="actionBtn">
                                    <Button
                                      style={{
                                        width: 105,
                                        padding: decline_btn_loading ? 4 : null,
                                      }}
                                      onClick={() => {
                                        decline_quotation_fun(Quotation_id);
                                      }}
                                      disabled={decline_btn_loading}
                                      variant="danger"

                                    >
                                      {decline_btn_loading ? (
                                        <Loader size={25} />
                                      ) : (
                                        <>
                                          <FaRegTimesCircle /> Decline
                                        </>
                                      )}
                                    </Button>

                                    <Button
                                      style={{
                                        width: 105,
                                        padding: accept_btn_loading ? 4 : null,
                                      }}
                                      onClick={() => {
                                        setSelectedIndexOfQuotation(i);
                                        setOnClick("accept");
                                      }}
                                      disabled={accept_btn_loading}
                                      variant="success"
                                      className="ms-2"
                                    >
                                      {accept_btn_loading ? (
                                        <Loader size={25} />
                                      ) : (
                                        <>
                                          <FaRegCircleCheck /> Accept
                                        </>
                                      )}
                                    </Button>


                                  </div>
                                )}
                                {isWaitingForConfermation && (
                                  <div className="carrier_name">
                                    <span
                                      className="waiting_text"
                                      style={{
                                        display: 'block',
                                        textAlign: 'center',
                                        width: '100%',
                                        lineHeight: '1.5',
                                      }}
                                    >
                                      <FiClock /> Waiting for confirmation <br /> {approved_price}€
                                    </span>

                                  </div>
                                )}
                                {isQuoteNotAccepted && (
                                  <div className="carrier_name">
                                    <span
                                      className="declined_text"
                                      style={{
                                        display: 'block',
                                        textAlign: 'center',
                                        width: '100%',
                                        lineHeight: '1.5',
                                      }}
                                    >
                                      <FiClock />
                                      {isDeclinedByCarrier
                                        ? "Quote not accepted"
                                        // : "Quote declined by user"}
                                        : "Quote non accepted"}  <br />  {approved_price}€
                                    </span>
                                    <RiDeleteBin6Line
                                      fill="red"
                                      style={{
                                        fontSize: "30px",
                                        marginLeft: "20px",
                                        cursor: "pointer",
                                        transition: "transform 0.2s ease",
                                      }}
                                      onClick={() => {
                                        setShow_confirm_list_modal(true)
                                        setdelID(Quotation_id)
                                      }}
                                      onMouseEnter={(e) => e.target.style.transform = 'scale(1.5)'}
                                      onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                                    />
                                  </div>
                                )}
                                {isIntendForAccept && (
                                  <div className="priceBox">
                                    <TextInputWithLabel
                                      label="Price"
                                      placeholder="0"
                                      type="number"
                                      reqstart="*"
                                      value={price_value}
                                      onChange={(e) => {
                                        setPriceValue(e.target.value);
                                      }}
                                    />
                                    <span className="dSign">€</span>
                                    <FaCircleCheck
                                      size={24}
                                      color="#f7ce6c"
                                      onClick={() => {
                                        acceptQuoteRequest(
                                          Quotation_id,
                                          price_value,
                                          i
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                                {isReady && (
                                  <React.Fragment>
                                    <div className="priLaunch">
                                      <label>Price : {approved_price} €</label>
                                      <Button
                                        style={{ width: 170 }}
                                        disabled={launch_btn_loading}
                                        onClick={() => {
                                          launch_mission_fun(Quotation_id, i);
                                        }}
                                      >
                                        {launch_btn_loading ? (
                                          <Loader size={35} />
                                        ) : (
                                          <React.Fragment>
                                            <svg
                                              width="19"
                                              height="18"
                                              viewBox="0 0 19 18"
                                              fill="none"
                                            >
                                              <path
                                                d="M4.10107 17.0625C3.79357 17.0625 3.53857 16.8075 3.53857 16.5V1.5C3.53857 1.1925 3.79357 0.9375 4.10107 0.9375C4.40857 0.9375 4.66357 1.1925 4.66357 1.5V16.5C4.66357 16.8075 4.40857 17.0625 4.10107 17.0625Z"
                                                fill="#1D244A"
                                              />
                                              <path
                                                d="M12.5011 12.5625H4.10107C3.79357 12.5625 3.53857 12.3075 3.53857 12C3.53857 11.6925 3.79357 11.4375 4.10107 11.4375H12.5011C13.3186 11.4375 13.7011 11.22 13.7761 11.0325C13.8511 10.845 13.7386 10.425 13.1536 9.8475L12.2536 8.9475C11.8861 8.625 11.6611 8.1375 11.6386 7.5975C11.6161 7.0275 11.8411 6.465 12.2536 6.0525L13.1536 5.1525C13.7086 4.5975 13.8811 4.1475 13.7986 3.9525C13.7161 3.7575 13.2886 3.5625 12.5011 3.5625H4.10107C3.78607 3.5625 3.53857 3.3075 3.53857 3C3.53857 2.6925 3.79357 2.4375 4.10107 2.4375H12.5011C14.1436 2.4375 14.6686 3.12 14.8411 3.525C15.0061 3.93 15.1186 4.785 13.9486 5.955L13.0486 6.855C12.8611 7.0425 12.7561 7.305 12.7636 7.5675C12.7711 7.7925 12.8611 7.995 13.0186 8.1375L13.9486 9.06C15.0961 10.2075 14.9836 11.0625 14.8186 11.475C14.6461 11.8725 14.1136 12.5625 12.5011 12.5625Z"
                                                fill="#1D244A"
                                              />
                                            </svg>{" "}
                                            Launch Mission
                                          </React.Fragment>
                                        )}
                                      </Button>
                                    </div>
                                  </React.Fragment>
                                )}
                              </td>
                              <td>
                                {isOpen ? (
                                  <FiChevronUp
                                    size={30}
                                    color="#f7ce6c"
                                    className="curpointer"
                                    onClick={() => {
                                      setOpenedMaterialIndex("");
                                    }}
                                  />
                                ) : (
                                  <FiChevronDown
                                    size={30}
                                    color="#4F5B92"
                                    className="curpointer"
                                    onClick={() => {
                                      setOpenedMaterialIndex(i);
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                <div className="mb-2">
                                  {userRole !== 'Carrier' && (
                                    <>
                                      {["start"].map((direction) => (
                                        <DropdownButton
                                          as={ButtonGroup}
                                          key={direction}
                                          id={`dropdown-button-drop-${direction}`}
                                          drop={direction}
                                          className="tabledrop_btn"
                                          title={<FiMoreVertical />}
                                        >
                                          <Dropdown.Item
                                            eventKey="2"
                                            className="menucancel_link"
                                            onClick={() => {
                                              handleOnDeletePress(Quotation_id);
                                            }}
                                          >
                                            <Image
                                              className="editimgt"
                                              src={TrashIcon}
                                              alt=""
                                            />{" "}
                                            Delete
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>

                            <Fade in={isOpen}>
                              <tr className="showaccordion">
                                <td colspan="10">
                                  <div className="cardAccordion">
                                    <Container fluid className="px-3">
                                      <Row>
                                        <Col lg={3} md={3}>
                                          <div className="braedContact">
                                            <Image
                                              className="tIcon"
                                              src={BoxIcon}
                                              alt=""
                                            />
                                            <h3>LOADING INFORMATIONS</h3>
                                          </div>
                                          <div className="accountBox accountBoxinner">
                                            <div class="account_body">
                                              <div>
                                                <h4
                                                  style={{
                                                    marginBottom: "0.6em",
                                                  }}
                                                >
                                                  {state}
                                                  {state && country && "-"}
                                                  {country}
                                                  {/* {LoadingPointDetails.loadingPostcode} */}
                                                </h4>

                                                <h3>
                                                  {" "}
                                                  {moment(
                                                    LoadingPointDetails.Date
                                                  ).format("DD.MM.YYYY")}{" "}
                                                  -{loadingConvertedTime}
                                                </h3>
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col lg={3} md={3}>
                                          <div className="braedContact">
                                            <Image
                                              className="tIcon"
                                              src={DeliveryIcon}
                                              alt=""
                                            />
                                            <h3>DELIVERY INFORMATIONS</h3>
                                          </div>
                                          <div className="accountBox accountBoxinner">
                                            <div class="account_body">
                                              <div>
                                                <h4
                                                  style={{
                                                    marginBottom: "0.6em",
                                                  }}
                                                >
                                                  {delivery_state}
                                                  {delivery_state &&
                                                    delivery_country &&
                                                    "-"}
                                                  {delivery_country}
                                                  {/* {delevryPointDetails.deliveryPostcode} */}
                                                </h4>
                                                <h3>
                                                  {" "}
                                                  {moment(
                                                    delevryPointDetails.delivery_Date
                                                  ).format("DD.MM.YYYY")}{" "}
                                                  -{deliveryConvertedTime}
                                                </h3>
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col lg={6} md={6}>
                                          <div className="braedContact">
                                            <Image
                                              className="tIcon"
                                              src={MatIcon}
                                              alt=""
                                            />
                                            <h3>MATERIAL INFORMATION</h3>
                                          </div>

                                          {isCategoryGeneralCargo && (
                                            <div className="tableAccountBox">
                                              <Table>
                                                <tr>
                                                  <th>
                                                    <h3>Material Name</h3>
                                                  </th>
                                                  <th>
                                                    <h3>Number of pallets</h3>
                                                  </th>
                                                  <th>
                                                    <h3>Size of pallets</h3>
                                                  </th>
                                                  <th>
                                                    <h3>Height of pallets</h3>
                                                  </th>
                                                  <th>
                                                    <h3>Total weight</h3>
                                                  </th>
                                                </tr>

                                                {materialInfo.map((item, i) => {
                                                  const meterial_name =
                                                    item.materialName;
                                                  const number_of_pallets =
                                                    item.numberOfPallets;
                                                  const size_of_pallets =
                                                    item.sizeOfPallets;
                                                  const height_of_pallets =
                                                    item.heightOfPallets;
                                                  const weight_of_material =
                                                    item.totalWeightOfPallets;
                                                  const initial_item = i == 0;
                                                  const {
                                                    adrReference,
                                                    selectedOptions,
                                                  } = item;

                                                  return (
                                                    <React.Fragment>
                                                      <tr>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {meterial_name}
                                                          </h4>
                                                        </td>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {number_of_pallets}
                                                          </h4>
                                                        </td>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {size_of_pallets}
                                                          </h4>
                                                        </td>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {height_of_pallets}
                                                          </h4>
                                                        </td>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {weight_of_material}{" "}
                                                            kg
                                                          </h4>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td colSpan="5">
                                                          <div class="account_body dangertext">
                                                            {adrReference &&
                                                              adrReference.trim() && (
                                                                <h3 className="redText">
                                                                  <svg
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 16 16"
                                                                    fill="none"
                                                                  >
                                                                    <path
                                                                      d="M8 9.83333C7.72667 9.83333 7.5 9.60667 7.5 9.33333V6C7.5 5.72667 7.72667 5.5 8 5.5C8.27333 5.5 8.5 5.72667 8.5 6V9.33333C8.5 9.60667 8.27333 9.83333 8 9.83333Z"
                                                                      fill="#EB5757"
                                                                    />
                                                                    <path
                                                                      d="M7.99992 11.9991C7.95992 11.9991 7.91325 11.9924 7.86659 11.9857C7.82659 11.9791 7.78659 11.9657 7.74659 11.9457C7.70659 11.9324 7.66659 11.9124 7.62659 11.8857C7.59325 11.8591 7.55992 11.8324 7.52659 11.8057C7.40659 11.6791 7.33325 11.5057 7.33325 11.3324C7.33325 11.1591 7.40659 10.9857 7.52659 10.8591C7.55992 10.8324 7.59325 10.8057 7.62659 10.7791C7.66659 10.7524 7.70659 10.7324 7.74659 10.7191C7.78659 10.6991 7.82659 10.6857 7.86659 10.6791C7.95325 10.6591 8.04659 10.6591 8.12659 10.6791C8.17325 10.6857 8.21325 10.6991 8.25325 10.7191C8.29325 10.7324 8.33325 10.7524 8.37325 10.7791C8.40659 10.8057 8.43992 10.8324 8.47325 10.8591C8.59325 10.9857 8.66659 11.1591 8.66659 11.3324C8.66659 11.5057 8.59325 11.6791 8.47325 11.8057C8.43992 11.8324 8.40659 11.8591 8.37325 11.8857C8.33325 11.9124 8.29325 11.9324 8.25325 11.9457C8.21325 11.9657 8.17325 11.9791 8.12659 11.9857C8.08659 11.9924 8.03992 11.9991 7.99992 11.9991Z"
                                                                      fill="#EB5757"
                                                                    />
                                                                    <path
                                                                      d="M12.04 14.7732H3.95998C2.65998 14.7732 1.66665 14.2999 1.15998 13.4466C0.659984 12.5932 0.726651 11.4932 1.35998 10.3532L5.39998 3.08656C6.06665 1.88656 6.98665 1.22656 7.99998 1.22656C9.01332 1.22656 9.93332 1.88656 10.6 3.08656L14.64 10.3599C15.2733 11.4999 15.3466 12.5932 14.84 13.4532C14.3333 14.2999 13.34 14.7732 12.04 14.7732ZM7.99998 2.22656C7.37332 2.22656 6.75998 2.70656 6.27332 3.57323L2.23998 10.8466C1.78665 11.6599 1.71332 12.4066 2.02665 12.9466C2.33998 13.4866 3.03332 13.7799 3.96665 13.7799H12.0466C12.98 13.7799 13.6666 13.4866 13.9866 12.9466C14.3066 12.4066 14.2266 11.6666 13.7733 10.8466L9.72665 3.57323C9.23998 2.70656 8.62665 2.22656 7.99998 2.22656Z"
                                                                      fill="#EB5757"
                                                                    />
                                                                  </svg>{" "}
                                                                  ADR - ref{" "}
                                                                  {adrReference}
                                                                </h3>
                                                              )}
                                                            {Array.isArray(
                                                              selectedOptions
                                                            ) &&
                                                              selectedOptions.map(
                                                                (
                                                                  selected_opt_item,
                                                                  selected_opt_index
                                                                ) => {
                                                                  return (
                                                                    <h3
                                                                      key={
                                                                        selected_opt_index
                                                                      }
                                                                    >
                                                                      {
                                                                        selected_opt_item
                                                                      }
                                                                    </h3>
                                                                  );
                                                                }
                                                              )}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </Table>
                                            </div>
                                          )}

                                          {isCategoryTanker && (
                                            <div className="tableAccountBox">
                                              <Table>
                                                <tr>
                                                  <th>
                                                    <h3>Material Name</h3>
                                                  </th>

                                                  <th>
                                                    <h3>Quantity</h3>
                                                  </th>
                                                  <th>
                                                    <h3></h3>
                                                  </th>
                                                </tr>

                                                {materialInfo.map((item, i) => {
                                                  const meterial_name =
                                                    item.materialName;

                                                  const quantity_of_material =
                                                    item.quantity;
                                                  const initial_item = i == 0;
                                                  const {
                                                    adrReference,
                                                    selectedOptions,
                                                    msdsfile
                                                  } = item;


                                                  return (
                                                    <React.Fragment>
                                                      <tr>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {meterial_name}
                                                          </h4>
                                                        </td>

                                                        <td>
                                                          <h4 className="mb-0">
                                                            {
                                                              quantity_of_material
                                                            }{" "}
                                                            L
                                                          </h4>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td colSpan="2">
                                                          <div class="account_body dangertext">
                                                            {adrReference &&
                                                              adrReference.trim() && (
                                                                <h3 className="redText">
                                                                  <svg
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 16 16"
                                                                    fill="none"
                                                                  >
                                                                    <path
                                                                      d="M8 9.83333C7.72667 9.83333 7.5 9.60667 7.5 9.33333V6C7.5 5.72667 7.72667 5.5 8 5.5C8.27333 5.5 8.5 5.72667 8.5 6V9.33333C8.5 9.60667 8.27333 9.83333 8 9.83333Z"
                                                                      fill="#EB5757"
                                                                    />
                                                                    <path
                                                                      d="M7.99992 11.9991C7.95992 11.9991 7.91325 11.9924 7.86659 11.9857C7.82659 11.9791 7.78659 11.9657 7.74659 11.9457C7.70659 11.9324 7.66659 11.9124 7.62659 11.8857C7.59325 11.8591 7.55992 11.8324 7.52659 11.8057C7.40659 11.6791 7.33325 11.5057 7.33325 11.3324C7.33325 11.1591 7.40659 10.9857 7.52659 10.8591C7.55992 10.8324 7.59325 10.8057 7.62659 10.7791C7.66659 10.7524 7.70659 10.7324 7.74659 10.7191C7.78659 10.6991 7.82659 10.6857 7.86659 10.6791C7.95325 10.6591 8.04659 10.6591 8.12659 10.6791C8.17325 10.6857 8.21325 10.6991 8.25325 10.7191C8.29325 10.7324 8.33325 10.7524 8.37325 10.7791C8.40659 10.8057 8.43992 10.8324 8.47325 10.8591C8.59325 10.9857 8.66659 11.1591 8.66659 11.3324C8.66659 11.5057 8.59325 11.6791 8.47325 11.8057C8.43992 11.8324 8.40659 11.8591 8.37325 11.8857C8.33325 11.9124 8.29325 11.9324 8.25325 11.9457C8.21325 11.9657 8.17325 11.9791 8.12659 11.9857C8.08659 11.9924 8.03992 11.9991 7.99992 11.9991Z"
                                                                      fill="#EB5757"
                                                                    />
                                                                    <path
                                                                      d="M12.04 14.7732H3.95998C2.65998 14.7732 1.66665 14.2999 1.15998 13.4466C0.659984 12.5932 0.726651 11.4932 1.35998 10.3532L5.39998 3.08656C6.06665 1.88656 6.98665 1.22656 7.99998 1.22656C9.01332 1.22656 9.93332 1.88656 10.6 3.08656L14.64 10.3599C15.2733 11.4999 15.3466 12.5932 14.84 13.4532C14.3333 14.2999 13.34 14.7732 12.04 14.7732ZM7.99998 2.22656C7.37332 2.22656 6.75998 2.70656 6.27332 3.57323L2.23998 10.8466C1.78665 11.6599 1.71332 12.4066 2.02665 12.9466C2.33998 13.4866 3.03332 13.7799 3.96665 13.7799H12.0466C12.98 13.7799 13.6666 13.4866 13.9866 12.9466C14.3066 12.4066 14.2266 11.6666 13.7733 10.8466L9.72665 3.57323C9.23998 2.70656 8.62665 2.22656 7.99998 2.22656Z"
                                                                      fill="#EB5757"
                                                                    />
                                                                  </svg>{" "}
                                                                  ADR - ref{" "}
                                                                  {adrReference}
                                                                </h3>
                                                              )}
                                                            {Array.isArray(
                                                              selectedOptions
                                                            ) &&
                                                              selectedOptions.map(
                                                                (
                                                                  selected_opt_item,
                                                                  selected_opt_index
                                                                ) => {
                                                                  return (
                                                                    <h3
                                                                      key={
                                                                        selected_opt_index
                                                                      }
                                                                    >
                                                                      {
                                                                        selected_opt_item === "MSDS included" ?
                                                                          <>
                                                                            <a
                                                                              href={`${process.env.REACT_APP_BUCKET_BASE_URL}/${msdsfile}`}
                                                                              target="_blank"
                                                                              rel="noopener noreferrer"
                                                                              style={{
                                                                                cursor: 'pointer',
                                                                                lineHeight: '0px',
                                                                                color:'#000000'
                                                                              }}
                                                                            >
                                                                              MSDS included
                                                                            </a>
                                                                          </>
                                                                          :
                                                                          <>
                                                                            {selected_opt_item
                                                                            }
                                                                          </>
                                                                      }
                                                                    </h3>
                                                                  );
                                                                }
                                                              )}
                                                          </div>
                                                        </td>
                                                        {/* <td>
                                                          {
                                                            Array.isArray(
                                                              selectedOptions
                                                            ) &&
                                                            selectedOptions.includes('MSDS included') &&
                                                            <a
                                                              href={`${process.env.REACT_APP_BUCKET_BASE_URL}/${msdsfile}`}
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                              style={{
                                                                fontSize: '10px',
                                                                cursor: 'pointer',
                                                                lineHeight: '0px'
                                                              }}
                                                            >
                                                              MSDS included
                                                            </a>
                                                          }
                                                        </td> */}
                                                      </tr>
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </Table>
                                            </div>
                                          )}
                                          {isCategoryRefrigerated && (
                                            <div className="tableAccountBox">
                                              <Table>
                                                <tr>
                                                  <th>
                                                    <h3>Material Name</h3>
                                                  </th>
                                                  <th>
                                                    <h3>Temperature</h3>
                                                  </th>
                                                  <th>
                                                    <h3>Number of pallets</h3>
                                                  </th>
                                                  <th>
                                                    <h3>Size of pallets</h3>
                                                  </th>
                                                  <th>
                                                    <h3>Height of pallets</h3>
                                                  </th>
                                                  <th>
                                                    <h3>Total weight</h3>
                                                  </th>
                                                  <th>
                                                    <h3></h3>
                                                  </th>
                                                </tr>

                                                {materialInfo.map((item, i) => {
                                                  const meterial_name =
                                                    item.materialName;
                                                  const number_of_pallets =
                                                    item.numberOfPallets;
                                                  const size_of_pallets =
                                                    item.sizeOfPallets;
                                                  const height_of_pallets =
                                                    item.heightOfPallets;
                                                  const weight_of_material =
                                                    item.totalWeightOfPallets;
                                                  const temprature =
                                                    item.temprature;
                                                  const initial_item = i == 0;
                                                  const {
                                                    adrReference,
                                                    selectedOptions,
                                                  } = item;

                                                  const fileName = item?.msdsfile || 'null'

                                                  return (
                                                    <React.Fragment>
                                                      <tr>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {meterial_name}
                                                          </h4>
                                                        </td>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {number_of_pallets}
                                                          </h4>
                                                        </td>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {temprature}
                                                          </h4>
                                                        </td>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {size_of_pallets}
                                                          </h4>
                                                        </td>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {height_of_pallets}
                                                          </h4>
                                                        </td>
                                                        <td>
                                                          <h4 className="mb-0">
                                                            {weight_of_material}{" "}
                                                            kg
                                                          </h4>
                                                        </td>
                                                        {
                                                          fileName != 'null' && <td>
                                                            <a
                                                              href={`${process.env.REACT_APP_BUCKET_BASE_URL}/${fileName}`}
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                              style={{
                                                                fontSize: '10px',
                                                                cursor: 'pointer',
                                                                lineHeight: '0px'
                                                              }}
                                                            >
                                                              MSDS included
                                                            </a>
                                                          </td>
                                                        }
                                                      </tr>
                                                      <tr>
                                                        <td colSpan="5">
                                                          <div class="account_body dangertext">
                                                            {adrReference &&
                                                              adrReference.trim() && (
                                                                <h3 className="redText">
                                                                  <svg
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 16 16"
                                                                    fill="none"
                                                                  >
                                                                    <path
                                                                      d="M8 9.83333C7.72667 9.83333 7.5 9.60667 7.5 9.33333V6C7.5 5.72667 7.72667 5.5 8 5.5C8.27333 5.5 8.5 5.72667 8.5 6V9.33333C8.5 9.60667 8.27333 9.83333 8 9.83333Z"
                                                                      fill="#EB5757"
                                                                    />
                                                                    <path
                                                                      d="M7.99992 11.9991C7.95992 11.9991 7.91325 11.9924 7.86659 11.9857C7.82659 11.9791 7.78659 11.9657 7.74659 11.9457C7.70659 11.9324 7.66659 11.9124 7.62659 11.8857C7.59325 11.8591 7.55992 11.8324 7.52659 11.8057C7.40659 11.6791 7.33325 11.5057 7.33325 11.3324C7.33325 11.1591 7.40659 10.9857 7.52659 10.8591C7.55992 10.8324 7.59325 10.8057 7.62659 10.7791C7.66659 10.7524 7.70659 10.7324 7.74659 10.7191C7.78659 10.6991 7.82659 10.6857 7.86659 10.6791C7.95325 10.6591 8.04659 10.6591 8.12659 10.6791C8.17325 10.6857 8.21325 10.6991 8.25325 10.7191C8.29325 10.7324 8.33325 10.7524 8.37325 10.7791C8.40659 10.8057 8.43992 10.8324 8.47325 10.8591C8.59325 10.9857 8.66659 11.1591 8.66659 11.3324C8.66659 11.5057 8.59325 11.6791 8.47325 11.8057C8.43992 11.8324 8.40659 11.8591 8.37325 11.8857C8.33325 11.9124 8.29325 11.9324 8.25325 11.9457C8.21325 11.9657 8.17325 11.9791 8.12659 11.9857C8.08659 11.9924 8.03992 11.9991 7.99992 11.9991Z"
                                                                      fill="#EB5757"
                                                                    />
                                                                    <path
                                                                      d="M12.04 14.7732H3.95998C2.65998 14.7732 1.66665 14.2999 1.15998 13.4466C0.659984 12.5932 0.726651 11.4932 1.35998 10.3532L5.39998 3.08656C6.06665 1.88656 6.98665 1.22656 7.99998 1.22656C9.01332 1.22656 9.93332 1.88656 10.6 3.08656L14.64 10.3599C15.2733 11.4999 15.3466 12.5932 14.84 13.4532C14.3333 14.2999 13.34 14.7732 12.04 14.7732ZM7.99998 2.22656C7.37332 2.22656 6.75998 2.70656 6.27332 3.57323L2.23998 10.8466C1.78665 11.6599 1.71332 12.4066 2.02665 12.9466C2.33998 13.4866 3.03332 13.7799 3.96665 13.7799H12.0466C12.98 13.7799 13.6666 13.4866 13.9866 12.9466C14.3066 12.4066 14.2266 11.6666 13.7733 10.8466L9.72665 3.57323C9.23998 2.70656 8.62665 2.22656 7.99998 2.22656Z"
                                                                      fill="#EB5757"
                                                                    />
                                                                  </svg>{" "}
                                                                  ADR - ref{" "}
                                                                  {adrReference}
                                                                </h3>
                                                              )}
                                                            {Array.isArray(
                                                              selectedOptions
                                                            ) &&
                                                              selectedOptions.map(
                                                                (
                                                                  selected_opt_item,
                                                                  selected_opt_index
                                                                ) => {
                                                                  return (
                                                                    <h3
                                                                      key={
                                                                        selected_opt_index
                                                                      }
                                                                    >
                                                                      {
                                                                        selected_opt_item
                                                                      }
                                                                    </h3>
                                                                  );
                                                                }
                                                              )}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </Table>
                                              {/* <Table>
                                                <tr>
                                                  <th>
                                                    <h3>Temperature</h3>
                                                  </th>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <h4 className="mb-0">

                                                      {materialInfo.length > 0 && materialInfo[0].temprature
                                                        ? `${materialInfo[0].temprature}°C`
                                                        : "No temperature data"}
                                                    </h4>
                                                  </td>
                                                </tr>
                                              </Table> */}
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                    </Container>
                                  </div>
                                </td>
                              </tr>
                            </Fade>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* pagination */}
              <div className="pagination-container">
                <div className="paginationFeature">
                  <p>Rows per page: {list_row_limit}</p>
                </div>
                <div className="pagination">
                  <p>
                    {List_data.from} - {List_data.to} of {List_data.total_docs}
                  </p>
                  <ReactPaginate
                    activePage={currentPage}
                    itemsCountPerPage={list_row_limit}
                    totalItemsCount={List_data.total_docs}
                    pageRangeDisplayed={list_row_limit}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination"
                  />
                </div>
              </div>
            </Container>
          ) : null}
        </div>
      </div>
      <ConfirmDeleteModal centered show={show_confirm_delete_modal} />
      <ConfirmToDeleteListRecord centered show={show_confirm_list_modal} />
      <ToastContainer />
    </React.Fragment>
  );
};

export default Home;
