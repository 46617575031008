import React, { useContext, useEffect, useState } from "react";
import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import FilterSidebar from "../inc/FilterSidebar";
import {
  Col,
  Container,
  Image,
  Row,
  Table,
  Button,
  Form,
  Badge,
} from "react-bootstrap";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import FilterIcon from "../images/filter.svg";
import ArrowLeftIcon from "../images/arrow-left.svg";
import RowsImg from "../images/rowtable.svg";
import DownloadIcon from "../images/download.svg";
import {
  get_OrderList,
  staff_ArchiveOrder,
  get_carrier_archive_list,
  get_user_archive_list,
  get_client_archive_list,
} from "../services/ApiCalls";
import LoadingContext from "../services/LoadingContext";
import moment from "moment";
import { user_ArchiveList } from "../services/UserApiServices/UserApiCalls";
import { saveAs } from "file-saver";
import ReactPaginate from "react-js-pagination";
import CustomHookFunctions from "../services/CustomHookFunctions";
import { AppStoreContext } from "../../store/AppStore";
import consoleService from "../../utility/services/consoleService";
import { DOWNLOAD_FILE_URL } from "../services/env";
import NotificationIcon from "../images/notification.svg";
import SearchIcon from "../images/searchicon.svg";

const OrdersArchives = () => {
  const { userDetail, toggleBodyClass } = useContext(AppStoreContext);
  const role = userDetail.profile;
  const isRoleCarrier = role == "Carrier";
  const isRoleUser = role == "User";
  const isRoleClient = role == "Client";
  const isRoleStaff = role == "Staff";
  const userId = userDetail._id;

  const [initialLoading, setInitialLoading] = useState(false);
  const [archive_list, setArchiveList] = useState([]);
  const [archive_list_for_filter, setArchiveListForFilter] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const list_row_limit = 10;
  const { Paginated_data, download_file_from_server, isValidString } =
    CustomHookFunctions();

  const List_data = Paginated_data(currentPage, list_row_limit, archive_list);

  // OLD VARIABLES

  const [toggle, setToggle] = React.useState(false);
  const [orderListData, setOrderListData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { setIsLoading } = useContext(LoadingContext);

  let count = 0;

  useEffect(() => {
    get_initial_details(true);
  }, []);

  async function get_initial_details(loading) {
    try {
      setIsLoading(loading);
      const get_archives = async () => {
        let FetchedRes;
        if (isRoleCarrier) {
          const payload = {
            carrier_id: userId,
            userRole:role
          };
          FetchedRes = await get_carrier_archive_list(payload);
        } else if (isRoleUser || isRoleStaff) {
          const payload = {
            user_id: userId,
          };
          FetchedRes = await get_user_archive_list(payload);
        } else if (isRoleClient) {
          const payload = {
            user_id: userId,
          };
          FetchedRes = await get_client_archive_list(payload);
        }
        return FetchedRes;
      };

      const res = await get_archives();

      if (res.status === 1) {
        const fetchedData = res.data;
        setArchiveList([...fetchedData]);
        setArchiveListForFilter([...fetchedData]);
        setInitialLoading(true);
        setIsLoading(false);
        return;
      }
    } catch (err) {
      consoleService("get_initial_details err = ", err);
    }
  }

  const handleSearch = (searchQuery) => {
    const search_query = searchQuery.toLowerCase();

    if (searchQuery.trim()) {
      const filteredResults = archive_list_for_filter.filter((result) => {
        const reference_number = result.reference_number;

        const cond = search_query == reference_number;

        if (cond) return result;
      });
      setArchiveList([...filteredResults]);
    } else {
      setArchiveList([...archive_list_for_filter]);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (searchQuery === "") {
      fetchOrderData();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (isRoleStaff) {
      fetchOrderData();
    } else {
      fetchUserOrderData();
    }
  }, []);

  const fetchOrderData = () => {
    const payload = {
      StaffId: userId,
    };
    setIsLoading(true);
    staff_ArchiveOrder(payload)
      .then((res) => {
        console.log("order res", res.data);
        setOrderListData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };
  // console.log("asdasdasdasdasdasdasdasd", orderListData);

  React.useEffect(() => {
    if (toggle) {
      document.body.classList.add("filter_sidebar");
    } else {
      document.body.classList.remove("filter_sidebar");
    }
  }, [toggle]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleDownload = (path) => {
    const pdfUrl = DOWNLOAD_FILE_URL + path;
    download_file_from_server(pdfUrl);
  };

  // ===================================User API=========================================

  const fetchUserOrderData = () => {
    const payload = {
      userId: userId,
      OderDeatilid: "",
    };
    setIsLoading(true);
    user_ArchiveList(payload)
      .then((res) => {
        console.log("user -- res", res.data);
        setOrderListData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  // const ARCHIVES_DATA = [
  //   {
  //     id: 1,
  //     date: "21.06.2023",
  //     refrence: "09478563",
  //     coa: "-",
  //     del_date: "-",
  //     pod: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //   },
  //   {
  //     id: 2,
  //     date: "19.06.2023",
  //     refrence: "00448456",
  //     coa: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //     del_date: "-",
  //     pod: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //   },
  //   {
  //     id: 3,
  //     date: "19.06.2023",
  //     refrence: "85663256",
  //     coa: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //     del_date: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //     pod: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //   },
  //   {
  //     id: 4,
  //     date: "19.06.2023",
  //     refrence: "02236958",
  //     coa: "-",
  //     del_date: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //     pod: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //   },
  //   {
  //     id: 5,
  //     date: "21.06.2023",
  //     refrence: "09478563",
  //     coa: "-",
  //     del_date: "-",
  //     pod: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //   },
  //   {
  //     id: 6,
  //     date: "19.06.2023",
  //     refrence: "00448456",
  //     coa: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //     del_date: "-",
  //     pod: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //   },
  //   {
  //     id: 7,
  //     date: "19.06.2023",
  //     refrence: "85663256",
  //     coa: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //     del_date: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //     pod: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //   },
  //   {
  //     id: 8,
  //     date: "19.06.2023",
  //     refrence: "02236958",
  //     coa: "-",
  //     del_date: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //     pod: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //   },
  //   {
  //     id: 9,
  //     date: "19.06.2023",
  //     refrence: "85663256",
  //     coa: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //     del_date: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //     pod: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //   },
  //   {
  //     id: 10,
  //     date: "19.06.2023",
  //     refrence: "02236958",
  //     coa: "-",
  //     del_date: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //     pod: (
  //       <Link className="downLink">
  //         <Image src={DownloadIcon} alt="" />
  //         Download
  //       </Link>
  //     ),
  //   },
  // ];

  return (
    <React.Fragment>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          <div className="topheader">
            <div className="container-fluid">
              <div className="topheader_flex">
                <Button
                  variant="primary"
                  className="menuBtn"
                  onClick={toggleBodyClass}
                >
                  <FiMenu />
                </Button>
                <div className="inPutBox">
                  <Form.Control
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(text) => {
                      handleSearch(text.target.value);
                      setSearchQuery(text.target.value);
                    }}
                  />
                  <Image src={SearchIcon} alt="" />
                </div>
                <Link className="notiIcon">
                  <Image src={NotificationIcon} alt="" />
                  <Badge bg="danger">5</Badge>
                </Link>
              </div>
            </div>
          </div>

          {initialLoading && (
            <Container fluid className=" pt-100">
              <div className="quotes_div">
                <Row className="align-items-center mb-3">
                  <Col lg={6} xs={6}>
                    <Link to={`/orders`} className="backTextBtn">
                      <Image src={ArrowLeftIcon} alt="" /> Back to current
                      orders
                    </Link>
                  </Col>
                </Row>
                <div className="react_table react_tableBold">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>SR.</th>
                        <th>DATE</th>
                        <th>REFERENCE</th>
                        {
                          isRoleStaff && <>
                           <th>VEHICLE</th>
                          <th>CARRIER</th>
                          <th>APPLICANT</th>
                          </>
                        }
                        <th>OC</th>
                        <th>COA</th>
                        <th>BL</th>
                        <th>POD</th>
                      </tr>
                    </thead>

                    {archive_list.length === 0 && (
                      <tr>
                        <td colSpan={9} className="text-center  pt-3 ">
                          No data found
                        </td>
                      </tr>
                    )}

                    <tbody>
                      {archive_list.map((item, index) => {
                        const referenceNumber = item.reference_number;
                        const delivery_date = item.delivery_date;
                        const POD_DOC = item.p_o_d;
                        const BL_DOC = item.b_l;
                        const COA_DOC = item.c_o_a;
                        const OC_DOC = item.oc_file;
                        if (isRoleStaff) {
                          console.log(item?.quotation_details[0]?.accountNumber  , 'commign detal ---')
                          var VEHICLE = item?.quotation_details[0]?.vehicle_plate_no || null;
                          var VEHICLECAt = item?.quotation_details[0]?.category || null;
                          // var APPLICANT = item?.quotation_details[0]?.applicant || null;
                          var APPLICANT =  item?.quotation_details[0]?.UserType == "staff"
                          ? "Mottruck"
                          : item?.quotation_details[0]?.applicant 
                         
                          var ACCOUNTNUMBER = item?.quotation_details[0]?.accountNumber
                         
                        }
                        const CARRIER =  item?.carrier_detail?.companyName;

                        return (
                          <tr>
                            <td>{++count}</td>
                            <td>
                              {moment(delivery_date).format("DD.MM.YYYY")}
                            </td>
                            <td>{referenceNumber}</td>
                            {
                              isRoleStaff && <>
                                 <td>
                                    <h4 className="name_text mb-1">
                                      {VEHICLECAt}
                                    </h4>
                                    <h4 className="datetime_text">
                                      {VEHICLE}
                                    </h4>
                                  </td>
                            <td>{CARRIER}</td>
                              <td>
                                <h4 className="name_text mb-1">
                                  {APPLICANT}
                                </h4>
                                  <h4 className="name_text mb-1">
                                    {ACCOUNTNUMBER}
                                  </h4>
                              </td>
                              </>
                            }
                            <td>
                              {OC_DOC ? (
                                <>
                                  <Link
                                    className="downLink"
                                    onClick={() => handleDownload(OC_DOC)}
                                  >
                                    <Image src={DownloadIcon} alt="" />
                                    Download
                                  </Link>
                                </>
                              ) : (
                                "-"
                              )}
                            </td>

                            <td>
                              {COA_DOC ? (
                                <>
                                  <Link
                                    className="downLink"
                                    onClick={() => handleDownload(COA_DOC)}
                                  >
                                    <Image src={DownloadIcon} alt="" />
                                    Download
                                  </Link>
                                </>
                              ) : (
                                "-"
                              )}
                            </td>

                            <td>
                              {BL_DOC ? (
                                <>
                                  <Link
                                    className="downLink"
                                    onClick={() => handleDownload(BL_DOC)}
                                  >
                                    <Image src={DownloadIcon} alt="" />
                                    Download
                                  </Link>
                                </>
                              ) : (
                                "-"
                              )}
                            </td>

                            <td>
                              {POD_DOC ? (
                                <>
                                  <Link
                                    className="downLink"
                                    onClick={() => handleDownload(POD_DOC)}
                                  >
                                    <Image src={DownloadIcon} alt="" />
                                    Download
                                  </Link>
                                </>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* pagination */}
              <div className="pagination-container">
                <div className="paginationFeature">
                  <p>Rows per page: {list_row_limit}</p>
                </div>
                <div className="pagination">
                  <p>
                    {List_data.from} - {List_data.to} of {List_data.total_docs}
                  </p>
                  <ReactPaginate
                    activePage={currentPage}
                    itemsCountPerPage={list_row_limit}
                    totalItemsCount={List_data.total_docs}
                    pageRangeDisplayed={list_row_limit}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination"
                  />
                </div>
              </div>
            </Container>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrdersArchives;
