import React, { useState, useEffect, useContext } from "react";
import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import FilterSidebar from "../inc/FilterSidebar";
import {
  Container,
  Image,
  Badge,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Fade,
  Table,
  Row,
  Col,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { PiEnvelopeSimpleLight } from "react-icons/pi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import FilterIcon from "../images/filter.svg";
import { Link } from "react-router-dom";
import ArchiveIcon from "../images/archive.svg";
import Danger from "../images/danger.svg";
import InfoCircle from "../images/info-circle.svg";

import { FiMenu, FiMoreVertical } from "react-icons/fi";
import tablearrow from "../images/table_arrow.svg";
import EditIcon from "../images/edit.svg";
import TrashIcon from "../images/trash.svg";
import ExportIcon from "../images/exporticon.svg";
import GpsIcon from "../images/gps.svg";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import DocumentIcon from "../images/document.svg";
import MatIcon from "../images/maticon.svg";
import CallIcon from "../images/call.svg";
import DocumentTextIcon from "../images/document-text.svg";
import DownloadIcon from "../images/download.svg";
import SmileysIcon from "../images/smileys.svg";
import RowsImg from "../images/rowtable.svg";

import SmileysIcon1 from "../../web_components/images/sicon1.svg";
import SmileysIcon2 from "../../web_components/images/sicon2.svg";
import SmileysIcon3 from "../../web_components/images/sicon3.svg";
import SmileysIcon4 from "../../web_components/images/sicon4.svg";
import SmileysIcon5 from "../../web_components/images/sicon5.svg";

import {
  get_OrderList,
  order_Delete,
  staff_OrderCarrierRate,
  staff_OrderCount,
  staff_SelectOrder,
  delete_quote_by_carrier,
  add_rating,
  set_view_order_status_carrier,
  get_carrier_order_list,
  get_user_order_list,
  set_view_order_status_user,
  delete_quote_by_user,
  get_client_order_list,
  get_staff_order_list,
} from "../services/ApiCalls";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import LoadingContext from "../services/LoadingContext";
import NotificationIcon from "../images/notification.svg";
import SearchIcon from "../images/searchicon.svg";
import { saveAs } from "file-saver";
import ExportToExcel from "../commonModal/ExportToExcel";
import ReactPaginate from "react-js-pagination";
import {
  user_DeleteOrder,
  user_OrderList,
  user_SelectOrder,
} from "../services/UserApiServices/UserApiCalls";
import ExcelJS from "exceljs";
import Loader from "../../common/component/Loader";
import consoleService from "../../utility/services/consoleService";
import CustomHookFunctions from "../services/CustomHookFunctions";
import { AppStoreContext } from "../../store/AppStore";

import { DOWNLOAD_FILE_URL } from "../services/env";
import const_config from "../../utility/config/const_config";

const Orders = () => {
  const { userDetail, toggleBodyClass } = useContext(AppStoreContext);
  const role = userDetail.profile;
  const isRoleCarrier = role == "Carrier";
  const isRoleUser = role == "User";
  const isRoleClient = role == "Client";
  const isRoleStaff = role == "Staff";
  const userId = userDetail._id;

  const [all_orders, setAllOrders] = useState("0");
  const [order_list, setOrderList] = useState([]);
  const [order_list_for_filter, setOrderListForFilter] = useState([]);
  const [supplier, setSupplier] = useState("0");
  const [hold_on, setHoldOn] = useState("0");
  const [on_route, setOnRoute] = useState("0");
  const [delivered, setDelivered] = useState("0");

  const [initialLoading, setInitialLoading] = useState(false);

  const input_key_permissions = const_config.filter_permission_keys;

  // OLD VARIABLES

  const [selectedItemCount, setSelectedItemCount] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [orderListData, setOrderListData] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState({});
  const [selectedItemDataIndex, setSelectedItemDataIndex] = useState();
  const [orderTotalCount, setOrderTotalCount] = useState({});
  const { setIsLoading } = useContext(LoadingContext);
  const [otif, setOtif] = useState("");
  const [platform, setPlatform] = useState("");
  const [correction, setCorrection] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [isBodyClassActive, setIsBodyClassActive] = useState(false);
  const [allSheetSelected, setAllSheetSelected] = useState(false);
  const [selectedOrderCategory, setSelectedOrderCategory] = useState("");

  let count = 0;

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedOrderList = orderListData.slice(startIndex, endIndex);
  const [show_order_modal, setShowOrderModal] = useState(false);
  const [deleteOrderLoading, setDeleteOrderLoading] = useState(false);
  const [add_rating_loading, setAddRatingLoading] = useState(false);
  const [selected_order_details_for_delete, setSelectedOrderDetailsForDelete] =
    useState({});

  const [opened_meterial_details_index, setOpenedMaterialIndex] = useState("");

  const list_row_limit = 10;
  const { Paginated_data, download_file_from_server, isValidString } =
    CustomHookFunctions();

  const List_data = Paginated_data(currentPage, list_row_limit, order_list);

  const calculateCount = (index) => {
    return (currentPage - 1) * itemsPerPage + index + 1;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (searchQuery) => {
    const search_query = searchQuery.toLowerCase();

    if (searchQuery.trim()) {
      const filteredResults = order_list_for_filter.filter((result) => {
        const loadingInfo = result.LOADING_INFORMATIONS[0];
        const deliveryInfo = result.DELIVERY_INFORMATIONS[0];
        const loadingCompanyName = (
          isValidString(loadingInfo.Company_name)
            ? loadingInfo.Company_name.toString()
            : ""
        ).toLowerCase();
        const loadingContactName = (
          isValidString(loadingInfo.Contact_name)
            ? loadingInfo.Contact_name.toString()
            : ""
        ).toLowerCase();

        const delivery_company_name = (
          isValidString(deliveryInfo.deliver_Contact_name)
            ? deliveryInfo.deliver_Contact_name.toString()
            : ""
        ).toLowerCase();

        const reference = (
          isValidString(result.referenceNumber)
            ? result.referenceNumber.toString()
            : ""
        ).toLowerCase();

        const loading_city = (
          isValidString(loadingInfo.loadingCity)
            ? loadingInfo.loadingCity.toString()
            : ""
        ).toLowerCase();

        const loading_country = (
          isValidString(loadingInfo.loadingCountry)
            ? loadingInfo.loadingCountry.toString()
            : ""
        ).toLowerCase();

        const delivery_city = (
          isValidString(deliveryInfo.deliveryCity)
            ? deliveryInfo.deliveryCity.toString()
            : ""
        ).toLowerCase();

        const delivery_country = (
          isValidString(deliveryInfo.deliveryCountry)
            ? deliveryInfo.deliveryCountry.toString()
            : ""
        ).toLowerCase();

        const delivery_state = (
          isValidString(deliveryInfo.deliveryState)
            ? deliveryInfo.deliveryState.toString()
            : ""
        ).toLowerCase();

        const category = (
          isValidString(result.category) ? result.category.toString() : ""
        ).toLowerCase();

        const carrier_name = (
          isValidString(result.carrier_company_name)
            ? result.carrier_company_name.toString()
            : ""
        ).toLowerCase();

        const user_company_name = (
          isValidString(result.user_company_name)
            ? result.user_company_name.toString()
            : ""
        ).toLowerCase();

        let distance = (
          isValidString(result.distance)
            ? `${Number(result.distance).toFixed(0)} km`.toString()
            : ""
        ).toLowerCase();

        const price = (
          isValidString(result.prize) ? result.prize.toString() : ""
        ).toLowerCase();

        const matchCondition =
          loadingCompanyName.startsWith(search_query) ||
          loadingContactName.startsWith(search_query) ||
          delivery_company_name.startsWith(search_query) ||
          reference.startsWith(search_query) ||
          loading_city.startsWith(search_query) ||
          loading_country.startsWith(search_query) ||
          delivery_city.startsWith(search_query) ||
          delivery_state.startsWith(search_query) ||
          category.startsWith(search_query) ||
          carrier_name.startsWith(search_query) ||
          user_company_name.startsWith(search_query) ||
          distance.startsWith(search_query) ||
          price.startsWith(search_query) ||
          delivery_country.startsWith(search_query);
        if (matchCondition) {
          return result;
        }
      });
      setOrderList([...filteredResults]);
    } else {
      setOrderList([...order_list_for_filter]);
    }
  };

  const handleSideFilter = (filter_items) => {
    const isFilter = Object.keys(filter_items).length > 0;
    if (isFilter) {
      const {
        delivery_city,
        delivery_contact_email,
        delivery_contact_name,
        delivery_contact_phone,
        delivery_date,
        delivery_time,
        loading_city,
        loading_contact_email,
        loading_contact_name,
        loading_contact_phone,
        loading_date,
        loading_time,
        vehicle_category,
      } = filter_items;

      const filteredResults = order_list_for_filter.filter((result) => {
        const loadingInfo = result.LOADING_INFORMATIONS[0];
        const deliveryInfo = result.DELIVERY_INFORMATIONS[0];

        const filter_arr = [];

        // DELIVERY CITY

        if (delivery_city) {
          const found_delivery_city = (
            isValidString(deliveryInfo.deliveryCity)
              ? deliveryInfo.deliveryCity.toString()
              : ""
          ).toLowerCase();

          const delivery_state = (
            isValidString(deliveryInfo.deliveryState)
              ? deliveryInfo.deliveryState.toString()
              : ""
          ).toLowerCase();

          const delivery_country = (
            isValidString(deliveryInfo.deliveryCountry)
              ? deliveryInfo.deliveryCountry.toString()
              : ""
          ).toLowerCase();

          const matchCondition =
            delivery_city == found_delivery_city ||
            delivery_city == delivery_state ||
            delivery_city == delivery_country;
          filter_arr.push(matchCondition);
        }

        // DELIVERY CONTACT EMAIL

        if (delivery_contact_email) {
          const found_delivery_contact_email = (
            isValidString(deliveryInfo.delivery_conntact_email)
              ? deliveryInfo.delivery_conntact_email.toString()
              : ""
          ).toLowerCase();
          const matchCondition =
            delivery_contact_email == found_delivery_contact_email;
          filter_arr.push(matchCondition);
        }

        // DELIVERY CONTACT NAME

        if (delivery_contact_name) {
          const found_delivery_contact_name = (
            isValidString(deliveryInfo.deliver_Contact_name)
              ? deliveryInfo.deliver_Contact_name.toString()
              : ""
          ).toLowerCase();

          const matchCondition =
            delivery_contact_name == found_delivery_contact_name;
          filter_arr.push(matchCondition);
        }

        // DELIVERY CONTACT PHONE

        if (delivery_contact_phone) {
          const delivery_contact_code = (
            isValidString(deliveryInfo.delver_code)
              ? deliveryInfo.delver_code.toString()
              : ""
          ).toLowerCase();

          const found_delivery_contact_phone = (
            isValidString(deliveryInfo.deliver_contact_phone)
              ? deliveryInfo.deliver_contact_phone.toString()
              : ""
          ).toLowerCase();

          const phone_with_code_with_space = `${delivery_contact_code} ${found_delivery_contact_phone}`;
          const phone_with_code_without_space = `${delivery_contact_code}${found_delivery_contact_phone}`;
          const matchCondition =
            delivery_contact_phone == found_delivery_contact_phone ||
            delivery_contact_phone == phone_with_code_without_space ||
            delivery_contact_phone == phone_with_code_with_space;
          filter_arr.push(matchCondition);
        }

        // DELIVERY DATA

        if (delivery_date) {
          const found_delivery_date = (
            isValidString(deliveryInfo.delivery_Date)
              ? deliveryInfo.delivery_Date.toString()
              : ""
          ).toLowerCase();

          const matchCondition = delivery_date == found_delivery_date;
          filter_arr.push(matchCondition);
        }

        // DELIVERY TIME

        if (delivery_time) {
          const found_delivery_time = (
            isValidString(deliveryInfo.delivery_Hour)
              ? deliveryInfo.delivery_Hour.toString()
              : ""
          ).toLowerCase();

          const matchCondition = delivery_time == found_delivery_time;
          filter_arr.push(matchCondition);
        }

        // LOADING CITY

        if (loading_city) {
          const found_loading_city = (
            isValidString(loadingInfo.loadingCity)
              ? loadingInfo.loadingCity.toString()
              : ""
          ).toLowerCase();

          const found_loading_state = (
            isValidString(loadingInfo.loadingState)
              ? loadingInfo.loadingState.toString()
              : ""
          ).toLowerCase();

          const loading_country = (
            isValidString(loadingInfo.loadingCountry)
              ? loadingInfo.loadingCountry.toString()
              : ""
          ).toLowerCase();

          const matchCondition =
            loading_city == found_loading_city ||
            loading_city == found_loading_state ||
            loading_city == loading_country;
          filter_arr.push(matchCondition);
        }

        // LOADING CONTACT EMAIL

        if (loading_contact_email) {
          const found_loading_contact_email = (
            isValidString(loadingInfo.Contact_email)
              ? loadingInfo.Contact_email.toString()
              : ""
          ).toLowerCase();
          const matchCondition =
            loading_contact_email == found_loading_contact_email;
          filter_arr.push(matchCondition);
        }

        // LOADING CONTACT NAME

        if (loading_contact_name) {
          const foundloadingContactName = (
            isValidString(loadingInfo.Contact_name)
              ? loadingInfo.Contact_name.toString()
              : ""
          ).toLowerCase();
          const matchCondition =
            loading_contact_name == foundloadingContactName;
          filter_arr.push(matchCondition);
        }

        // LOADING CONTACT PHONE

        if (loading_contact_phone) {
          const loading_contact_code = (
            isValidString(loadingInfo.Contact_code)
              ? loadingInfo.Contact_code.toString()
              : ""
          ).toLowerCase();

          const found_loading_contact_phone = (
            isValidString(loadingInfo.Contact_phone)
              ? loadingInfo.Contact_phone.toString()
              : ""
          ).toLowerCase();

          const phone_with_code_with_space = `${loading_contact_code} ${found_loading_contact_phone}`;
          const phone_with_code_without_space = `${loading_contact_code}${found_loading_contact_phone}`;
          const matchCondition =
            loading_contact_phone == found_loading_contact_phone ||
            loading_contact_phone == phone_with_code_without_space ||
            loading_contact_phone == phone_with_code_with_space;
          filter_arr.push(matchCondition);
        }

        // LOADING DATE

        if (loading_date) {
          const found_loading_date = (
            isValidString(loadingInfo.Date) ? loadingInfo.Date.toString() : ""
          ).toLowerCase();

          const matchCondition = loading_date == found_loading_date;
          filter_arr.push(matchCondition);
        }

        // LOADING TIME

        if (loading_time) {
          const found_loading_time = (
            isValidString(loadingInfo.Hours) ? loadingInfo.Hours.toString() : ""
          ).toLowerCase();

          const matchCondition = loading_time == found_loading_time;
          filter_arr.push(matchCondition);
        }

        // VEHICLE CATEGORY

        if (vehicle_category) {
          const found_category = (
            isValidString(result.category) ? result.category.toString() : ""
          ).toLowerCase();

          const matchCondition = vehicle_category == found_category;
          filter_arr.push(matchCondition);
        }

        const Final_condition = filter_arr.every((condition) => {
          return condition == true;
        });

        if (Final_condition) {
          return result;
        }
      });

      setOrderList([...filteredResults]);
    } else {
      setOrderList([...order_list_for_filter]);
    }
  };

  useEffect(() => {
    fetch_initial_details_for_carrier(true);
  }, []);

  async function fetch_order_details(payload) {
    try {
      let FetchedRes;
      if (isRoleCarrier) {
        FetchedRes = await get_carrier_order_list(payload);
      } else if (isRoleUser) {
        FetchedRes = await get_user_order_list(payload);
      } else if (isRoleClient) {
        FetchedRes = await get_client_order_list(payload);
      } else if (isRoleStaff) {
        FetchedRes = await get_staff_order_list(payload);
      }
      return FetchedRes;
    } catch (err) {
      consoleService("error in fetch_order_details = ", err);
    }
  }

  async function fetch_initial_details_for_carrier(loading, lanunch_status) {
    try {
      const selected_category = lanunch_status ? lanunch_status : "";
      setSelectedOrderCategory(selected_category);
      setIsLoading(loading);
      const payload = {
        user_id: userId,
        launch_status: selected_category,
      };
      const res = await fetch_order_details(payload);

      if (res.status === 1) {
        const fetched_data = res.data;
        setOrderList([...fetched_data.order_list]);
        setOrderListForFilter([...fetched_data.order_list]);
        setAllOrders(fetched_data.all_orders);
        setOnRoute(fetched_data.on_route);
        setHoldOn(!!fetched_data.on_hold ? fetched_data.on_hold : 0);
        setSupplier(!!fetched_data.supplier ? fetched_data.supplier : 0);
        setDelivered(fetched_data.delivered);
        setCurrentPage(1);
        setInitialLoading(true);
        setIsLoading(false);
        return;
      }
    } catch (err) {
      consoleService("error fetch_initial_details fun=  ", err);
    }
  }

  useEffect(() => {
    if (toggle) {
      document.body.classList.add("filter_sidebar");
    } else {
      document.body.classList.remove("filter_sidebar");
    }
  }, [toggle]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const [open, setOpen] = useState(false);

  const [thHide, setthShow] = useState(false);

  const toggleTh = () => {
    setthShow(!thHide);
  };

  useEffect(() => {
    fetchOrderData();
    getTotalCount();
  }, []);

  const handleSelectOrderFilter = (item) => {
    handleSelectOrder(item);
  };

  //  Order List API
  const fetchOrderData = async () => {
    const payload = {
      StaffId: userId,
      type: isRoleStaff ? "staff" : "user",
    };
    setIsLoading(true);
    get_OrderList(payload)
      .then((res) => {
        add_checks(setOrderListData, res.data);
        setIsLoading(false);
        return;
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  // check New Order API
  const checkNewOrder = (itemID) => {
    const payload = {
      StaffId: userId,
      OderDeatilid: itemID,
      type: isRoleStaff ? "staff" : "user",
    };
    setIsLoading(true);
    get_OrderList(payload)
      .then((res) => {
        add_checks(setOrderListData, res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  const handleDetail = (item, index) => {
    if (item) {
      setOpen(!open);
      setSelectedItemData(item);
      setSelectedItemDataIndex(index);
    }
  };

  const handleDeleteClick = (id) => {
    let payload;
    payload = {
      carrier_id: userId,
      quote_id: id,
    };

    setSelectedOrderDetailsForDelete(payload);
    setShowOrderModal(true);
  };

  async function delete_order_fun() {
    try {
      setDeleteOrderLoading(true);
      const delete_quote = async () => {
        let FetchedRes;
        // if (isRoleCarrier) {
        //   FetchedRes = await delete_quote_by_carrier(
        //     selected_order_details_for_delete
        //   );
        // } else
        if (isRoleUser || isRoleStaff || isRoleCarrier) {
          FetchedRes = await delete_quote_by_user(
            selected_order_details_for_delete
          );
        }
        return FetchedRes;
      };
      const res = await delete_quote();
      if (res.status === 1) {
        await fetch_initial_details_for_carrier(false);
        setDeleteOrderLoading(false);
        setShowOrderModal(false);
        toast("Delete Successfully");
      } else {
        console.log("error in delete_order_fun = ", res.message);
      }
    } catch (err) {
      consoleService("error in delete_order_fun =  ", err);
    }
  }

  const getTotalCount = () => {
    const payload = {
      staffId: userId,
      type: isRoleStaff ? "staff" : "user",
    };
    setIsLoading(true);
    staff_OrderCount(payload)
      .then((res) => {
        setOrderTotalCount(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  const [selectedRating, setSelectedRating] = useState(0);
  const [isAddRatingLoading, setAddRatingLoadings] = useState(false);
  const [comment, setComment] = useState("");

  const handleImageClick = (rating) => {
    setSelectedRating(rating);
  };

  const handleRating = (quotation_id, carrierId, user_id) => {
    if (role === "User" || role === "Client") {
      if (!selectedRating || selectedRating < 1) {
        toast("Please select  rating");
        return;
      }
      var payload = {
        rating_receipent_id: carrierId,
        reviewer_id: user_id,
        quote_id: quotation_id,
        rating: selectedRating,
        comment: comment,
      };
    } else {
      if (!otif || otif < 1) {
        toast("Please enter OTIF rating");
        return;
      } else if (!platform || platform < 1) {
        toast("Please enter Platform obligation rating");
        return;
      } else if (!correction || correction < 1) {
        toast("Please enter correction rating");
        return;
      } else {
        var payload = {
          rating_receipent_id: carrierId,
          reviewer_id: user_id,
          quote_id: quotation_id,
          OTIF_Rating: otif,
          Platform_Obligation_Rating: platform,
          Correction_Rating: correction,
        };
      }
    }

    setAddRatingLoadings(true);
    add_rating(payload)
      .then(async (res) => {
        if (res.status === 1) {
          await fetch_initial_details_for_carrier(false);
          toast("Carrier Rating Added Successfully");
          setAddRatingLoadings(false);
          setSelectedRating(1);
          window.location.reload();
        } else {
          toast(res.msg);
          setSelectedRating(1);
          setAddRatingLoadings(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setSelectedRating(1);
        setAddRatingLoadings(false);
      });

    // if (!otif || otif < 1) {
    //   toast("Please enter OTIF rating");
    //   return;
    // } else if (!platform || platform < 1) {
    //   toast("Please enter Platform obligation rating");
    //   return;
    // } else if (!correction || correction < 1) {
    //   toast("Please enter correction rating");
    //   return;
    // } else {
    //   const payload = {
    //     rating_receipent_id: carrierId,
    //     reviewer_id: user_id,
    //     quote_id: quotation_id,
    //     OTIF_Rating: otif,
    //     Platform_Obligation_Rating: platform,
    //     Correction_Rating: correction,
    //   };
    //   setAddRatingLoading(true);
    //   add_rating(payload)
    //     .then(async (res) => {
    //       if (res.status === 1) {
    //         await fetch_initial_details_for_carrier(false);
    //         toast("Carrier Rating Added Successfully");
    //         setAddRatingLoading(false);
    //       } else {
    //         toast(res.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // }
  };

  const handleDownload = (path) => {
    const pdfUrl = DOWNLOAD_FILE_URL + path;
    download_file_from_server(pdfUrl);
  };

  useEffect(() => {
    const handleLoginEvent = () => {
      const loggedIn = localStorage.getItem("logged");
      if (loggedIn !== "reset") {
        add_checks(setOrderListData, JSON.parse(loggedIn));
      } else {
        fetchOrderData();
      }
    };
    window.addEventListener("onLoggedIn", handleLoginEvent);
    return () => {
      window.removeEventListener("onLoggedIn", handleLoginEvent);
    };
  }, []);

  const add_checks = (setmethod, data) => {
    const newData = data.map((item) => {
      item.checked = false;
      return item;
    });
    setmethod([...newData]);
  };

  const handleAllCheckboxChange = () => {
    const newData = order_list.map((item) => {
      item.checked = !allSheetSelected;
      return item;
    });
    setOrderListData([...newData]);
    setAllSheetSelected(!allSheetSelected);
    setSelectedItemCount(allSheetSelected ? 0 : order_list.length);
  };

  const handleCheckboxChange = (index) => {
    const newData = [...order_list];
    newData[index].checked = !newData[index].checked;
    setOrderList(newData);

    const newSelectedCount = newData.filter((item) => item.checked).length;
    setSelectedItemCount(newSelectedCount);
    setAllSheetSelected(newSelectedCount === order_list.length);
  };

  const handleExport = () => {
    const selectedItems = order_list.filter((item) => item.checked);
    exportData111(selectedItems, true);
  };

  const exportData111 = async (order_list, disableCheckboxes) => {
    var exportData = [];
    order_list.forEach((item) => {
      const loadingInfo = [];
      const deliveryInfo = [];
      const materialInfo = [];

      item["LOADING_INFORMATIONS"].forEach((val) => {
        loadingInfo.push({
          loading_reference: val.Reference ? val.Reference : null,
          loading_date: moment(val.date_Date).format("DD.MM.YYYY"),
          loading_address: val.city,
          applicant: item.user_company_name,
        });
      });

      item["DELIVERY_INFORMATIONS"].forEach((val) => {
        deliveryInfo.push({
          unloading_reference: val.Reference ? val.Reference : null,
          unloading_date: moment(val.delivery_Date).format("DD.MM.YYYY"),
          unloading_address: val.delivery_city,
        });
      });

      item["MaterialInformation"].forEach((val) => {
        materialInfo.push({
          total_weight: val.totalWeightOfPallets,
        });
      });

      const mergedInfo = loadingInfo.map((info, index) => ({
        ...info,
        ...deliveryInfo[index],
        ...materialInfo[index],
      }));

      mergedInfo.forEach((info) => {
        exportData.push({
          reference_number: item.driver_trackingNumber,
          vehicle_plate: item.vehicle_plate_no,
          vehicle: item.category,
          status: item.Status,
          prize: item.prize,
          carrier: item.carrier_company_name,
          distance: Number(item.distance).toFixed(0) + " km",
          ...info,
          ...info,
        });
      });
    });

    const keyOrder = [
      "reference_number",
      "vehicle_plate",
      "status",
      "total_weight",
      "loading_address",
      "loading_date",
      "unloading_address",
      "unloading_date",
      "distance",
      "prize",
      "vehicle",
      "carrier",
      "applicant",
    ];
    consoleService("exportData = ", exportData);
    var finalResult = [];

    exportData.forEach((data) => {
      const newData = {};

      keyOrder.forEach((key) => {
        newData[key] = data[key] || "";
      });
      finalResult.push(newData);
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Orders");

    const headers = keyOrder;
    worksheet.addRow(headers);

    finalResult.forEach((data) => {
      const row = [];
      headers.forEach((header) => {
        row.push(data[header]);
      });
      worksheet.addRow(row);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "orders.xlsx");
    fetchOrderData();
    setSelectedItemCount(0);
    setAllSheetSelected(false);
    setthShow(!thHide);
  };

  const handleSelectOrder = (item) => {
    setSelectedOrderCategory(item);
    if (item === "") {
      fetchOrderData();
    } else {
      const payload = {
        StaffId: userId,
        OderDeatilid: "",
        type: item,
      };
      setIsLoading(true);
      staff_SelectOrder(payload)
        .then((res) => {
          if (res.status === true) {
            add_checks(setOrderListData, res.data);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }
  };

  const get_tag_icon = (status) => {
    let icon;

    switch (status) {
      case "Supplier":
        icon = (
          <div className="badegCus supplier">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path
                d="M6.99996 13.2707C3.54079 13.2707 0.729126 10.459 0.729126 6.99984C0.729126 3.54067 3.54079 0.729004 6.99996 0.729004C10.4591 0.729004 13.2708 3.54067 13.2708 6.99984C13.2708 10.459 10.4591 13.2707 6.99996 13.2707ZM6.99996 1.604C4.02496 1.604 1.60413 4.02484 1.60413 6.99984C1.60413 9.97484 4.02496 12.3957 6.99996 12.3957C9.97496 12.3957 12.3958 9.97484 12.3958 6.99984C12.3958 4.02484 9.97496 1.604 6.99996 1.604Z"
                fill="#FF9900"
              />
              <path
                d="M9.16421 9.29253C9.08837 9.29253 9.01254 9.27503 8.94254 9.22836L7.13421 8.14919C6.68504 7.88086 6.35254 7.29169 6.35254 6.77253V4.38086C6.35254 4.14169 6.55087 3.94336 6.79004 3.94336C7.02921 3.94336 7.22754 4.14169 7.22754 4.38086V6.77253C7.22754 6.98253 7.40254 7.29169 7.58337 7.39669L9.39171 8.47586C9.60171 8.59836 9.66587 8.86669 9.54337 9.07669C9.45587 9.21669 9.31004 9.29253 9.16421 9.29253Z"
                fill="#FF9900"
              />
            </svg>
            Supplier
          </div>
        );
        break;
      case "On Hold":
        icon = (
          <div className="badegCus hold">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path
                d="M10.57 12.6875C10.22 12.6875 9.86417 12.6058 9.52584 12.4367L7.63584 11.4917C7.23917 11.2933 6.76084 11.2933 6.35834 11.4917L4.46834 12.4367C3.55834 12.8917 2.50834 12.7167 1.79667 11.9875C1.085 11.2583 0.927503 10.2083 1.40584 9.30417L4.96417 2.54333C5.36084 1.785 6.1425 1.3125 7 1.3125C7.8575 1.3125 8.63917 1.785 9.03584 2.54333L12.5942 9.30417C13.0667 10.2083 12.915 11.2583 12.2033 11.9875C11.7542 12.4483 11.1708 12.6875 10.57 12.6875ZM7 10.465C7.35584 10.465 7.70584 10.5467 8.02667 10.7042L9.91667 11.6492C10.5992 11.9933 11.2233 11.7308 11.5733 11.3692C11.9233 11.0075 12.1742 10.3833 11.8183 9.70667L8.26 2.94583C8.00917 2.47333 7.53667 2.1875 7 2.1875C6.46334 2.1875 5.99084 2.47333 5.74 2.95167L2.18167 9.7125C1.82584 10.3892 2.07084 11.0192 2.42667 11.375C2.7825 11.7308 3.40084 11.9933 4.08334 11.655L5.97334 10.71C6.29417 10.5467 6.64417 10.465 7 10.465Z"
                fill="#9B51E0"
              />
            </svg>
            On hold
          </div>
        );
        break;

      case "Delivered":
        icon = (
          <div className="badegCus delivered">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path
                d="M11.0833 13.2708C10.1383 13.2708 9.24582 12.7808 8.74416 11.9758C8.47582 11.5616 8.32416 11.0658 8.3125 10.5583C8.295 9.70658 8.65667 8.91326 9.30417 8.37659C9.78834 7.97409 10.395 7.7466 11.0192 7.7291C11.7775 7.72327 12.46 7.98577 12.9967 8.4991C13.5333 9.01244 13.8367 9.70078 13.8483 10.4416C13.86 10.9491 13.7317 11.4449 13.475 11.8824C13.335 12.1274 13.1542 12.3549 12.9383 12.5474C12.46 12.9966 11.8183 13.2591 11.1358 13.2708C11.1242 13.2708 11.1067 13.2708 11.0833 13.2708ZM11.0833 8.6041C11.0717 8.6041 11.0542 8.6041 11.0425 8.6041C10.6108 8.61577 10.2025 8.76744 9.86417 9.04744C9.42083 9.41494 9.17583 9.95744 9.1875 10.5408C9.19333 10.8849 9.29833 11.2233 9.47916 11.5091C9.835 12.0808 10.4533 12.4308 11.1183 12.3958C11.5792 12.3841 12.0167 12.2091 12.3492 11.8999C12.5008 11.7658 12.6233 11.6141 12.7167 11.4508C12.8917 11.1474 12.9792 10.8091 12.9733 10.4649C12.9617 9.95743 12.7575 9.48492 12.39 9.13492C12.04 8.79075 11.5733 8.6041 11.0833 8.6041Z"
                fill="#27AE60"
              />
              <path
                d="M10.7625 11.521C10.6516 11.521 10.5466 11.4801 10.4591 11.3985L9.86997 10.8385C9.69497 10.6693 9.68913 10.3952 9.85829 10.2202C10.0275 10.0452 10.3016 10.0393 10.4766 10.2085L10.7625 10.4826L11.6783 9.59598C11.8533 9.42681 12.1275 9.43262 12.2966 9.60762C12.4658 9.78262 12.46 10.0568 12.285 10.226L11.0658 11.4043C10.9783 11.4801 10.8675 11.521 10.7625 11.521Z"
                fill="#27AE60"
              />
              <path
                d="M6.99995 7.75817C6.92412 7.75817 6.84828 7.74069 6.77828 7.69985L1.62745 4.71903C1.41745 4.59653 1.34744 4.32818 1.46994 4.11818C1.59244 3.90818 1.86079 3.83817 2.06495 3.96067L6.99411 6.81317L11.8941 3.97818C12.1041 3.85568 12.3725 3.93153 12.4891 4.13569C12.6116 4.34569 12.5358 4.61401 12.3316 4.73651L7.21578 7.69985C7.15161 7.73485 7.07578 7.75817 6.99995 7.75817Z"
                fill="#27AE60"
              />
              <path
                d="M7 13.0433C6.76083 13.0433 6.5625 12.8449 6.5625 12.6058V7.31494C6.5625 7.07577 6.76083 6.87744 7 6.87744C7.23917 6.87744 7.4375 7.07577 7.4375 7.31494V12.6058C7.4375 12.8449 7.23917 13.0433 7 13.0433Z"
                fill="#27AE60"
              />
              <path
                d="M6.99997 13.2706C6.48664 13.2706 5.9733 13.1598 5.57663 12.9323L2.46164 11.2056C1.6158 10.739 0.950806 9.61313 0.950806 8.6448V5.34311C0.950806 4.37478 1.6158 3.2548 2.46164 2.7823L5.57663 1.05562C6.36997 0.606458 7.6183 0.606458 8.41747 1.05562L11.5325 2.7823C12.3783 3.24897 13.0433 4.37478 13.0433 5.34311V8.6448C13.0433 8.70313 13.0433 8.74978 13.0316 8.80811C13.0025 8.95978 12.8916 9.08811 12.7458 9.13478C12.6 9.18728 12.4366 9.1523 12.3141 9.05314C11.6433 8.4698 10.605 8.44647 9.89914 9.0123C9.44997 9.36813 9.18747 9.91061 9.18747 10.4881C9.18747 10.8323 9.2808 11.1706 9.46164 11.4623C9.5083 11.544 9.55497 11.6081 9.60747 11.6723C9.69497 11.7715 9.72998 11.9056 9.70665 12.034C9.68331 12.1623 9.60164 12.2731 9.48498 12.3373L8.41747 12.9264C8.0208 13.1598 7.5133 13.2706 6.99997 13.2706ZM6.99997 1.60396C6.6383 1.60396 6.2708 1.6798 6.0083 1.82563L2.8933 3.55231C2.32746 3.86148 1.83747 4.70144 1.83747 5.34311V8.6448C1.83747 9.28646 2.3333 10.1265 2.8933 10.4356L6.0083 12.1623C6.53913 12.4598 7.46663 12.4598 7.99747 12.1623L8.6508 11.8006C8.43496 11.4098 8.31831 10.9548 8.31831 10.4881C8.31831 9.63645 8.69747 8.84897 9.35664 8.32397C10.15 7.68814 11.2816 7.55395 12.1741 7.93311V5.33147C12.1741 4.6898 11.6783 3.8498 11.1183 3.54063L8.0033 1.81395C7.72914 1.67978 7.36164 1.60396 6.99997 1.60396Z"
                fill="#27AE60"
              />
            </svg>
            Delivered
          </div>
        );
        break;

      case "On route":
        icon = (
          <div className="badegCus onroute">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path
                d="M7.58324 8.604H6.99991C6.76074 8.604 6.56241 8.40567 6.56241 8.1665C6.56241 7.92734 6.76074 7.729 6.99991 7.729H7.58324C7.98574 7.729 8.31241 7.40234 8.31241 6.99984V1.604H3.49991C2.81158 1.604 2.18156 1.97733 1.84323 2.57816C1.72656 2.78816 1.45825 2.86402 1.24825 2.74735C1.03825 2.63068 0.962397 2.36234 1.07906 2.15234C1.56906 1.27734 2.49658 0.729004 3.49991 0.729004H8.74991C8.98908 0.729004 9.18741 0.927337 9.18741 1.1665V6.99984C9.18741 7.8865 8.46991 8.604 7.58324 8.604Z"
                fill="#2F80ED"
              />
              <path
                d="M11.0833 12.104H10.5C10.2608 12.104 10.0625 11.9057 10.0625 11.6665C10.0625 11.264 9.73579 10.9373 9.33329 10.9373C8.93079 10.9373 8.60413 11.264 8.60413 11.6665C8.60413 11.9057 8.40579 12.104 8.16663 12.104H5.83329C5.59413 12.104 5.39579 11.9057 5.39579 11.6665C5.39579 11.264 5.06913 10.9373 4.66663 10.9373C4.26413 10.9373 3.93746 11.264 3.93746 11.6665C3.93746 11.9057 3.73913 12.104 3.49996 12.104H2.91663C1.70913 12.104 0.729126 11.124 0.729126 9.9165C0.729126 9.67734 0.927459 9.479 1.16663 9.479C1.40579 9.479 1.60413 9.67734 1.60413 9.9165C1.60413 10.6398 2.19329 11.229 2.91663 11.229H3.12078C3.31328 10.5582 3.93163 10.0623 4.66663 10.0623C5.40163 10.0623 6.01997 10.5582 6.21247 11.229H7.79328C7.98578 10.5582 8.60413 10.0623 9.33913 10.0623C10.0741 10.0623 10.6924 10.5582 10.8849 11.229H11.0833C11.8066 11.229 12.3958 10.6398 12.3958 9.9165V8.604H11.0833C10.5233 8.604 10.0625 8.14317 10.0625 7.58317V5.83317C10.0625 5.27317 10.5175 4.81234 11.0833 4.81234L10.4591 3.72151C10.3308 3.49401 10.0858 3.354 9.82331 3.354H9.18746V6.99984C9.18746 7.8865 8.46996 8.604 7.58329 8.604H6.99996C6.76079 8.604 6.56246 8.40567 6.56246 8.1665C6.56246 7.92734 6.76079 7.729 6.99996 7.729H7.58329C7.98579 7.729 8.31246 7.40234 8.31246 6.99984V2.9165C8.31246 2.67734 8.51079 2.479 8.74996 2.479H9.82331C10.4008 2.479 10.9316 2.78818 11.2174 3.28985L12.215 5.03401C12.2908 5.16817 12.2908 5.33734 12.215 5.47151C12.1391 5.60567 11.9933 5.68734 11.8358 5.68734H11.0833C11.0016 5.68734 10.9375 5.7515 10.9375 5.83317V7.58317C10.9375 7.66484 11.0016 7.729 11.0833 7.729H12.8333C13.0725 7.729 13.2708 7.92734 13.2708 8.1665V9.9165C13.2708 11.124 12.2908 12.104 11.0833 12.104Z"
                fill="#2F80ED"
              />
              <path
                d="M4.66667 13.2708C3.78 13.2708 3.0625 12.5533 3.0625 11.6667C3.0625 10.78 3.78 10.0625 4.66667 10.0625C5.55333 10.0625 6.27083 10.78 6.27083 11.6667C6.27083 12.5533 5.55333 13.2708 4.66667 13.2708ZM4.66667 10.9375C4.26417 10.9375 3.9375 11.2642 3.9375 11.6667C3.9375 12.0692 4.26417 12.3958 4.66667 12.3958C5.06917 12.3958 5.39583 12.0692 5.39583 11.6667C5.39583 11.2642 5.06917 10.9375 4.66667 10.9375Z"
                fill="#2F80ED"
              />
              <path
                d="M9.33329 13.2708C8.44663 13.2708 7.72913 12.5533 7.72913 11.6667C7.72913 10.78 8.44663 10.0625 9.33329 10.0625C10.22 10.0625 10.9375 10.78 10.9375 11.6667C10.9375 12.5533 10.22 13.2708 9.33329 13.2708ZM9.33329 10.9375C8.93079 10.9375 8.60413 11.2642 8.60413 11.6667C8.60413 12.0692 8.93079 12.3958 9.33329 12.3958C9.73579 12.3958 10.0625 12.0692 10.0625 11.6667C10.0625 11.2642 9.73579 10.9375 9.33329 10.9375Z"
                fill="#2F80ED"
              />
              <path
                d="M12.8333 8.60417H11.0833C10.5233 8.60417 10.0625 8.14333 10.0625 7.58333V5.83333C10.0625 5.27333 10.5233 4.8125 11.0833 4.8125H11.8358C11.9933 4.8125 12.1392 4.89417 12.215 5.03417L13.2125 6.78417C13.2475 6.84834 13.2708 6.92417 13.2708 7V8.16667C13.2708 8.40583 13.0725 8.60417 12.8333 8.60417ZM11.0833 5.6875C11.0017 5.6875 10.9375 5.75167 10.9375 5.83333V7.58333C10.9375 7.665 11.0017 7.72917 11.0833 7.72917H12.3958V7.11667L11.5792 5.6875H11.0833Z"
                fill="#2F80ED"
              />
              <path
                d="M4.66663 5.104H1.16663C0.927459 5.104 0.729126 4.90567 0.729126 4.6665C0.729126 4.42734 0.927459 4.229 1.16663 4.229H4.66663C4.90579 4.229 5.10413 4.42734 5.10413 4.6665C5.10413 4.90567 4.90579 5.104 4.66663 5.104Z"
                fill="#2F80ED"
              />
              <path
                d="M3.49996 6.854H1.16663C0.927459 6.854 0.729126 6.65567 0.729126 6.4165C0.729126 6.17734 0.927459 5.979 1.16663 5.979H3.49996C3.73913 5.979 3.93746 6.17734 3.93746 6.4165C3.93746 6.65567 3.73913 6.854 3.49996 6.854Z"
                fill="#2F80ED"
              />
              <path
                d="M2.33329 8.604H1.16663C0.927459 8.604 0.729126 8.40567 0.729126 8.1665C0.729126 7.92734 0.927459 7.729 1.16663 7.729H2.33329C2.57246 7.729 2.77079 7.92734 2.77079 8.1665C2.77079 8.40567 2.57246 8.604 2.33329 8.604Z"
                fill="#2F80ED"
              />
            </svg>
            On route
          </div>
        );
        break;
    }
    return icon;
  };

  const handleOpenMaterialDetails = (
    index,
    quotationId,
    otif_value,
    platform_value,
    correction_value
  ) => {
    setOpenedMaterialIndex(index);
    set_view_order_status_carrier_fun(quotationId);
    setOtif(otif_value);
    setPlatform(platform_value);
    setCorrection(correction_value);
  };

  const set_view_order_status_carrier_fun = async (quote_id) => {
    try {
      const payload = {
        quotation_id: quote_id,
      };
      const set_view_status = async () => {
        let FetchedRes;
        // if (isRoleCarrier) {
        //   FetchedRes = await set_view_order_status_carrier(payload);
        // } else if (isRoleUser) {
        //   FetchedRes = await set_view_order_status_user(payload);
        // }
        return FetchedRes;
      };
      const res = await set_view_status();
      if (res.status === 1) {
        await fetch_initial_details_for_carrier(false);
        consoleService("carrier user viewed the order.");
      }
    } catch (err) {
      consoleService("error in set_view_order_status_carrier_fun  = ", err);
    }
  };

  const check_new_status = (item) => {
    let isNew = false;
    if (isRoleCarrier) {
      isNew = !item.carrier_view_status;
    } else if (isRoleUser) {
      isNew = !item.user_view_status;
    }
    return isNew;
  };
  return (
    <React.Fragment>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          <div className="topheader">
            <div className="container-fluid">
              <div className="topheader_flex">
                <Button
                  variant="primary"
                  className="menuBtn"
                  onClick={toggleBodyClass}
                >
                  <FiMenu />
                </Button>
                <div className="inPutBox">
                  <Form.Control
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(text) => {
                      handleSearch(text.target.value);
                      setSearchQuery(text.target.value);
                    }}
                  />
                  <Image src={SearchIcon} alt="" />
                </div>
                <Link className="notiIcon">
                  <Image src={NotificationIcon} alt="" />
                  <Badge bg="danger">5</Badge>
                </Link>
              </div>
            </div>
          </div>

          <FilterSidebar
            close={handleToggle}
            handleFilterFun={handleSideFilter}
            type={"0"}
            input_permissoins={[
              input_key_permissions.delivery_contact_email,
              input_key_permissions.delivery_contact_name,
              input_key_permissions.delivery_contact_phone,
              input_key_permissions.loading_contact_email,
              input_key_permissions.loading_contact_name,
              input_key_permissions.loading_contact_phone,
            ]}
          />

          {initialLoading && (
            <Container fluid className="pt-100">
              <div className="quotes_div">
                <div className="orderHeaderFlex mb-3">
                  <div className="orderHeader">
                    <h2
                      onClick={() => fetch_initial_details_for_carrier(true)}
                      className={selectedOrderCategory === "" && `active`}
                    >
                      All orders{" "}
                      <Badge pill bg="secondary">
                        {all_orders}
                      </Badge>
                    </h2>
                    <h2
                      onClick={() =>
                        fetch_initial_details_for_carrier(true, "hold on")
                      }
                      className={
                        selectedOrderCategory === "hold on" && `active`
                      }
                    >
                      On hold{" "}
                      <Badge pill bg="secondary">
                        {hold_on}
                      </Badge>
                    </h2>
                    <h2
                      onClick={() =>
                        fetch_initial_details_for_carrier(true, "supplier")
                      }
                      className={
                        selectedOrderCategory === "supplier" && `active`
                      }
                    >
                      Supplier{" "}
                      <Badge pill bg="secondary">
                        {supplier}
                      </Badge>
                    </h2>
                    <h2
                      onClick={() =>
                        fetch_initial_details_for_carrier(true, "on route")
                      }
                      className={
                        selectedOrderCategory === "on route" && `active`
                      }
                    >
                      On route{" "}
                      <Badge pill bg="secondary">
                        {on_route}
                      </Badge>
                    </h2>
                    <h2
                      onClick={() =>
                        fetch_initial_details_for_carrier(true, "delivered")
                      }
                      className={
                        selectedOrderCategory === "delivered" && `active`
                      }
                    >
                      Delivered{" "}
                      <Badge pill bg="secondary">
                        {delivered}
                      </Badge>
                    </h2>
                  </div>
                  <div className="fiphone">
                    {!isRoleClient && (
                      <Link onClick={toggleTh} className="filter_btn me-4">
                        <Image src={ExportIcon} width={20} alt="" /> Export
                      </Link>
                    )}
                    <Link to={`/archives`} className="filter_btn me-4">
                      <Image src={ArchiveIcon} width={20} alt="" /> Archives
                    </Link>
                    <Link onClick={handleToggle} className="filter_btn">
                      <Image src={FilterIcon} width={20} alt="" /> Filters
                    </Link>
                  </div>
                </div>

                {thHide && (
                  <div className="exportStrip">
                    <div className="d-flex align-items-center">
                      <IoIosCloseCircleOutline onClick={toggleTh} />
                      <p className="ms-2">
                        <strong>{selectedItemCount}</strong> element selected
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        if (selectedItemCount === 0) {
                          toast("Plesae select element");
                        } else {
                          handleExport();
                        }
                      }}
                    >
                      Export to Excel
                    </button>
                  </div>
                )}

                <div className="react_table">
                  <Table responsive>
                    <thead>
                      <tr>
                        {thHide && (
                          <th>
                            <div className="textColor">
                              <Form.Check
                                type="checkbox"
                                label={`All`}
                                checked={allSheetSelected}
                                onChange={handleAllCheckboxChange}
                              />
                            </div>
                          </th>
                        )}

                        <th></th>
                        <th>STATUS</th>
                        <th style={{ width: 130, minWidth: 130 }}>REFERENCE</th>
                        <th style={{ width: 160, minWidth: 160 }}>LOADING</th>
                        <th>DISTANCE</th>
                        <th style={{ width: 160, minWidth: 160 }}>ETA</th>
                        <th style={{ width: 130, minWidth: 130 }}>VEHICLE</th>
                        {role !== "Carrier" && (
                          <>
                            <th style={{ width: 100, minWidth: 100 }}>
                              CARRIER
                            </th>
                            {role !== "User" && role !== "Client" && (
                              <th>APPLICANT</th>
                            )}
                          </>
                        )}
                        <th>PRICE</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>

                    {order_list.length === 0 && (
                      <tr>
                        <td colSpan={9} className="text-center  pt-3 ">
                          No orders
                        </td>
                      </tr>
                    )}

                    <tbody>
                      {List_data.result.map((item, index) => {
                        const category = item.category;
                        const isCategoryTanker = category == "Tanker";
                        const isCategoryRefrigerated =
                          category == "Refrigerated";
                        const isCategoryGeneralCargo =
                          category == "General cargo";

                        var distanceNumber = Number(item.distance);
                        if (!isNaN(distanceNumber)) {
                          var distanceWithTwoDecimalPlaces =
                            distanceNumber.toFixed(0) + " km";
                        } else {
                          console.log(
                            "Error: item.distance is not a valid number."
                          );
                        }

                        const loadingTime =
                          item.LOADING_INFORMATIONS &&
                          item.LOADING_INFORMATIONS[0].Hours;
                        const loadingConvertedTime = item
                          .LOADING_INFORMATIONS[0].Hours
                          ? loadingTime.replace(":", "h")
                          : "";

                        const deliveryTime =
                          item.DELIVERY_INFORMATIONS[0] &&
                          item.DELIVERY_INFORMATIONS[0].delivery_Hour;

                        const deliveryConvertedTime = item
                          .DELIVERY_INFORMATIONS[0].delivery_Hour
                          ? deliveryTime.replace(":", "h")
                          : "";

                        const { DocumentPOD, DocumentCSR, DocumentCOA } = item;

                        const string_index = index.toString();

                        const isOpen =
                          opened_meterial_details_index == string_index;

                        const isAddRatingLoading = isOpen && add_rating_loading;
                        // const isDisableRating =
                        //   !isRoleUser && !isRoleStaff && !isRoleClient;

                        const carrierRating = item.carrier_rating;

                        const correction_value =
                          carrierRating.Correction_Rating;

                        const platform_value =
                          carrierRating.Platform_Obligation_Rating;

                        const otif_value = carrierRating.OTIF_Rating;
                        const rating_value = carrierRating.rating;
                        // const selectedRating = carrierRating.rating;
                        const comment = carrierRating.comment;

                        const isDisableRatingBtn =
                          role === "User" || role === "Client"
                            ? Number(rating_value)
                            : Number(correction_value) ||
                            Number(platform_value) ||
                            Number(otif_value);

                        // const isDisableRating =
                        //   correction_value && platform_value && otif_value;
                        const isDisableRating =
                          role === "User" || role === "Client"
                            ? Number(rating_value)
                            : Number(correction_value) ||
                            Number(platform_value) ||
                            Number(otif_value);

                        // const isDisableRatingBtn =
                        //   Number(rating_value) ||
                        //   Number(platform_value) ||
                        //   Number(otif_value);

                        const quotationId = item._id;
                        const total_rating =
                          Number(otif) + Number(platform) + Number(correction);
                        // const average_rating = (total_rating / 3).toFixed(1);
                        const average_rating =
                          role === "User" || role === "Client"
                            ? rating_value
                            : (total_rating / 3).toFixed(1);

                        const docs = item.docs;
                        const POD_DOC = docs.p_o_d;
                        const BL_DOC = docs.b_l;
                        const COA_DOC = docs.c_o_a;
                        const isDocFound = POD_DOC || BL_DOC || COA_DOC;

                        const DeliveryCity =
                          item.DELIVERY_INFORMATIONS &&
                            item.DELIVERY_INFORMATIONS[0].deliveryCity
                            ? item.DELIVERY_INFORMATIONS[0].deliveryCity
                            : item.DELIVERY_INFORMATIONS[0].deliveryState;

                        const LoadingCity =
                          item.LOADING_INFORMATIONS &&
                            item.LOADING_INFORMATIONS[0].loadingCity
                            ? item.LOADING_INFORMATIONS[0].loadingCity
                            : item.LOADING_INFORMATIONS[0].loadingState;

                        // const CompanyName = item.user_company_name
                        //   ? item.user_company_name
                        //   : item.staff_user_company_name
                        //   ? item.staff_user_company_name
                        //   : "N/A";

                        const CompanyName =
                          item.user_company_name == ""
                            ? item.applicant
                            : "Mottruck";

                        const statuscheck = item.order_status;
                        const loadingInfo = item.LOADING_INFORMATIONS?.[0];
                        const today = new Date();

                        let finalStatus;

                        if (!statuscheck) {
                          // If order_status is blank
                          if (loadingInfo && loadingInfo.Date) {
                            const loadingDate = new Date(loadingInfo.Date);

                            if (loadingDate < today) {
                              finalStatus = "Supplier";
                            } else {
                              finalStatus = "On Hold";
                            }
                          } else {
                            finalStatus = ""; // If no loading information is available, status remains blank
                          }
                        } else {
                          // If order_status is not blank, use the existing status
                          finalStatus = statuscheck;
                        }

                        return (
                          <>
                            {
                              <>
                                <tr>
                                  {thHide && (
                                    <td>
                                      <div className="textColor">
                                        <Form.Check
                                          checked={item.checked}
                                          onChange={() =>
                                            handleCheckboxChange(index)
                                          }
                                          type="checkbox"
                                        />
                                      </div>
                                    </td>
                                  )}

                                  <td>{calculateCount(index)}</td>
                                  <td>
                                    {/* <div className={`badegCus hold`}>
                                      <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                      >
                                        <path
                                          d="M10.57 12.6875C10.22 12.6875 9.8642 12.6058 9.52587 12.4367L7.63587 11.4917C7.2392 11.2933 6.76087 11.2933 6.35837 11.4917L4.46837 12.4367C3.55837 12.8917 2.50837 12.7167 1.7967 11.9875C1.08503 11.2583 0.927534 10.2083 1.40587 9.30417L4.9642 2.54333C5.36087 1.785 6.14253 1.3125 7.00003 1.3125C7.85753 1.3125 8.6392 1.785 9.03587 2.54333L12.5942 9.30417C13.0667 10.2083 12.915 11.2583 12.2034 11.9875C11.7542 12.4483 11.1709 12.6875 10.57 12.6875ZM7.00003 10.465C7.35587 10.465 7.70587 10.5467 8.0267 10.7042L9.9167 11.6492C10.5992 11.9933 11.2234 11.7308 11.5734 11.3692C11.9234 11.0075 12.1742 10.3833 11.8184 9.70667L8.26003 2.94583C8.0092 2.47333 7.5367 2.1875 7.00003 2.1875C6.46337 2.1875 5.99087 2.47333 5.74003 2.95167L2.1817 9.7125C1.82587 10.3892 2.07087 11.0192 2.4267 11.375C2.78253 11.7308 3.40087 11.9933 4.08337 11.655L5.97337 10.71C6.2942 10.5467 6.6442 10.465 7.00003 10.465Z"
                                          fill="#9B51E0"
                                        />
                                      </svg>
                                      {item.routeStatus}
                                    </div> */}
                                    {/* {get_tag_icon(item.order_status)} */}
                                    <div
                                      style={{
                                        flexDirection: "column",
                                        display: "flex",
                                        columnGap: 10,
                                      }}
                                    >
                                      <span>{get_tag_icon(finalStatus)}</span>

                                      {/* {item?.eta && finalStatus === "On route" && (
                                      <>
                                        <span
                                          style={{
                                            color: item?.eta.includes("-") ? "#27AE60" : "#EB5757",
                                            fontWeight: 400,
                                            marginTop: 10,
                                            display: "inline-block",
                                          }}
                                        >
                                          <Image
                                            src={item?.eta.includes("-") ? InfoCircle : Danger}
                                            width={20}
                                            alt=""
                                          />{" "}
                                          {item.eta}
                                        </span>
                                      </>
                                    )} */}
                                    </div>
                                  </td>
                                  <td>
                                    <h4 className="name_text mb-1">
                                      {item.driver_trackingNumber}
                                      {check_new_status(item) && (
                                        <Badge bg="danger" className="cubd">
                                          New
                                        </Badge>
                                      )}
                                    </h4>
                                    <h4 className="datetime_text">
                                      {moment(item.createdAt).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </h4>
                                  </td>
                                  <td>
                                    <h4 className="name_text mb-1">
                                      {LoadingCity}

                                      {LoadingCity && "-"}
                                      {item.LOADING_INFORMATIONS &&
                                        item.LOADING_INFORMATIONS[0]
                                          .loadingCountry &&
                                        item.LOADING_INFORMATIONS[0]
                                          .loadingCountry}
                                    </h4>
                                    <h4 className="datetime_text">
                                      {moment(
                                        item.LOADING_INFORMATIONS[0].Date
                                      ).format("DD.MM.YYYY")}
                                      {" - "}
                                      {loadingConvertedTime}
                                    </h4>
                                  </td>
                                  <td>
                                    <h4 className="name_text mb-1">
                                      {distanceWithTwoDecimalPlaces}
                                    </h4>
                                    <Image
                                      className="tableArrow_img"
                                      src={tablearrow}
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    <h4 className="name_text mb-1">
                                      {DeliveryCity}
                                      {DeliveryCity && "-"}
                                      {item.DELIVERY_INFORMATIONS &&
                                        item.DELIVERY_INFORMATIONS[0]
                                          .deliveryCountry &&
                                        item.DELIVERY_INFORMATIONS[0]
                                          .deliveryCountry}
                                    </h4>
                                    <h4 className="datetime_text">
                                      {moment(
                                        item.DELIVERY_INFORMATIONS[0]
                                          .delivery_Date
                                      ).format("DD.MM.YYYY")}{" "}
                                      {"- "}
                                      {deliveryConvertedTime}
                                    </h4>
                                  </td>
                                  <td>
                                    <h4 className="name_text mb-1">
                                      {item.category}
                                    </h4>
                                    <h4 className="datetime_text">
                                      {item.vehicle_plate_no}
                                    </h4>
                                  </td>
                                  {role !== "Carrier" && (
                                    <>
                                      <td>
                                        <h4 className="name_text mb-1">
                                          {item.carrier_company_name}
                                        </h4>
                                      </td>
                                      {role !== "User" && role !== "Client" && (
                                        <td>
                                          <h4 className="name_text mb-1">
                                            {CompanyName}
                                          </h4>
                                          {item?.accountNumber && (
                                            <h4 className="name_text mb-1">
                                              {item?.accountNumber}
                                            </h4>
                                          )}
                                        </td>
                                      )}
                                    </>
                                  )}
                                  <td>
                                    <h4 className="name_text mb-1">
                                      {role === "User"
                                        ? `${(
                                          Number(item.prize) +
                                          Number(item.margin)
                                        ).toFixed(2)}`
                                        : `${item.prize}`}{" "}
                                      €
                                    </h4>
                                  </td>
                                  <td>
                                    {isOpen ? (
                                      <FiChevronUp
                                        size={30}
                                        color="#f7ce6c"
                                        className="curpointer"
                                        onClick={() => {
                                          setOpenedMaterialIndex("");
                                        }}
                                      />
                                    ) : (
                                      <FiChevronDown
                                        size={30}
                                        color="#4F5B92"
                                        className="curpointer"
                                        onClick={() => {
                                          handleOpenMaterialDetails(
                                            index,
                                            quotationId,
                                            otif_value,
                                            platform_value,
                                            correction_value
                                          );
                                        }}
                                      />
                                    )}
                                  </td>
                                  {(isRoleStaff &&
                                    <td>
                                      <div className="mb-2">
                                        {!isRoleClient &&
                                          ["start"].map((direction) => (
                                            <DropdownButton
                                              as={ButtonGroup}
                                              key={direction}
                                              id={`dropdown-button-drop-${direction}`}
                                              drop={direction}
                                              className="tabledrop_btn"
                                              title={<FiMoreVertical />}
                                            >
                                              <Dropdown.Item
                                                eventKey="2"
                                                className="menucancel_link"
                                                onClick={() => {
                                                  handleDeleteClick(
                                                    quotationId,
                                                    item.StaffId
                                                  );
                                                }}
                                              >
                                                <Image
                                                  className="editimgt"
                                                  src={TrashIcon}
                                                  alt=""
                                                />{" "}
                                                Delete
                                              </Dropdown.Item>
                                            </DropdownButton>
                                          ))}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                                <Fade in={isOpen}>
                                  <tr className="showaccordion">
                                    <td colspan="13">
                                      <div className="cardAccordion">
                                        <Container fluid className="px-3">
                                          <Row>
                                            <Col lg={4} md={6}>
                                              <div className="outerBox">
                                                <BreadCrumbHeader
                                                  image={GpsIcon}
                                                  title="EVENTS"
                                                />
                                              </div>
                                              <div className="infoCard">
                                                <ul>
                                                  <li className="active">
                                                    <div className="activeLine">
                                                      <Badge>1</Badge>
                                                    </div>
                                                    <div className="infoCardBody">
                                                      <h3 className="ch1">
                                                        MATERIAL LOADING {
                                                          (!!item.LOADING_INFORMATIONS[0].Reference) ? ' - ' + item.LOADING_INFORMATIONS[0].Reference : item.LOADING_INFORMATIONS[0].Company_name

                                                        }
                                                      </h3>
                                                      <h3 className="ch2">
                                                        {item.LOADING_INFORMATIONS[0].Company_name}
                                                      </h3>
                                                      <h3 className="ch3">
                                                        {
                                                          item
                                                            .LOADING_INFORMATIONS[0]
                                                            .city
                                                        }
                                                      </h3>
                                                      <h3 className="ch4">
                                                        {moment(
                                                          item
                                                            .LOADING_INFORMATIONS[0]
                                                            .Date
                                                        ).format(
                                                          "DD.MM.YYYY"
                                                        )}{" "}
                                                        -{" "}
                                                        {!!item.LOADING_INFORMATIONS &&
                                                          item
                                                            .LOADING_INFORMATIONS
                                                            .length > 0 &&
                                                          item
                                                            .LOADING_INFORMATIONS[0]
                                                            .Hours
                                                          ? loadingTime.replace(
                                                            ":",
                                                            "h"
                                                          )
                                                          : ""}
                                                      </h3>
                                                      <h3 className="ch5">
                                                        <Image
                                                          src={CallIcon}
                                                          alt=""
                                                          width={18}
                                                        />{" "}
                                                        {
                                                          item
                                                            .LOADING_INFORMATIONS[0]
                                                            .Contact_name
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                          item
                                                            .LOADING_INFORMATIONS[0]
                                                            .Contact_code
                                                        }{" "}
                                                        {
                                                          item
                                                            .LOADING_INFORMATIONS[0]
                                                            .Contact_phone
                                                        }
                                                      </h3>

                                                      <h3 className="ch5">
                                                        <PiEnvelopeSimpleLight
                                                          size={20}
                                                          style={{
                                                            marginRight: 3,
                                                          }}
                                                        />

                                                        {
                                                          item
                                                            .LOADING_INFORMATIONS[0]
                                                            .Contact_email
                                                        }
                                                      </h3>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className="activeLine">
                                                      <Badge>2</Badge>
                                                    </div>
                                                    <div className="infoCardBody">
                                                      <h3 className="ch1">
                                                        MATERIAL UNLOADING {
                                                          (!!item.DELIVERY_INFORMATIONS[0].Reference) ? ' - ' + item.DELIVERY_INFORMATIONS[0].Reference : item.DELIVERY_INFORMATIONS[0].Company_name

                                                        }
                                                      </h3>
                                                      <h3 className="ch2">
                                                        {
                                                          item.DELIVERY_INFORMATIONS[0].Company_name

                                                        }
                                                      </h3>
                                                      <h3 className="ch3">
                                                        {
                                                          item
                                                            .DELIVERY_INFORMATIONS[0]
                                                            .delivery_city
                                                        }
                                                      </h3>
                                                      <h3 className="ch4">
                                                        {moment(
                                                          item
                                                            .DELIVERY_INFORMATIONS[0]
                                                            .delivery_Date
                                                        ).format(
                                                          "DD.MM.YYYY"
                                                        )}{" "}
                                                        -{" "}
                                                        {!!item.DELIVERY_INFORMATIONS &&
                                                          item
                                                            .DELIVERY_INFORMATIONS
                                                            .length > 0 &&
                                                          item
                                                            .DELIVERY_INFORMATIONS[0]
                                                            .delivery_Hour
                                                          ? item.DELIVERY_INFORMATIONS[0].delivery_Hour.replace(
                                                            ":",
                                                            "h"
                                                          )
                                                          : ""}
                                                      </h3>
                                                      <h3 className="ch5">
                                                        <Image
                                                          src={CallIcon}
                                                          alt=""
                                                          width={18}
                                                        />{" "}
                                                        {
                                                          item
                                                            .DELIVERY_INFORMATIONS[0]
                                                            .deliver_Contact_name
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                          item
                                                            .DELIVERY_INFORMATIONS[0]
                                                            .delver_code
                                                        }{" "}
                                                        {
                                                          item
                                                            .DELIVERY_INFORMATIONS[0]
                                                            .deliver_contact_phone
                                                        }
                                                      </h3>

                                                      <h3 className="ch5">
                                                        <PiEnvelopeSimpleLight
                                                          size={20}
                                                          style={{
                                                            marginRight: 3,
                                                          }}
                                                        />

                                                        {
                                                          item
                                                            .DELIVERY_INFORMATIONS[0]
                                                            .delivery_conntact_email
                                                        }
                                                      </h3>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </Col>
                                            {item.MaterialInformation && (
                                              <Col lg={4} md={6}>
                                                <div className="outerBox">
                                                  <BreadCrumbHeader
                                                    image={MatIcon}
                                                    title="MATERIAL INFORMATION"
                                                  />
                                                </div>
                                                <div className="matInfoBox">
                                                  <div className="matInfoBoxItems total">
                                                    <h3 className="totalhead">
                                                      Total Material
                                                    </h3>
                                                    {!isCategoryTanker && (
                                                      <React.Fragment>
                                                        <div className="fixWith">
                                                          <span>
                                                            <svg
                                                              width="21"
                                                              height="21"
                                                              viewBox="0 0 21 21"
                                                              fill="none"
                                                            >
                                                              <path
                                                                d="M10.344 10.4002C9.89396 10.4002 9.43563 10.3168 9.07729 10.1585L4.16063 7.97516C2.91063 7.41683 2.72729 6.66683 2.72729 6.2585C2.72729 5.85016 2.91063 5.10016 4.16063 4.54183L9.07729 2.3585C9.80229 2.0335 10.894 2.0335 11.619 2.3585L16.544 4.54183C17.7856 5.09183 17.9773 5.85016 17.9773 6.2585C17.9773 6.66683 17.794 7.41683 16.544 7.97516L11.619 10.1585C11.2523 10.3252 10.8023 10.4002 10.344 10.4002ZM10.344 3.36683C10.0606 3.36683 9.78563 3.4085 9.58563 3.50016L4.66896 5.6835C4.16063 5.91683 3.97729 6.15016 3.97729 6.2585C3.97729 6.36683 4.16063 6.6085 4.66063 6.8335L9.57729 9.01683C9.97729 9.19183 10.7023 9.19183 11.1023 9.01683L16.0273 6.8335C16.5356 6.6085 16.719 6.36683 16.719 6.2585C16.719 6.15016 16.5356 5.9085 16.0273 5.6835L11.1106 3.50016C10.9106 3.41683 10.6273 3.36683 10.344 3.36683Z"
                                                                fill="#1D244A"
                                                              />
                                                              <path
                                                                d="M10.519 14.7415C10.2024 14.7415 9.88571 14.6748 9.58571 14.5415L3.92738 12.0248C3.06904 11.6498 2.39404 10.6082 2.39404 9.6665C2.39404 9.32484 2.67738 9.0415 3.01904 9.0415C3.36071 9.0415 3.64404 9.32484 3.64404 9.6665C3.64404 10.1248 4.01904 10.6998 4.43571 10.8915L10.094 13.4082C10.3607 13.5248 10.669 13.5248 10.944 13.4082L16.6024 10.8915C17.019 10.7082 17.394 10.1248 17.394 9.6665C17.394 9.32484 17.6774 9.0415 18.019 9.0415C18.3607 9.0415 18.644 9.32484 18.644 9.6665C18.644 10.6082 17.969 11.6498 17.1107 12.0332L11.4524 14.5498C11.1524 14.6748 10.8357 14.7415 10.519 14.7415Z"
                                                                fill="#1D244A"
                                                              />
                                                              <path
                                                                d="M10.519 18.9085C10.2024 18.9085 9.88571 18.8419 9.58571 18.7085L3.92738 16.1919C2.99404 15.7752 2.39404 14.8502 2.39404 13.8252C2.39404 13.4835 2.67738 13.2002 3.01904 13.2002C3.36071 13.2002 3.64404 13.4919 3.64404 13.8335C3.64404 14.3585 3.95238 14.8419 4.43571 15.0585L10.094 17.5752C10.3607 17.6919 10.669 17.6919 10.944 17.5752L16.6024 15.0585C17.0857 14.8419 17.394 14.3669 17.394 13.8335C17.394 13.4919 17.6774 13.2085 18.019 13.2085C18.3607 13.2085 18.644 13.4919 18.644 13.8335C18.644 14.8585 18.044 15.7835 17.1107 16.2002L11.4524 18.7169C11.1524 18.8419 10.8357 18.9085 10.519 18.9085Z"
                                                                fill="#1D244A"
                                                              />
                                                            </svg>
                                                            <h3>
                                                              {item.MaterialInformation.reduce(
                                                                (
                                                                  total,
                                                                  material
                                                                ) =>
                                                                  total +
                                                                  (parseFloat(
                                                                    material.numberOfPallets
                                                                  ) || 0),
                                                                0
                                                              )}
                                                            </h3>
                                                          </span>
                                                        </div>
                                                        <div className="fixWith dividerbox">
                                                          <div className="divider"></div>
                                                        </div>
                                                      </React.Fragment>
                                                    )}
                                                    <div className="fixWith">
                                                      <h3
                                                        style={{
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        {isCategoryTanker
                                                          ? item.quantity
                                                          : // : `${item.MaterialInformation.reduce((total, material) => total + (parseFloat(material.sizeOfPallets) || 0), 0)},${item.MaterialInformation.reduce((total, material) => total + (parseFloat(material.totalWeightOfPallets) || 0), 0)} Kg`}
                                                          `${item.MaterialInformation.reduce(
                                                            (
                                                              total,
                                                              material
                                                            ) =>
                                                              total +
                                                              (parseFloat(
                                                                material.totalWeightOfPallets
                                                              ) || 0),
                                                            0
                                                          )} Kg`}
                                                      </h3>
                                                    </div>
                                                  </div>
                                                  {item.MaterialInformation.map(
                                                    (material_item, index) => {
                                                      const material_weight =
                                                        isCategoryTanker
                                                          ? material_item.quantity
                                                          : // : material_item.sizeOfPallets + ',' + material_item.totalWeightOfPallets;
                                                          material_item.totalWeightOfPallets;

                                                      const weight_type =
                                                        isCategoryTanker
                                                          ? "L"
                                                          : "Kg";

                                                      return (
                                                        <div
                                                          key={index}
                                                          className="matInfoBoxItems"
                                                        >
                                                          <h3 className="totalhead">
                                                            {
                                                              material_item.materialName
                                                            }
                                                          </h3>
                                                          {!isCategoryTanker && (
                                                            <React.Fragment>
                                                              <div className="fixWith">
                                                                <span>
                                                                  <svg
                                                                    width="21"
                                                                    height="21"
                                                                    viewBox="0 0 21 21"
                                                                    fill="none"
                                                                  >
                                                                    <path
                                                                      d="M10.344 10.4002C9.89396 10.4002 9.43563 10.3168 9.07729 10.1585L4.16063 7.97516C2.91063 7.41683 2.72729 6.66683 2.72729 6.2585C2.72729 5.85016 2.91063 5.10016 4.16063 4.54183L9.07729 2.3585C9.80229 2.0335 10.894 2.0335 11.619 2.3585L16.544 4.54183C17.7856 5.09183 17.9773 5.85016 17.9773 6.2585C17.9773 6.66683 17.794 7.41683 16.544 7.97516L11.619 10.1585C11.2523 10.3252 10.8023 10.4002 10.344 10.4002ZM10.344 3.36683C10.0606 3.36683 9.78563 3.4085 9.58563 3.50016L4.66896 5.6835C4.16063 5.91683 3.97729 6.15016 3.97729 6.2585C3.97729 6.36683 4.16063 6.6085 4.66063 6.8335L9.57729 9.01683C9.97729 9.19183 10.7023 9.19183 11.1023 9.01683L16.0273 6.8335C16.5356 6.6085 16.719 6.36683 16.719 6.2585C16.719 6.15016 16.5356 5.9085 16.0273 5.6835L11.1106 3.50016C10.9106 3.41683 10.6273 3.36683 10.344 3.36683Z"
                                                                      fill="#1D244A"
                                                                    />
                                                                    <path
                                                                      d="M10.519 14.7415C10.2024 14.7415 9.88571 14.6748 9.58571 14.5415L3.92738 12.0248C3.06904 11.6498 2.39404 10.6082 2.39404 9.6665C2.39404 9.32484 2.67738 9.0415 3.01904 9.0415C3.36071 9.0415 3.64404 9.32484 3.64404 9.6665C3.64404 10.1248 4.01904 10.6998 4.43571 10.8915L10.094 13.4082C10.3607 13.5248 10.669 13.5248 10.944 13.4082L16.6024 10.8915C17.019 10.7082 17.394 10.1248 17.394 9.6665C17.394 9.32484 17.6774 9.0415 18.019 9.0415C18.3607 9.0415 18.644 9.32484 18.644 9.6665C18.644 10.6082 17.969 11.6498 17.1107 12.0332L11.4524 14.5498C11.1524 14.6748 10.8357 14.7415 10.519 14.7415Z"
                                                                      fill="#1D244A"
                                                                    />
                                                                    <path
                                                                      d="M10.519 18.9085C10.2024 18.9085 9.88571 18.8419 9.58571 18.7085L3.92738 16.1919C2.99404 15.7752 2.39404 14.8502 2.39404 13.8252C2.39404 13.4835 2.67738 13.2002 3.01904 13.2002C3.36071 13.2002 3.64404 13.4919 3.64404 13.8335C3.64404 14.3585 3.95238 14.8419 4.43571 15.0585L10.094 17.5752C10.3607 17.6919 10.669 17.6919 10.944 17.5752L16.6024 15.0585C17.0857 14.8419 17.394 14.3669 17.394 13.8335C17.394 13.4919 17.6774 13.2085 18.019 13.2085C18.3607 13.2085 18.644 13.4919 18.644 13.8335C18.644 14.8585 18.044 15.7835 17.1107 16.2002L11.4524 18.7169C11.1524 18.8419 10.8357 18.9085 10.519 18.9085Z"
                                                                      fill="#1D244A"
                                                                    />
                                                                  </svg>
                                                                  <h3>
                                                                    {
                                                                      material_item.numberOfPallets
                                                                    }
                                                                  </h3>
                                                                </span>
                                                              </div>
                                                              <div className="fixWith dividerbox">
                                                                <div className="divider"></div>
                                                              </div>
                                                            </React.Fragment>
                                                          )}
                                                          <div className="fixWith">
                                                            <h3
                                                              style={{
                                                                whiteSpace:
                                                                  "nowrap",
                                                              }}
                                                            >
                                                              {material_weight}{" "}
                                                              {weight_type}
                                                            </h3>
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </Col>
                                            )}

                                            <Col lg={4} md={6}>
                                              <div className="outerBox">
                                                <BreadCrumbHeader
                                                  image={DocumentIcon}
                                                  title="DOCUMENTS"
                                                />
                                              </div>
                                              {isDocFound ? (
                                                <div className="pdflist">
                                                  <ul>
                                                    {POD_DOC && (
                                                      <li>
                                                        <div>
                                                          <Image
                                                            src={
                                                              DocumentTextIcon
                                                            }
                                                            alt=""
                                                          />
                                                          <span
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            POD.pdf
                                                          </span>
                                                        </div>
                                                        <Image
                                                          src={DownloadIcon}
                                                          alt=""
                                                          onClick={() =>
                                                            handleDownload(
                                                              POD_DOC
                                                            )
                                                          }
                                                        />
                                                      </li>
                                                    )}

                                                    {BL_DOC && (
                                                      <li>
                                                        <div>
                                                          <Image
                                                            src={
                                                              DocumentTextIcon
                                                            }
                                                            alt=""
                                                          />
                                                          <span
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            BL.pdf
                                                          </span>
                                                        </div>
                                                        <Image
                                                          src={DownloadIcon}
                                                          alt=""
                                                          onClick={() =>
                                                            handleDownload(
                                                              BL_DOC
                                                            )
                                                          }
                                                        />
                                                      </li>
                                                    )}
                                                    {COA_DOC && (
                                                      <li>
                                                        <div>
                                                          <Image
                                                            src={
                                                              DocumentTextIcon
                                                            }
                                                            alt=""
                                                          />
                                                          <span
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            COA.pdf
                                                          </span>
                                                        </div>
                                                        <Image
                                                          src={DownloadIcon}
                                                          alt=""
                                                          onClick={() =>
                                                            handleDownload(
                                                              COA_DOC
                                                            )
                                                          }
                                                        />
                                                      </li>
                                                    )}
                                                  </ul>
                                                </div>
                                              ) : (
                                                <div className="otf_box mb-2">
                                                  {" "}
                                                  <span className="otfFont">
                                                    No Document found
                                                  </span>
                                                </div>
                                              )}
                                              {role !== "Carrier" &&
                                                item.order_status ===
                                                "Delivered" && (
                                                  <>
                                                    {(role === "User" ||
                                                      role === "Client") && (
                                                        <div className="outerBox">
                                                          <BreadCrumbHeader
                                                            image={SmileysIcon}
                                                            title="CARRIER RATE"
                                                          />
                                                          {average_rating > 0 && (
                                                            <Badge>
                                                              {average_rating}
                                                            </Badge>
                                                          )}
                                                          <h3 className="rateH1">
                                                            Rate your carrier{" "}
                                                            <span>*</span> :
                                                          </h3>

                                                          <div className="smileyBox">
                                                            <Link
                                                              className={
                                                                (item
                                                                  .carrier_rating
                                                                  ?.rating
                                                                  ? item
                                                                    .carrier_rating
                                                                    .rating
                                                                  : selectedRating) ===
                                                                  1
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={() =>
                                                                handleImageClick(
                                                                  1
                                                                )
                                                              }
                                                            >
                                                              <Image
                                                                src={SmileysIcon1}
                                                                alt="Rating 1"
                                                              />
                                                            </Link>
                                                            <Link
                                                              className={
                                                                (item
                                                                  .carrier_rating
                                                                  ?.rating
                                                                  ? item
                                                                    .carrier_rating
                                                                    .rating
                                                                  : selectedRating) ===
                                                                  2
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={() =>
                                                                handleImageClick(
                                                                  2
                                                                )
                                                              }
                                                            >
                                                              <Image
                                                                src={SmileysIcon2}
                                                                alt="Rating 2"
                                                              />
                                                            </Link>
                                                            <Link
                                                              className={
                                                                (item
                                                                  .carrier_rating
                                                                  ?.rating
                                                                  ? item
                                                                    .carrier_rating
                                                                    .rating
                                                                  : selectedRating) ===
                                                                  3
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={() =>
                                                                handleImageClick(
                                                                  3
                                                                )
                                                              }
                                                            >
                                                              <Image
                                                                src={SmileysIcon3}
                                                                alt="Rating 3"
                                                              />
                                                            </Link>
                                                            <Link
                                                              className={
                                                                (item
                                                                  .carrier_rating
                                                                  ?.rating
                                                                  ? item
                                                                    .carrier_rating
                                                                    .rating
                                                                  : selectedRating) ===
                                                                  4
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={() =>
                                                                handleImageClick(
                                                                  4
                                                                )
                                                              }
                                                            >
                                                              <Image
                                                                src={SmileysIcon4}
                                                                alt="Rating 4"
                                                              />
                                                            </Link>
                                                            <Link
                                                              className={
                                                                (item
                                                                  .carrier_rating
                                                                  ?.rating
                                                                  ? item
                                                                    .carrier_rating
                                                                    .rating
                                                                  : selectedRating) ===
                                                                  5
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={() =>
                                                                handleImageClick(
                                                                  5
                                                                )
                                                              }
                                                            >
                                                              <Image
                                                                src={SmileysIcon5}
                                                                alt="Rating 5"
                                                              />
                                                            </Link>
                                                          </div>

                                                          <h3 className="rateH1">
                                                            Comment your choice
                                                          </h3>
                                                          <Form.Control
                                                            as="textarea"
                                                            value={comment}
                                                            onChange={(e) =>
                                                              setComment(
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                          <div className="mb-2"></div>
                                                          <React.Fragment>
                                                            {!isDisableRating &&
                                                              !isDisableRatingBtn && (
                                                                <div className="otf_box justify-content-center">
                                                                  <Button
                                                                    style={{
                                                                      width: 130,
                                                                    }}
                                                                    size="sm"
                                                                    onClick={() =>
                                                                      handleRating(
                                                                        quotationId,
                                                                        item.approved_carrier_id,
                                                                        item.userId
                                                                      )
                                                                    }
                                                                    disabled={
                                                                      isDisableRatingBtn
                                                                    }
                                                                  >
                                                                    {isAddRatingLoading ? (
                                                                      <Loader
                                                                        size={30}
                                                                      />
                                                                    ) : (
                                                                      "Save ratings"
                                                                    )}
                                                                  </Button>
                                                                </div>
                                                              )}
                                                          </React.Fragment>
                                                        </div>
                                                      )}



                                                    {role === "Staff" && (
                                                      <div className="outerBox">
                                                        <BreadCrumbHeader
                                                          image={SmileysIcon}
                                                          title="CARRIER RATE"
                                                        />
                                                        <Badge>
                                                          {average_rating}
                                                        </Badge>

                                                        <div className="otf_box mb-2">
                                                          <span className="otfFont">
                                                            OTIF
                                                          </span>
                                                          <span>
                                                            <Form.Control
                                                              type="text"
                                                              placeholder="0"
                                                              className="me-1"
                                                              value={
                                                                otif > 0
                                                                  ? otif
                                                                  : ""
                                                              }
                                                              disabled={
                                                                isDisableRating
                                                              }
                                                              onChange={(e) => {
                                                                const inputValue =
                                                                  e.target
                                                                    .value;
                                                                if (
                                                                  /^\d+$/.test(
                                                                    inputValue
                                                                  ) &&
                                                                  inputValue >=
                                                                  0 &&
                                                                  inputValue <=
                                                                  5
                                                                ) {
                                                                  setOtif(
                                                                    inputValue
                                                                  );
                                                                } else if (
                                                                  inputValue ===
                                                                  ""
                                                                ) {
                                                                  setOtif("");
                                                                }
                                                              }}
                                                            />
                                                            {"  "}/ 5
                                                          </span>
                                                        </div>

                                                        <div className="otf_box mb-2">
                                                          <span className="otfFont">
                                                            Platform obligation
                                                          </span>
                                                          <span>
                                                            {item.Platfrom ? (
                                                              item.Platfrom
                                                            ) : (
                                                              <Form.Control
                                                                type="text"
                                                                placeholder="0"
                                                                className="me-1"
                                                                value={
                                                                  platform > 0
                                                                    ? platform
                                                                    : ""
                                                                }
                                                                disabled={
                                                                  isDisableRating
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const inputValue =
                                                                    e.target
                                                                      .value;
                                                                  if (
                                                                    /^\d+$/.test(
                                                                      inputValue
                                                                    ) &&
                                                                    inputValue >=
                                                                    0 &&
                                                                    inputValue <=
                                                                    5
                                                                  ) {
                                                                    setPlatform(
                                                                      inputValue
                                                                    );
                                                                  } else if (
                                                                    inputValue ===
                                                                    ""
                                                                  ) {
                                                                    setPlatform(
                                                                      ""
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                            )}
                                                            {"  "}/ 5
                                                          </span>
                                                        </div>

                                                        <div className="otf_box mb-2">
                                                          <span className="otfFont">
                                                            Correction
                                                          </span>
                                                          <span>
                                                            {item.correction ? (
                                                              item.correction
                                                            ) : (
                                                              <Form.Control
                                                                type="text"
                                                                placeholder="0"
                                                                className="me-1"
                                                                disabled={
                                                                  isDisableRating
                                                                }
                                                                value={
                                                                  correction > 0
                                                                    ? correction
                                                                    : ""
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const inputValue =
                                                                    e.target
                                                                      .value;
                                                                  if (
                                                                    /^\d+$/.test(
                                                                      inputValue
                                                                    ) &&
                                                                    inputValue >=
                                                                    0 &&
                                                                    inputValue <=
                                                                    5
                                                                  ) {
                                                                    setCorrection(
                                                                      inputValue
                                                                    );
                                                                  } else if (
                                                                    inputValue ===
                                                                    ""
                                                                  ) {
                                                                    setCorrection(
                                                                      ""
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                            )}
                                                            {"  "}/ 5
                                                          </span>
                                                        </div>


                                                        {item.OTIF &&
                                                          item.Platfrom &&
                                                          item.correction ? (
                                                          ""
                                                        ) : (
                                                          <React.Fragment>
                                                            {!isDisableRating &&
                                                              !isDisableRatingBtn && (
                                                                <div className="otf_box justify-content-center">
                                                                  <Button
                                                                    style={{
                                                                      width: 130,
                                                                    }}
                                                                    size="sm"
                                                                    onClick={() =>
                                                                      handleRating(
                                                                        quotationId,
                                                                        item.approved_carrier_id,
                                                                        item.userId
                                                                      )
                                                                    }
                                                                    disabled={
                                                                      isDisableRatingBtn
                                                                    }
                                                                  >
                                                                    {isAddRatingLoading ? (
                                                                      <Loader
                                                                        size={
                                                                          30
                                                                        }
                                                                      />
                                                                    ) : (
                                                                      "Save ratings"
                                                                    )}
                                                                  </Button>
                                                                </div>
                                                              )}
                                                          </React.Fragment>
                                                        )}

                                                      </div>
                                                    )}
                                                  </>
                                                )}
                                            </Col>
                                          </Row>
                                        </Container>
                                      </div>
                                    </td>
                                  </tr>
                                </Fade>
                              </>
                            }
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>

              {/* pagination */}
              <div className="pagination-container">
                <div className="paginationFeature">
                  <p>Rows per page: {list_row_limit}</p>
                </div>
                <div className="pagination">
                  <p>
                    {List_data.from} - {List_data.to} of {List_data.total_docs}
                  </p>
                  <ReactPaginate
                    activePage={currentPage}
                    itemsCountPerPage={list_row_limit}
                    totalItemsCount={List_data.total_docs}
                    pageRangeDisplayed={list_row_limit}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination"
                  />
                </div>
              </div>

              <ToastContainer />
            </Container>
          )}
        </div>
      </div>
      <Modal centered show={show_order_modal}>
        <Modal.Body className="p-3">
          <div className="deleteModal text-center">
            {/* <XCircle className="deletemodal_icon" /> */}
            <h2>Are You Sure ?</h2>
            <p>You will not be able to recover the deleted record!</p>
            <div className="btn-cancel-ok">
              <Button
                className="btn-cancel me-1"
                onClick={() => {
                  setShowOrderModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ width: 95 }}
                className="btn-ok"
                onClick={() => {
                  delete_order_fun();
                }}
                disabled={deleteOrderLoading}
              >
                {deleteOrderLoading ? <Loader /> : "OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Orders;
