import React, { useRef, useState } from "react";
import { Col, Container, Row, Button, Image } from "react-bootstrap";
import { BiCheckCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import TruckGif from "../images/truck.gif";
import stats1 from "../images/stats_daily.svg";
import stats2 from "../images/stats_carriers.svg";
import stats3 from "../images/stats_clients.svg";
import stats4 from "../images/stats_Countries.svg";
import transportimg1 from "../images/transportimg1.jpg";
import transportimg2 from "../images/transportimg2.jpg";
import transportimg3 from "../images/transportimg3.jpg";
import Google from "../images/Google.svg";
import facebook from "../images/facebook.svg";
import YouTube from "../images/YouTube.svg";
import Pinterest from "../images/Pinterest.svg";
import Twitch from "../images/Twitch.svg";
import Webflow from "../images/Webflow.svg";
import howitworkimg from "../images/howitwork_img.jpg";
import contactimg from "../images/img1.jpg";
import { FiArrowRight } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import video from "../images/video/mottruck.mp4";
import { IoVolumeMute } from "react-icons/io5";
import { IoMdVolumeMute } from "react-icons/io";

const Home = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  return (
    <React.Fragment>
      <section className="bannerSection">
        <Container>
          <Row>
            <Col md={12} lg={12}>
              <div className="bannerCenter">
                <div
                  className="videoWidth"
                  style={{ position: "relative", display: "inline-block" }}
                >
                  <video
                    ref={videoRef}
                    src={video}
                    autoPlay
                    muted
                    height="500"
                    style={{ width: "100%" }}
                  />
                  <a
                    href="javascript:void(0)"
                    onClick={handleMuteToggle}
                    className="muteBtns"
                  >
                    {isMuted ? <IoMdVolumeMute /> : <IoVolumeMute />}
                  </a>
                </div>

                <h1>
                  The ultimate <span>matchmaking solution</span> for shippers
                  and carriers.
                </h1>

                <Button
                  as={Link}
                  to="/usersignup"
                  variant="primary"
                  className="me-2 btn-primaryAll btnPrimary"
                >
                  Start a quote <FiArrowRight />
                </Button>

                <Button
                  as={Link}
                  to="/solutions"
                  variant="primary"
                  className="me-2 btn-primaryAll btnLight_outline"
                >
                  Our solutions
                </Button>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md={7} lg={5}>
              <h1>
                The ultimate <span>matchmaking solution</span> for shippers and
                carriers.
              </h1>
              <ul>
                <li>
                  <BiCheckCircle /> 3 types of transport: cargo, tanker and
                  refrigerated.
                </li>
                <li>
                  <BiCheckCircle /> Get quotes and choose your carriers.
                </li>
                <li>
                  <BiCheckCircle /> Trust us with setting up your shipments.
                </li>
              </ul>
              <Button
                as={Link}
                to="/usersignup"
                variant="primary"
                className="me-2 btn-primaryAll btnPrimary"
              >
                Start a quote <FiArrowRight />
              </Button>

              <Button
                as={Link}
                to="/solutions"
                variant="primary"
                className="me-2 btn-primaryAll btnLight_outline"
              >
                Our solutions
              </Button>
            </Col>
          </Row> */}
          {/* <div className="gifAbs">
            <Image src={TruckGif} alt="" />
          </div> */}
        </Container>
      </section>
      <section className="statscounter_Div">
        <Container>
          <Row>
            <Col sm={6} md={6} lg={3}>
              <div className="statscounter_main">
                <span className="stats_icon">
                  <Image src={stats1} alt="" />
                </span>
                <h4>
                  115{" "}
                  <sub>
                    Daily <br />
                    delivery
                  </sub>
                </h4>
              </div>
            </Col>
            <Col sm={6} md={6} lg={3}>
              <div className="statscounter_main">
                <span className="stats_icon">
                  <Image src={stats2} alt="" />
                </span>
                <h4>
                  372{" "}
                  <sub>
                    Qualified <br />
                    carriers
                  </sub>
                </h4>
              </div>
            </Col>
            <Col sm={6} md={6} lg={3}>
              <div className="statscounter_main">
                <span className="stats_icon">
                  <Image src={stats3} alt="" />
                </span>
                <h4>
                  96%{" "}
                  <sub>
                    Satisfied <br />
                    clients
                  </sub>
                </h4>
              </div>
            </Col>
            <Col sm={6} md={6} lg={3}>
              <div className="statscounter_main">
                <span className="stats_icon">
                  <Image src={stats4} alt="" />
                </span>
                <h4>
                  27{" "}
                  <sub>
                    Countries <br />
                    served
                  </sub>
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="transportmain_div">
        <Container>
          <Row>
            <Col md={12}>
              <div className="transport_head">
                <h1>Transport via 3 channels</h1>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className="transport_box">
                <Image src={transportimg1} alt="" />
                <h4>Cargo</h4>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className="transport_box">
                <Image src={transportimg2} alt="" />
                <h4>Tanker</h4>
              </div>
            </Col>
            <Col sm={6} md={4}>
              <div className="transport_box">
                <Image src={transportimg3} alt="" />
                <h4>Refrigerated</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="partner_div">
        <Container>
          <Row>
            <Col md={12}>
              <div className="partner_head">
                <h1>They trust us</h1>
              </div>
            </Col>
            <Col md={12}>
              <Swiper
                slidesPerView={6}
                spaceBetween={10}
                breakpoints={{
                  300: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 6,
                    spaceBetween: 50,
                  },
                }}
                className="patnerswiper"
              >
                <SwiperSlide>
                  <div className="partner_logo">
                    <Image src={Google} alt="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="partner_logo">
                    <Image src={facebook} alt="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="partner_logo">
                    <Image src={YouTube} alt="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="partner_logo">
                    <Image src={Pinterest} alt="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="partner_logo">
                    <Image src={Twitch} alt="" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="partner_logo">
                    <Image src={Webflow} alt="" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section> */}
      <section className="howitworks_div">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="howitworks_head">
                <h1>How it works ?</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 5, offset: 1 }} md={6}>
              <div className="howitworks_content">
                <div className="howitwork_steps">
                  <span className="steps_count">1</span>
                  <h4>
                    <span>Create</span> your request or <span>entrust it</span>{" "}
                    to our team
                  </h4>
                  <p>
                    Choose between cargo, tanker or refrigerated. Enter all the
                    parameters of your order and manage it yourself, or let our
                    team handle it for you.
                  </p>
                </div>
                <div className="howitwork_steps">
                  <span className="steps_count">2</span>
                  <h4>
                    <span>Receive</span> quotes and <span>select</span> your
                    carrier
                  </h4>
                  <p>
                    From your account, see the proposals of the carriers to whom
                    you have sent a request. Accept the price that suits you
                    best.
                  </p>
                </div>
                <div className="howitwork_steps">
                  <span className="steps_count">3</span>
                  <h4>
                    <span>Track</span> your order in real time
                  </h4>
                  <p>
                    Keep an eye on all events on the route and any possible
                    changes. <br />
                    Access delivery documents.
                  </p>
                </div>
                <Button
                  as={Link}
                  to="/solutions"
                  variant="primary"
                  className="mt-3 btn-primaryAll btnPrimary"
                >
                  Our solutions <FiArrowRight />
                </Button>
              </div>
            </Col>
            <Col lg={{ span: 5, offset: 1 }} md={6}>
              <div className="howitworks_img">
                <Image src={howitworkimg} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homegetstarted_div">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="homegetstarted_img">
                <Image src={contactimg} alt="" />
              </div>
            </Col>
            <Col md={6}>
              <div className="homegetstarted_content">
                <h4>
                  Create your account today and <span>get started</span> !
                </h4>
                <p>
                  At Mottruck, we're more than just a trucking logistics
                  company. We're your dedicated partner, ready to simplify your
                  logistics needs, whether you're a customer looking to ship
                  goods, or a carrier looking to join our trusted network.
                </p>
                <Button
                  as={Link}
                  to="/usersignup"
                  variant="primary"
                  className="me-2 btn-primaryAll btnPrimary"
                >
                  Get started
                </Button>
                <Button
                  as={Link}
                  to="/contact"
                  variant="primary"
                  className="me-2 btn-primaryAll btnLight_outline"
                >
                  Contact us
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Home;
