import React, { useState, memo, useCallback, useMemo, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  DirectionsService,
  Polyline,
} from "@react-google-maps/api";

import trackicon1 from "../../component/images/starting_mark.svg";
import trackicon2 from "../../component/images/current_location_marker.svg";
import trackicon3 from "../../component/images/delivery_marker.svg";
import Loader from "./Loader";

const center = {
  lat: 0,
  lng: -180,
};
function MapComponent({ deliveryStatus, location_details, mapContainerStyle }) {
  const [loading, setLoading] = useState(true);
  const [routeOne, setRouteOne] = useState(null);
  const [routeTwo, setRouteTwo] = useState(null);
  const [state, setState] = useState({
    color1: '#219653',
    color2: '#AAA'
  })
  const handleState = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };



  useEffect(() => {
    if (deliveryStatus == "delivered") {
      setState((prev) => ({ ...prev, color1: '#219653', color2: '#219653' }));
    } else if (deliveryStatus == 'on route') {
      setState((prev) => ({ ...prev, color1: '#219653', color2: '#AAA' }));
    } else {
      setState((prev) => ({ ...prev, color1: '#AAA', color2: '#AAA' }));
    }
  }, [deliveryStatus])

  const { loading_point, transit_point, delivery_point } = location_details;
  // console.log(loading_point ,transit_point ,delivery_point , 'CHECK T HE ALL  THREE')

  const directionsServiceOptionsOne = useMemo(
    () => ({
      // 31.14439, 76.09019]
      origin: `${loading_point.lat},${loading_point.lng}`,
      destination:
        transit_point.lat && transit_point.lng
          ? `${transit_point.lat},${transit_point.lng}`
          : `${delivery_point.lat},${delivery_point.lng}`,
      travelMode: "DRIVING",
    }),
    [loading_point, transit_point]
  );

  const directionsServiceOptionsTwo = useMemo(
    () => ({
      origin: `${transit_point.lat},${transit_point.lng}`,
      destination: `${delivery_point.lat},${delivery_point.lng}`,
      travelMode: "DRIVING",
    }),
    [transit_point, delivery_point]
  );

  const directionsCallbackOne = useCallback((result, status) => {
    console.log(result, 'COMMING RESULT  ---')
    if (status === "OK") {
      setRouteOne(result.routes[0].overview_path);
    } else {
      console.log("Route 1 response: ", result);
    }
  }, []);

  const directionsCallbackTwo = useCallback((result, status) => {
    if (status === "OK") {
      setRouteTwo(result.routes[0].overview_path);
    } else {
      console.log("Route 2 response: ", result);
    }
  }, []);

  const libraries = ["places"];
  const { isLoaded, loadError } = useLoadScript({
    // googleMapsApiKey: "AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I",
    googleMapsApiKey: `AIzaSyBHQWCrTWJIMYz04ZN43VyZ9xv6agXnuyk`,// -- mine one
    libraries,
  });



  useEffect(() => {
    if (isLoaded) {
      setLoading(false);
    } else if (loadError) {
      setLoading(true);
    } else {
      setLoading(true);
    }
  }, [isLoaded, loadError]);

  if (!isLoaded || loadError) return null;
  { console.log(deliveryStatus, 'CHECKTH LAUCH STATUS ---0000') }
  return (
    <React.Fragment>
      <GoogleMap
        mapContainerClassName="mapContainer"
        id="direction-example"
        mapContainerStyle={mapContainerStyle}
        zoom={6}
        center={loading_point || center}
      >
        {loading && (
          <div className="map_loader_container">
            <Loader size={80} color={"#F7CE6C"} />
          </div>
        )}
        <DirectionsService
          options={directionsServiceOptionsOne}
          callback={directionsCallbackOne}
        />
        <DirectionsService
          options={directionsServiceOptionsTwo}
          callback={directionsCallbackTwo}
        />
        {routeOne && (
          <Polyline
            path={routeOne}
            options={{
              strokeColor: state.color1,
              strokeOpacity: 1,
              strokeWeight: 4,
            }}
          />
        )}
        {routeTwo && (
          <Polyline
            path={routeTwo}
            options={{
              strokeColor: state.color2,
              strokeOpacity: 2,
              strokeWeight: 4,
            }}
          />
        )}
        {loading_point && (
          <Marker
            key={0}
            title="Loading point"
            position={{
              lat: Number(loading_point.lat),
              lng: Number(loading_point.lng),
            }}
            icon={{
              url: trackicon1,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
        )}

        {transit_point && (
          <Marker
            title="Transit point"
            key={2}
            position={{
              lat: Number(transit_point.lat),
              lng: Number(transit_point.lng),
            }}
            icon={{
              url: trackicon2,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
        )}

        {delivery_point && (
          <Marker
            key={1}
            title="Delivery point"
            position={{
              lat: Number(delivery_point.lat),
              lng: Number(delivery_point.lng),
            }}
            icon={trackicon3}
          />
        )}
      </GoogleMap>
    </React.Fragment>
  );
}

export default memo(MapComponent);

