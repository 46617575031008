import { useState, useEffect, useContext } from "react";
import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import {
  Col,
  Row,
  Button,
  Table,
  Image,
  Modal,
  Container,
  Badge,
  Dropdown,
  ButtonGroup,
  DropdownButton,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiPlusCircle } from "react-icons/fi";
import UserInfoIcon from "../images/userinfo.svg";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import FilterSidebar from "../inc/FilterSidebar";
import FilterIcon from "../images/filter.svg";
import RowsImg from "../images/cchart.svg";
import { GoDotFill } from "react-icons/go";
import EditIcon from "../images/edit.svg";
import TrashIcon from "../images/trash.svg";
import BlockIcon from "../images/blockicon.svg";
import SendEmailIcon from "../images/sendemail.svg";
import { FiMoreVertical } from "react-icons/fi";
import * as SERVICE from "../services/index";
import { ToastContainer, toast } from "react-toastify";
import {
  add_Client,
  client_Block,
  client_Delete,
  client_EditDetail,
  client_List,
  client_SendMail,
  country_PhoneNumberCode,
  particular_Client,
  staff_ClientList,
} from "../services/ApiCalls";
import moment from "moment";
import EditFieldTextInput from "../customcomponents/EditFieldTextInput";
import LoadingContext from "../services/LoadingContext";
import { URL_LINK } from "../services/env";
import { FiMenu } from "react-icons/fi";
import SearchIcon from "../images/searchicon.svg";
import NotificationIcon from "../images/notification.svg";
import ReactPaginate from "react-js-pagination";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { FaLocationDot } from "react-icons/fa6";
import { AppStoreContext } from "../../store/AppStore";
import Loader from "../../common/component/Loader";

var selectedItem = {};

const Client = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { userDetail } = useContext(AppStoreContext);
  const [modalShow, setModalShow] = useState(false);
  const [eidtModal, setEditModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const userDeatildata = userDetail;
  const { _id, email, profile } = userDeatildata;

  const userRole = profile;
  const isRoleAdmin = userRole == "Admin";

  const [getClinetlist, setGetClinetlist] = useState([]);
  const [showItem, setShowItem] = useState(false);
  // const [selectedItem, setSelectedItem] = useState({});
  const [editDetail, setEditDetail] = useState({});

  const [isBodyClassActive, setIsBodyClassActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedClientList = getClinetlist.slice(startIndex, endIndex);

  const [show_order_modal, setShowOrderModal] = useState(false);
  const [deleteOrderLoading, setDeleteOrderLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null); // Store the item ID


  const calculateCount = (index) => {
    return (currentPage - 1) * itemsPerPage + index + 1;
  };

  const handlePageChange = (page) => {
    setShowItem(false);
    setCurrentPage(page);
  };

  const handleSearch = () => {
    const filteredResults = getClinetlist.filter(
      (result) =>
        result.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.accountNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setGetClinetlist(filteredResults);
    setShowItem(false);
  };

  const toggleBodyClass = () => {
    setIsBodyClassActive(!isBodyClassActive);
  };

  useEffect(() => {
    if (isRoleAdmin) {
      if (searchQuery === "") {
        fetchClientList();
      }
    } else {
      if (searchQuery === "") {
        fetchStaffClientList();
      }
    }
  }, [searchQuery]);

  let count = 0;

  const handleItemShow = (item) => {
    // setSelectedItem(item);
    // console.log("item+++++++++++++++++++++>>>>>>>>>>>>+++++++++++",item)

    const payLoad = {
      particularClient: item._id,
    };
    particular_Client(payLoad)
      .then((res) => {
        if (res.status === true) {
          console.log("res--client-", res.result.clientdetail);
          selectedItem = res.result.clientdetail;
          setEditDetail(res.result.clientdetail);
          setShowItem(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (toggle) {
      document.body.classList.add("filter_sidebar");
    } else {
      document.body.classList.remove("filter_sidebar");
    }
  }, [toggle]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    if (isRoleAdmin) {
      fetchClientList();
      // setShowItem(false);
    } else {
      fetchStaffClientList();
    }
  }, [eidtModal, modalShow]);

  // Admint Client list
  const fetchClientList = () => {
    const payload = {
      adminId: _id,
    };
    setIsLoading(true);
    client_List(payload)
      .then((res) => {
        console.log("client res", res.result.clientList);
        setGetClinetlist(res.result.clientList);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  // Staff Client List
  const fetchStaffClientList = () => {
    const payload = {};
    setIsLoading(true);
    staff_ClientList(payload)
      .then((res) => {
        console.log("client res", res.result.clientList);
        setGetClinetlist(res.result.clientList);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  // send Email
  const handleSendEmailClick = (ownerName, staffEmail, id) => {
    const emailAddress = staffEmail;
    const subject = "";
    const body = "";
    window.location.href = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };
  // const handleSendEmailClick = (ownerName, clientEmail, id) => {
  //   const payload = {
  //     id: id,
  //     contactEmail: clientEmail,
  //     accountOwnerFirstName: ownerName,
  //     link: `${URL_LINK}example`,
  //   };
  //   console.log("payload", payload);
  //   setIsLoading(true);
  //   client_SendMail(payload)
  //     .then((res) => {
  //       console.log("res", res);
  //       if (res.status === true) {
  //         toast("Email sent successfully.");
  //       } else toast(res.msg);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       setIsLoading(false);
  //     });
  // };
  // edit
  const handleEditClick = (item) => {
    setEditDetail(item);
    setEditModal(true);
  };
  // Block API
  const handleBlockClick = (id, check) => {
    const payload = {
      type: check === "Active" ? "Inactive" : "Active",
      addid: id,
    };
    console.log("payload block", payload);
    setIsLoading(true);
    client_Block(payload)
      .then((res) => {
        console.log("block res", res);
        if (res.status === true) {
          check === "Active"
            ? toast("Block Successfully")
            : toast("Unblock Successfully");
          setShowItem(false);
          fetchClientList();
        } else toast(res.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  const handleDeleteClick = (id) => {
    setDeleteItemId(id); // Set the item ID to be deleted
    setShowOrderModal(true);

  };

  const confirmDelete = () => {
    setDeleteOrderLoading(true);
    const payload = {
      deleteStatus: "1",
      addid: deleteItemId,
    };

    setIsLoading(true);
    client_Delete(payload)
      .then((res) => {
        console.log("Delete response:", res);
        if (res.status === true) {
          toast("Deleted Successfully");
          fetchClientList(); // Refresh the list
        } else {
          toast(res.msg);
        }
        setShowOrderModal(false); // Hide the modal
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error:", err);
        setIsLoading(false);
      });
  };

  // Delete
  // const handleDeleteClick = (id) => {
  //   if (window.confirm("Are you sure you want to delete this client?")) {
  //     const payload = {
  //       deleteStatus: "1",
  //       addid: id,
  //     };
  //     setIsLoading(true);
  //     client_Delete(payload)
  //       .then((res) => {
  //         console.log(" delete res", res);
  //         if (res.status === true) {
  //           toast("Delete Successfully");
  //           setShowItem(false);
  //           fetchClientList();
  //         } else toast(res.msg);
  //         setIsLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //         setIsLoading(false);
  //       });
  //   } else {
  //     console.log("cancel client");
  //   }
  // };

  

  return (
    <>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          {/* <NavBar /> */}

          <div className="topheader">
            <div className="container-fluid">
              <div className="topheader_flex">
                <Button
                  variant="primary"
                  className="menuBtn"
                  onClick={toggleBodyClass}
                >
                  <FiMenu />
                </Button>
                <div className="inPutBox">
                  <Form.Control
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(text) => setSearchQuery(text.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  <Image src={SearchIcon} alt="" />
                </div>
                <Link className="notiIcon">
                  <Image src={NotificationIcon} alt="" />
                  <Badge bg="danger">5</Badge>
                </Link>
              </div>
            </div>
          </div>

          <FilterSidebar close={handleToggle} />

          <Container fluid className="pt-100">
            <div className="quotes_div">
              <Row className="align-items-center mb-3">
                {isRoleAdmin && (
                  <Col lg={6} xs={6}>
                    <Button
                      onClick={() => setModalShow(true)}
                      variant="primary"
                      className="btn-primaryAll btnPrimary"
                    >
                      <FiPlusCircle /> Add client
                    </Button>
                  </Col>
                )}

                {/* <Col lg={6} xs={6} className="text-end">
                  <Link onClick={handleToggle} className="filter_btn">
                    <Image src={FilterIcon} width={20} alt="" /> Filters
                  </Link>
                </Col> */}
              </Row>

              <Row>
                <Col lg={5}>
                  <div className="react_table react_table1 react_tableLast">
                    <Table cellSpacing={10}>
                      <thead>
                        <tr>
                          <th></th>
                          <th>NAME</th>
                          <th>ACCOUNT</th>
                          <th></th>
                        </tr>
                      </thead>
                      {getClinetlist.length === 0 && (
                        <tr>
                          <td colSpan={9} className="text-center  pt-3 ">
                            No data found
                          </td>
                        </tr>
                      )}
                      <tbody>
                        {displayedClientList.map((item, index) => {
                          // console.log("item", item, index)
                          return (
                            <tr
                              key={index}
                              onClick={() => handleItemShow(item)}
                              style={{ cursor: "pointer" }}
                            >
                              {item.deleteStatus === "0" && (
                                <>
                                  <td>
                                    <span className="padd">
                                      {calculateCount(index)}
                                    </span>
                                  </td>
                                  <td style={{ maxWidth: 170, width: 170 }}>
                                    <span
                                      style={{
                                        maxWidth: 170,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                      className="bgSeconday bgSecondayr"
                                    >
                                      {item.companyName}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="bgSeconday bgSecondayl">
                                      {item.accountNumber}
                                    </span>
                                  </td>
                                  <td>
                                    {isRoleAdmin ? (
                                      <div className="mb-2">
                                        {["start"].map((direction) => (
                                          <DropdownButton
                                            as={ButtonGroup}
                                            key={direction}
                                            id={`dropdown-button-drop-${direction}`}
                                            drop={direction}
                                            className="tabledrop_btn"
                                            title={<FiMoreVertical />}
                                          >
                                            <Dropdown.Item
                                              eventKey="1"
                                              className="menudrop_link"
                                              onClick={() =>
                                                handleSendEmailClick(
                                                  item.accountOwnerFirstName,
                                                  item.contactEmail,
                                                  item._id
                                                )
                                              }
                                            >
                                              <Image
                                                className="editimg"
                                                src={SendEmailIcon}
                                                alt=""
                                              />{" "}
                                              Send email
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              eventKey="2"
                                              className="menudrop_link"
                                              onClick={() =>
                                                handleEditClick(item)
                                              }
                                            >
                                              <Image
                                                className="editimg"
                                                src={EditIcon}
                                                alt=""
                                              />{" "}
                                              Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              eventKey="3"
                                              className="menucancel_link"
                                              onClick={() =>
                                                handleBlockClick(
                                                  item._id,
                                                  item.status
                                                )
                                              }
                                            >
                                              <Image
                                                className="editimgt"
                                                src={BlockIcon}
                                                alt=""
                                              />{" "}
                                              {item.status === "Active"
                                                ? "Block"
                                                : "Unblock"}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              eventKey="4"
                                              className="menucancel_link"
                                              onClick={() =>
                                                handleDeleteClick(item._id)
                                              }
                                            >
                                              <Image
                                                className="editimgt"
                                                src={TrashIcon}
                                                alt=""
                                              />{" "}
                                              Delete
                                            </Dropdown.Item>
                                          </DropdownButton>
                                        ))}
                                         <Modal centered show={show_order_modal}>
                                        <Modal.Body className="p-3">
                                          <div className="deleteModal text-center">
                                            {/* <XCircle className="deletemodal_icon" /> */}
                                            <h2>Are You Sure ?</h2>
                                            <p>You will not be able to recover the deleted record!</p>
                                            <div className="btn-cancel-ok">
                                              <Button
                                                className="btn-cancel me-1"
                                                onClick={() => {
                                                  setShowOrderModal(false);
                                                }}
                                              >
                                                Cancel
                                              </Button>
                                              <Button
                                                style={{ width: 95 }}
                                                className="btn-ok"
                                                onClick={() => {
                                                  confirmDelete();
                                                }}
                                                disabled={deleteOrderLoading}
                                              >
                                                {deleteOrderLoading ? <Loader /> : "OK"}
                                              </Button>
                                            </div>
                                          </div>
                                        </Modal.Body>
                                      </Modal>
                                      </div>
                                    ) : (
                                      <div className="mb-2">
                                        {["start"].map((direction) => (
                                          <DropdownButton
                                            as={ButtonGroup}
                                            key={direction}
                                            id={`dropdown-button-drop-${direction}`}
                                            drop={direction}
                                            className="tabledrop_btn"
                                            title={<FiMoreVertical />}
                                          >
                                            <Dropdown.Item
                                              eventKey="1"
                                              className="menudrop_link"
                                              onClick={() =>
                                                handleSendEmailClick(
                                                  item.accountOwnerFirstName,
                                                  item.contactEmail,
                                                  item._id
                                                )
                                              }
                                            >
                                              <Image
                                                className="editimg"
                                                src={SendEmailIcon}
                                                alt=""
                                              />{" "}
                                              Send email
                                            </Dropdown.Item>
                                          </DropdownButton>
                                        ))}
                                      </div>
                                    )}
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  {/* <Image src={RowsImg} alt="" className="mt-2 img-fluid" /> */}
                </Col>
                {showItem === true && (
                  <Col lg={7}>
                    <div className="react_table carrier_Details">
                      <div className="common_bg bg_Color">
                        <div className="headFlex">
                          <h2>{selectedItem.companyName}</h2>
                          {isRoleAdmin && (
                            <Link onClick={() => handleEditClick(selectedItem)}>
                              <Image
                                className="editimg"
                                src={EditIcon}
                                alt=""
                              />{" "}
                              Modify
                            </Link>
                          )}
                        </div>
                        <div className="wfix">
                          {selectedItem.status === "Inactive" ? (
                            <Badge bg="danger" className="badgeC">
                              <GoDotFill size={15} /> Inactive
                            </Badge>
                          ) : (
                            <Badge bg="success" className="badgeC">
                              <GoDotFill size={15} /> Active
                            </Badge>
                          )}
                        </div>
                      </div>
                      {/* <div className="common_bg common_bg_pad">
      <h3>Company</h3>
      <div className="wfix">
        <h3>Carrefour</h3>
      </div>
    </div> */}
                      <div className="common_bg common_bg_pad ">
                        <h3>Account number</h3>
                        <div className="wfix">
                          <h3>{selectedItem.accountNumber}</h3>
                        </div>
                      </div>
                      <div className="common_bg common_bg_pad bg_Color">
                        <div className="headFlex headFlex_h2">
                          <h2>Address</h2>
                        </div>
                        <div className="wfix">
                          <h3>{selectedItem.address}</h3>
                        </div>
                      </div>
                      <div className="common_bg common_bg_pad ">
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <h3>Phone</h3>
                            <div className="wfix">
                              <h3>
                                {selectedItem.contactCode}{" "}
                                {selectedItem.contactPhone}
                              </h3>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h3>Email</h3>
                            <div className="wfix">
                              <h3>{selectedItem.contactEmail}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isRoleAdmin && (
                        <div className="common_bg common_bg_pad bg_Color">
                          <div className="w-100">
                            <div className="d-flex justify-content-between">
                              <div className="headFlex headFlex_h2">
                                <h2>Account owner name</h2>
                              </div>
                              <div className="wfix">
                                <h3>
                                  {selectedItem.accountOwnerFirstName}{" "}
                                  {selectedItem.accountOwnerLastName}
                                </h3>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="headFlex headFlex_h2">
                                <h2>Account owner phone</h2>
                              </div>
                              <div className="wfix">
                                <h3>
                                  {selectedItem.accountOwnerCode}{" "}
                                  {selectedItem.accountOwnerPhoneNumber}
                                </h3>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="headFlex headFlex_h2">
                                <h2>Account owner email</h2>
                              </div>
                              <div className="wfix">
                                <h3>{selectedItem.accountOwnerEmail}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {isRoleAdmin && (
                        <div className="common_bg common_bg_pad">
                          <h3>VAT number</h3>
                          <div className="wfix">
                            <h3>{selectedItem.VATNumber}</h3>
                          </div>
                        </div>
                      )}

                      {isRoleAdmin && (
                        <div className="common_bg common_bg_pad bg_Color">
                          <div className="headFlex headFlex_h2">
                            <h2>DUNS number</h2>
                          </div>
                          <div className="wfix">
                            <h3>{selectedItem.DUNS}</h3>
                          </div>
                        </div>
                      )}

                      {isRoleAdmin && (
                        <div className="common_bg common_bg_pad">
                          <div className="w-100">
                            <div className="d-flex justify-content-between">
                              <h3>IBAN</h3>
                              <div className="wfix">
                                <h3>{selectedItem.IBAN}</h3>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <h3>BIC</h3>
                              <div className="wfix">
                                <h3>{selectedItem.BIC}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="common_bg common_bg_pad bg_Color">
                        <h3>Creation</h3>
                        <div className="wfix">
                          <h3>
                            {" "}
                            {moment(selectedItem.updatedAt).format(
                              "DD.MM.YYYY"
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </div>

            {/* Pagination controls */}
            <div className="pagination-container">
              <div className="paginationFeature">
                <p>Rows per page: 10</p>
              </div>
              <div className="pagination">
                <p>
                  {currentPage} - {displayedClientList.length} of{" "}
                  {getClinetlist.length}
                </p>
                <ReactPaginate
                  activePage={currentPage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={getClinetlist.length}
                  pageRangeDisplayed={10} // You can customize the number of pages displayed in the pagination bar
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                  innerClass="pagination"
                />
              </div>
            </div>
          </Container>
          <ToastContainer />
        </div>
      </div>
      <CarrierModal
        show={modalShow}
        onShowOtherModal={() => {
          setModalShow(false);
        }}
        onHide={() => {
          setModalShow(false);
        }}
      />

      <EditCarrierModal
        show={eidtModal}
        itemdetail={editDetail || {}}
        onShowOtherModal={() => {
          setEditModal(false);
        }}
        onHide={() => {
          setEditModal(false);
        }}
      />
    </>
  );
};
// Add Modal
function CarrierModal(props) {
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [accountOwnerFirstName, setAccountOwnerFirstName] = useState("");
  const [accountOwnerLastName, setAccountOwnerLastName] = useState("");
  const [accountOwnerPhone, setAccountOwnerPhone] = useState("");
  const [accountOwnerEmail, setAccountOwnerEmail] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [duns, setDuns] = useState("");
  const [iban, setIban] = useState("");
  const [bic, setBic] = useState("");
  const { userDetail } = useContext(AppStoreContext);
  const userDeatildata = userDetail;
  const { _id } = userDeatildata;

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [companyNameError, setCompanyNameError] = useState(0);
  const [addressError, setAddressError] = useState(0);
  const [contactPhoneError, setContactPhoneError] = useState(0);
  const [contactEmailError, setContactEmailError] = useState(0);
  const [accountOwnerFirstNameError, setAccountOwnerFirstNameError] =
    useState(0);
  const [accountOwnerLastNameError, setAccountOwnerLastNameError] = useState(0);
  const [accountOwnerPhoneError, setAccountOwnerPhoneError] = useState(0);
  const [accountOwnerEmailError, setAccountOwnerEmailError] = useState(0);
  const [vatNumberError, setVatNumberError] = useState(0);
  const [dunsError, setDunsError] = useState(0);
  const [ibanError, setIbanError] = useState(0);
  const [bicError, setBicError] = useState(0);

  const { setIsLoading } = useContext(LoadingContext);

  const checkStates = () => {
    setCompanyName("");
    setAddress("");
    setContactPhone("");
    setContactEmail("");
    setAccountOwnerFirstName("");
    setAccountOwnerLastName("");
    setAccountOwnerPhone("");
    setAccountOwnerEmail("");
    setVatNumber("");
    setDuns("");
    setIban("");
    setBic("");
  };

  useEffect(() => {
    onProfileValidation();
  }, [
    companyName,
    address,
    contactPhone,
    contactEmail,
    accountOwnerFirstName,
    accountOwnerLastName,
    accountOwnerPhone,
    accountOwnerEmail,
    vatNumber,
    duns,
    iban,
    bic,
  ]);

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (!companyName) {
      errorCount++;
      let msg = "Please enter company name.";
      setCompanyNameError(msg);
    } else {
      setCompanyNameError(null);
    }

    if (!address) {
      errorCount++;
      let msg = "Please enter address.";
      setAddressError(msg);
    } else {
      setAddressError(null);
    }

    if (!contactPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setContactPhoneError(msg);
    } else {
      setContactPhoneError(null);
    }

    let contactEmailError = SERVICE.validateEmail(contactEmail);
    if (contactEmailError !== undefined) {
      errorCount++;
      let msg =
        contactEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setContactEmailError(msg);
    } else {
      setContactEmailError(null);
    }

    if (!accountOwnerFirstName) {
      errorCount++;
      let msg = "Please enter first name.";
      setAccountOwnerFirstNameError(msg);
    } else {
      setAccountOwnerFirstNameError(null);
    }

    if (!accountOwnerLastName) {
      errorCount++;
      let msg = "Please enter last name.";
      setAccountOwnerLastNameError(msg);
    } else {
      setAccountOwnerLastNameError(null);
    }

    if (!accountOwnerPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setAccountOwnerPhoneError(msg);
    } else {
      setAccountOwnerPhoneError(null);
    }

    let accountOwnerEmailError = SERVICE.validateEmail(accountOwnerEmail);
    if (accountOwnerEmailError !== undefined) {
      errorCount++;
      let msg =
        accountOwnerEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setAccountOwnerEmailError(msg);
    } else {
      setAccountOwnerEmailError(null);
    }

    if (!vatNumber) {
      errorCount++;
      let msg = "Please enter VAT number.";
      setVatNumberError(msg);
    } else {
      setVatNumberError(null);
    }

    if (!duns) {
      errorCount++;
      let msg = "Please enter DUNS.";
      setDunsError(msg);
    } else {
      setDunsError(null);
    }

    if (!iban) {
      errorCount++;
      let msg = "Please enter IBAN number.";
      setIbanError(msg);
    } else {
      setIbanError(null);
    }

    if (!bic) {
      errorCount++;
      let msg = "Please enter BIC number.";
      setBicError(msg);
    } else {
      setBicError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
    console.log("errorCount", errorCount);
  };

  const onSubmit = (item) => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      console.log("check if");
      console.log("errorCount if  ", errorCount);
      return;
    } else {
      console.log("check else ");
      addClientDetial(item);
      console.log("call api");
    }
  };

  const handleCancel = (item) => {
    setSubmit(false);
    item.onHide();
  };

  const addClientDetial = (item) => {
    const payload = {
      adminId: _id,
      companyName: companyName,
      address: address,
      contactCode: selectedCountryCode,
      contactPhone: contactPhone,
      contactEmail: contactEmail,
      accountOwnerFirstName: accountOwnerFirstName,
      accountOwnerLastName: accountOwnerLastName,
      accountOwnerCode: unLoadingCountryCode,
      accountOwnerPhoneNumber: accountOwnerPhone,
      accountOwnerEmail: accountOwnerEmail,
      VATNumber: vatNumber,
      DUNS: duns,
      IBAN: iban,
      BIC: bic,
      link: `${URL_LINK}user_login`,
    };

    setIsLoading(true);
    add_Client(payload)
      .then((res) => {
        console.log("res", res);
        if (res.status === true) {
          toast("Client has been successfully added");
          setTimeout(() => {
            item.onHide(false);
            checkStates();
            setSubmit(false);
          }, 1500);
        } else {
          toast("Client Already Exist");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // const handleSelect = async (value, placeId) => {
  //   const results = await geocodeByAddress(value);
  //   const latLng = await getLatLng(results[0]);
  //   const [place] = await geocodeByPlaceId(placeId);
  //   const { long_name: postalCode = "" } =
  //     place.address_components.find((c) => c.types.includes("postal_code")) ||
  //     {};
  //   setAddress(value + " " + postalCode);
  // };

  useEffect(() => {
    getCounrtyCodeList();
  }, []);

  const [phoneNumberCode, setPhoneNumberCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+33");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("+33");

  const getCounrtyCodeList = () => {
    const payload = {};
    country_PhoneNumberCode(payload).then((res) => {
      // console.log("res phone code ---->", res.data);
      setPhoneNumberCode(res.data);
    });
  };

  const handleLoadingCountryCode = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handleUnloadingCountryCode = (event) => {
    setUnLoadingCountryCode(event.target.value);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body>
        <h2>Add client</h2>
        <BreadCrumbHeader image={UserInfoIcon} title="CLIENT INFORMATIONS" />

        <Row>
          <Col md={6}>
            <TextInputWithLabel
              label="Company name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={companyName}
              onChange={(text) => {
                setCompanyName(text.target.value);
              }}
            />
            {companyNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {companyNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Address"
              placeholder="Enter an Address"
              type="text"
              reqstart="*"
              onChange={(text) => setAddress(text.target.value)}
            />
            {/* <div>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <TextInputWithLabel
                      {...getInputProps({
                        placeholder: "Enter an Address",
                        className: "location-search-input",
                        label: "Address",
                        reqstart: "*",
                      })}
                    />
                    {suggestions.length > 0 && (
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          return (
                            <li
                              style={{ cursor: "pointer" }}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                            >
                              <FaLocationDot />
                              <span>{suggestion.description}</span>
                            </li>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>
            </div> */}
            {addressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {addressError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            {/* <TextInputWithLabel
              label="Contact phone"
              placeholder="(+33) 283 288 182"
              type="text"
              reqstart="*"
              value={contactPhone}
            
              onChange={(text) => {
                SERVICE.setNumberValidation(text.target.value, setContactPhone);
              }}
            /> */}

            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={selectedCountryCode}
                onChange={handleLoadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Contact phone"
                placeholder="283 288 182"
                type="text"
                reqstart="*"
                value={contactPhone}
                onChange={(text) => {
                  setContactPhone(text.target.value);
                  // SERVICE.setNumberValidation(
                  //   text.target.value,
                  //   setContactPhone
                  // );
                }}
              />
            </div>

            {contactPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Contact Email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              onChange={(text) => setContactEmail(text.target.value)}
            />
            {contactEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactEmailError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Account owner first name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              onChange={(text) => setAccountOwnerFirstName(text.target.value)}
            />
            {accountOwnerFirstNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerFirstNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Account owner last name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              onChange={(text) => setAccountOwnerLastName(text.target.value)}
            />
            {accountOwnerLastNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerLastNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            {/* <TextInputWithLabel
              label="Account owner phone"
              placeholder="(+33) 283 288 182"
              type="text"
              reqstart="*"
              value={accountOwnerPhone}
              onChange={(text) => {
                SERVICE.setNumberValidation(
                  text.target.value,
                  setAccountOwnerPhone
                );
              }}
            /> */}

            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={unLoadingCountryCode}
                onChange={handleUnloadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Account owner phone"
                placeholder="283 288 182"
                type="text"
                reqstart="*"
                value={accountOwnerPhone}
                onChange={(text) => {
                  setAccountOwnerPhone(text.target.value);
                  // SERVICE.setNumberValidation(
                  //   text.target.value,
                  //   setAccountOwnerPhone
                  // );
                }}
              />
            </div>

            {accountOwnerPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Account owner email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              onChange={(text) => setAccountOwnerEmail(text.target.value)}
            />
            {accountOwnerEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerEmailError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="VAT number"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={vatNumber}
              onChange={(text) => {
                if (text.target.value.length <= 13) {
                  setVatNumber(text.target.value);
                }
              }}
            />
            {vatNumberError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {vatNumberError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="DUNS"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={duns}
              onChange={(text) => {
                if (text.target.value.length <= 15) {
                  setDuns(text.target.value);
                }
              }}
            />
            {dunsError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {dunsError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="IBAN"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={iban}
              onChange={(text) => {
                if (text.target.value.length <= 25) {
                  setIban(text.target.value);
                }
              }}
            />
            {ibanError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {ibanError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="BIC"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={bic}
              onChange={(text) => {
                if (text.target.value.length <= 20) {
                  setBic(text.target.value);
                }
              }}
            />
            {bicError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {bicError}
              </div>
            ) : null}
          </Col>
        </Row>

        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={() => handleCancel(props)}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="btn-primaryAll btnPrimary"
            // onClick={props.onHide}
            onClick={() => onSubmit(props)}
          >
            Create this partner
          </Button>
        </div>
        <ToastContainer />
      </Modal.Body>
    </Modal>
  );
}

// Edit Modal
function EditCarrierModal(props) {
  useEffect(() => {
    if (props.itemdetail) {
      setCompanyName(props.itemdetail.companyName);
      setAddress(props.itemdetail.address);
      setContactPhone(props.itemdetail.contactPhone);
      setContactEmail(props.itemdetail.contactEmail);
      setAccountOwnerFirstName(props.itemdetail.accountOwnerFirstName);
      setAccountOwnerLastName(props.itemdetail.accountOwnerLastName);
      setAccountOwnerPhone(props.itemdetail.accountOwnerPhoneNumber);
      setAccountOwnerEmail(props.itemdetail.accountOwnerEmail);
      setVatNumber(props.itemdetail.VATNumber);
      setDuns(props.itemdetail.DUNS);
      setIban(props.itemdetail.IBAN);
      setBic(props.itemdetail.BIC);
      setSelectedCountryCode(props.itemdetail.contactCode);
      setUnLoadingCountryCode(props.itemdetail.accountOwnerCode);
    }
  }, [props.itemdetail]);

  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [accountOwnerFirstName, setAccountOwnerFirstName] = useState("");
  const [accountOwnerLastName, setAccountOwnerLastName] = useState("");
  const [accountOwnerPhone, setAccountOwnerPhone] = useState("");
  const [accountOwnerEmail, setAccountOwnerEmail] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [duns, setDuns] = useState("");
  const [iban, setIban] = useState("");
  const [bic, setBic] = useState("");

  const { userDetail } = useContext(AppStoreContext);
  const userDeatildata = userDetail;
  const { _id } = userDeatildata;

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [companyNameError, setCompanyNameError] = useState(0);
  const [addressError, setAddressError] = useState(0);
  const [contactPhoneError, setContactPhoneError] = useState(0);
  const [contactEmailError, setContactEmailError] = useState(0);
  const [accountOwnerFirstNameError, setAccountOwnerFirstNameError] =
    useState(0);
  const [accountOwnerLastNameError, setAccountOwnerLastNameError] = useState(0);
  const [accountOwnerPhoneError, setAccountOwnerPhoneError] = useState(0);
  const [accountOwnerEmailError, setAccountOwnerEmailError] = useState(0);
  const [vatNumberError, setVatNumberError] = useState(0);
  const [dunsError, setDunsError] = useState(0);
  const [ibanError, setIbanError] = useState(0);
  const [bicError, setBicError] = useState(0);
  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    onProfileValidation();
  }, [
    companyName,
    address,
    contactPhone,
    contactEmail,
    accountOwnerFirstName,
    accountOwnerLastName,
    accountOwnerPhone,
    accountOwnerEmail,
    vatNumber,
    duns,
    iban,
    bic,
  ]);

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (!companyName) {
      errorCount++;
      let msg = "Please enter company name.";
      setCompanyNameError(msg);
    } else {
      setCompanyNameError(null);
    }

    if (!address) {
      errorCount++;
      let msg = "Please enter address.";
      setAddressError(msg);
    } else {
      setAddressError(null);
    }

    if (!contactPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setContactPhoneError(msg);
    } else {
      setContactPhoneError(null);
    }

    let contactEmailError = SERVICE.validateEmail(contactEmail);
    if (contactEmailError !== undefined) {
      errorCount++;
      let msg =
        contactEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setContactEmailError(msg);
    } else {
      setContactEmailError(null);
    }

    if (!accountOwnerFirstName) {
      errorCount++;
      let msg = "Please enter first name.";
      setAccountOwnerFirstNameError(msg);
    } else {
      setAccountOwnerFirstNameError(null);
    }

    if (!accountOwnerLastName) {
      errorCount++;
      let msg = "Please enter last name.";
      setAccountOwnerLastNameError(msg);
    } else {
      setAccountOwnerLastNameError(null);
    }

    if (!accountOwnerPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setAccountOwnerPhoneError(msg);
    } else {
      setAccountOwnerPhoneError(null);
    }

    let accountOwnerEmailError = SERVICE.validateEmail(accountOwnerEmail);
    if (accountOwnerEmailError !== undefined) {
      errorCount++;
      let msg =
        accountOwnerEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setAccountOwnerEmailError(msg);
    } else {
      setAccountOwnerEmailError(null);
    }

    if (!vatNumber) {
      errorCount++;
      let msg = "Please enter VAT number.";
      setVatNumberError(msg);
    } else {
      setVatNumberError(null);
    }

    if (!duns) {
      errorCount++;
      let msg = "Please enter DUNS.";
      setDunsError(msg);
    } else {
      setDunsError(null);
    }

    if (!iban) {
      errorCount++;
      let msg = "Please enter IBAN number.";
      setIbanError(msg);
    } else {
      setIbanError(null);
    }

    if (!bic) {
      errorCount++;
      let msg = "Please enter BIC number.";
      setBicError(msg);
    } else {
      setBicError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
    console.log("errorCount", errorCount);
  };

  const onSubmit = (item) => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      console.log("check if");
      console.log("errorCount if  ", errorCount);
      return;
    } else {
      console.log("check else ");
      editClientDetail(item);
      console.log("call api");
    }
  };

  const editClientDetail = (item) => {
    const payload = {
      companyName: companyName,
      address: address,
      contactCode: selectedCountryCode,
      contactPhone: contactPhone,
      contactEmail: contactEmail,
      accountOwnerFirstName: accountOwnerFirstName,
      accountOwnerLastName: accountOwnerLastName,
      accountOwnerCode: unLoadingCountryCode,
      accountOwnerPhoneNumber: accountOwnerPhone,
      accountOwnerEmail: accountOwnerEmail,
      VATNumber: vatNumber,
      DUNS: duns,
      IBAN: iban,
      BIC: bic,
      adminId: _id,
      Clientid: props.itemdetail._id,
    };
    setIsLoading(true);
    console.log("payLoad", payload);
    client_EditDetail(payload)
      .then((res) => {
        console.log("res", res);
        if (res.status === true) {
          toast("Client details have been updated successfully.");
          selectedItem = res.result.data;
          setTimeout(() => {
            item.onHide(false);
          }, 1500);
        } else toast(res.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  //phone code
  useEffect(() => {
    getCounrtyCodeList();
  }, []);

  const [phoneNumberCode, setPhoneNumberCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("");

  const getCounrtyCodeList = () => {
    const payload = {};
    country_PhoneNumberCode(payload).then((res) => {
      // console.log("res phone code ---->", res.data);
      setPhoneNumberCode(res.data);
    });
  };

  const handleLoadingCountryCode = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handleUnloadingCountryCode = (event) => {
    setUnLoadingCountryCode(event.target.value);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body>
        <h2>Edit client</h2>
        <BreadCrumbHeader image={UserInfoIcon} title="CLIENT INFORMATIONS" />

        <Row>
          <Col md={6}>
            <EditFieldTextInput
              label="Company name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={companyName}
              onChange={(text) => setCompanyName(text)}
            />
            {companyNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {companyNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Address"
              placeholder="Enter an Address"
              type="text"
              reqstart="*"
              value={address}
              onChange={(text) => setAddress(text)}
            />
            {addressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {addressError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            {/* <EditFieldTextInput
              label="Contact phone"
              placeholder="(+33) 283 288 182"
              type="text"
              reqstart="*"
              value={contactPhone}
              onChange={(text) => {
                SERVICE.setNumberValidation(text, setContactPhone);
              }}
            /> */}
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={selectedCountryCode}
                onChange={handleLoadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>

              <EditFieldTextInput
                label="Contact phone"
                placeholder="283 288 182"
                type="text"
                reqstart="*"
                value={contactPhone}
                onChange={(text) => {
                  setContactPhone(text);
                  // SERVICE.setNumberValidation(text, setContactPhone);
                }}
              />
            </div>
            {contactPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Contact Email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              value={contactEmail}
              onChange={(text) => setContactEmail(text)}
            />
            {contactEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactEmailError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Account owner first name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={accountOwnerFirstName}
              onChange={(text) => setAccountOwnerFirstName(text)}
            />
            {accountOwnerFirstNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerFirstNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Account owner last name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={accountOwnerLastName}
              onChange={(text) => setAccountOwnerLastName(text)}
            />
            {accountOwnerLastNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerLastNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            {/* <EditFieldTextInput
              label="Account owner phone"
              placeholder="(+33) 283 288 182"
              type="text"
              reqstart="*"
              value={accountOwnerPhone}
              onChange={(text) => {
                SERVICE.setNumberValidation(text, setAccountOwnerPhone);
              }}
            /> */}

            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={unLoadingCountryCode}
                onChange={handleUnloadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <EditFieldTextInput
                label="Contact phone"
                placeholder="283 288 182"
                type="text"
                reqstart="*"
                value={accountOwnerPhone}
                onChange={(text) => {
                  setAccountOwnerPhone(text);
                  // SERVICE.setNumberValidation(text, setAccountOwnerPhone);
                }}
              />
            </div>

            {accountOwnerPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Account owner email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              value={accountOwnerEmail}
              onChange={(text) => setAccountOwnerEmail(text)}
            />
            {accountOwnerEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerEmailError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="VAT number"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={vatNumber}
              onChange={(text) => {
                setVatNumber(text);
                // SERVICE.setNumberValidation(text, setVatNumber);
              }}
            />
            {vatNumberError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {vatNumberError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="DUNS"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={duns}
              onChange={(text) => {
                setDuns(text);
                // SERVICE.setNumberValidation(text, setDuns);
              }}
            />
            {dunsError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {dunsError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="IBAN"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={iban}
              onChange={(text) => {
                setIban(text);
                // SERVICE.setNumberValidation(text, setIban);
              }}
            />
            {ibanError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {ibanError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="BIC"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={bic}
              onChange={(text) => {
                setBic(text);
                // SERVICE.setNumberValidation(text, setBic);
              }}
            />
            {bicError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {bicError}
              </div>
            ) : null}
          </Col>
        </Row>

        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={props.onHide}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="btn-primaryAll btnPrimary"
            // onClick={props.onHide}
            onClick={() => onSubmit(props)}
          >
            Save Changes
          </Button>
        </div>
        <ToastContainer />
      </Modal.Body>
    </Modal>
  );
}
export default Client;
