import React, { useState, useContext } from "react";
import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import { Col, Container, Image, Row, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiPlusCircle } from "react-icons/fi";
import EditIcon2 from "../images/edit-2.svg";
import TrashIcon from "../images/trash.svg";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import DeliveryIcon from "../images/truck.svg";
import TextInputWithLabel from "../customcomponents/AvailTextInputWithLabel";
import CalanderImg from "../images/calander.png";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppStoreContext } from "../../store/AppStore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaLocationDot } from "react-icons/fa6";
import TruckNew from "../images/trucknew.jpg";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import Loader from "../../common/component/Loader";

const Availabilities = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowEdit, setModalShowEdit] = React.useState(false);
  const  [confirmModel  , setConfirmModel] = useState(false)
  const [delId , setDelId] = useState(null)
  const [availabilities, setAvailabilities] = useState({
    cargo: [],
    tanker: [],
    refrigerated: [],
  });
  const [selectedAvailability, setselectedAvailability] = useState(null);
  console.log(
    "🚀 ~ Availabilities ~ selectedAvailability:",
    selectedAvailability
  );
  const { userDetail } = useContext(AppStoreContext);

  const fetchAvailabilities = async () => {
    try {
      const user_id = userDetail._id;
      const response = await axios.get(
        "https://mottruck.com:3001/get-availability-list?user_id=" + user_id
      );
      if (response.data.status !== 1) {
        throw new Error(
          `API request failed with status ${response.data.status}`
        );
      }
      const data = response.data.data;
      // console.log("data", data);

      // Filter data by category
      const cargoAvailabilities = data.filter(
        (item) => item.category === "cargo"
      );
      const tankerAvailabilities = data.filter(
        (item) => item.category === "tanker"
      );
      const refrigeratedAvailabilities = data.filter(
        (item) => item.category === "refrigerated"
      );

      setAvailabilities({
        cargo: cargoAvailabilities,
        tanker: tankerAvailabilities,
        refrigerated: refrigeratedAvailabilities,
      });
    } catch (error) {
      console.error("Error fetching availabilities:", error);
    }
  };

    //
 

  // const handleDelete = async (id) => {
  //   const confirmed = window.confirm(
  //     "Are you sure you want to delete this availability?"
  //   );
  //   if (!confirmed) {
  //     return; // If user cancels, do nothing
  //   }
   
  // };

  React.useEffect(() => {
    fetchAvailabilities();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero based
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };




  const ConfirmAvailabilities = (props) => {
    const [deleteQuoteLoading, setDeleteQuoteLoading] = useState(false);
  
    const handleDelete = async (delId) => {
      try {
        setDeleteQuoteLoading(true);
    
        // MARK: API HIT HERE
        try {
          const response = await axios.get(
            `https://mottruck.com:3001/removed-availability?id=${delId}`
          );
          toast.success("Availability removed successfully!");
          fetchAvailabilities();
        } catch (error) {
          console.error("Error deleting availability:", error);
          toast.error("Error removing availability. Please try again later.");
        } finally {
          setDelId(null);
        }
    
        setDeleteQuoteLoading(false);
        setConfirmModel(false);
      } catch (error) {
        console.error("Error while deleting from the list: ", error);
        toast.error("Failed to delete.");
      }
    };
    

    return (
      <Modal {...props}>
        <Modal.Body className="p-3">
          <div className="deleteModal text-center">
            {/* <XCircle className="deletemodal_icon" /> */}
            <h2>Are You Sure ?</h2>
            <p>You will not be able to recover the deleted record!</p>
            <div className="btn-cancel-ok">
              <Button
                className="btn-cancel me-1"
                disabled={deleteQuoteLoading}
                onClick={() => {
                  setConfirmModel(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ width: 95 }}
                className="btn-ok"
                onClick={() => {
                  handleDelete(delId)
                }}
                disabled={deleteQuoteLoading}
              >
                {deleteQuoteLoading ? <Loader  /> : "OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          <NavBar />
          <ConfirmAvailabilities centered show={confirmModel} />
          <Container fluid className="pt-100">
            <div className="quotes_div">
              <div className="availBox">
                <div className="braedContact mb-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M13 14.75H2C1.59 14.75 1.25 14.41 1.25 14V6C1.25 3.38 3.38 1.25 6 1.25H15C15.41 1.25 15.75 1.59 15.75 2V12C15.75 13.52 14.52 14.75 13 14.75ZM2.75 13.25H13C13.69 13.25 14.25 12.69 14.25 12V2.75H6C4.21 2.75 2.75 4.21 2.75 6V13.25Z"
                      fill="#4F5B92"
                    />
                    <path
                      d="M19 20.75H18C17.59 20.75 17.25 20.41 17.25 20C17.25 19.31 16.69 18.75 16 18.75C15.31 18.75 14.75 19.31 14.75 20C14.75 20.41 14.41 20.75 14 20.75H10C9.59 20.75 9.25 20.41 9.25 20C9.25 19.31 8.69 18.75 8 18.75C7.31 18.75 6.75 19.31 6.75 20C6.75 20.41 6.41 20.75 6 20.75H5C2.93 20.75 1.25 19.07 1.25 17V14C1.25 13.59 1.59 13.25 2 13.25H13C13.69 13.25 14.25 12.69 14.25 12V5C14.25 4.59 14.59 4.25 15 4.25H16.84C17.83 4.25 18.74 4.78001 19.23 5.64001L20.94 8.63C21.07 8.86 21.07 9.15 20.94 9.38C20.81 9.61 20.56 9.75 20.29 9.75H19C18.86 9.75 18.75 9.86 18.75 10V13C18.75 13.14 18.86 13.25 19 13.25H22C22.41 13.25 22.75 13.59 22.75 14V17C22.75 19.07 21.07 20.75 19 20.75ZM18.65 19.25H19C20.24 19.25 21.25 18.24 21.25 17V14.75H19C18.04 14.75 17.25 13.96 17.25 13V10C17.25 9.04 18.03 8.25 19 8.25L17.93 6.38C17.71 5.99 17.29 5.75 16.84 5.75H15.75V12C15.75 13.52 14.52 14.75 13 14.75H2.75V17C2.75 18.24 3.76 19.25 5 19.25H5.35001C5.68001 18.1 6.74 17.25 8 17.25C9.26 17.25 10.32 18.1 10.65 19.25H13.36C13.69 18.1 14.75 17.25 16.01 17.25C17.27 17.25 18.32 18.1 18.65 19.25Z"
                      fill="#4F5B92"
                    />
                    <path
                      d="M8 22.75C6.48 22.75 5.25 21.52 5.25 20C5.25 18.48 6.48 17.25 8 17.25C9.52 17.25 10.75 18.48 10.75 20C10.75 21.52 9.52 22.75 8 22.75ZM8 18.75C7.31 18.75 6.75 19.31 6.75 20C6.75 20.69 7.31 21.25 8 21.25C8.69 21.25 9.25 20.69 9.25 20C9.25 19.31 8.69 18.75 8 18.75Z"
                      fill="#4F5B92"
                    />
                    <path
                      d="M16 22.75C14.48 22.75 13.25 21.52 13.25 20C13.25 18.48 14.48 17.25 16 17.25C17.52 17.25 18.75 18.48 18.75 20C18.75 21.52 17.52 22.75 16 22.75ZM16 18.75C15.31 18.75 14.75 19.31 14.75 20C14.75 20.69 15.31 21.25 16 21.25C16.69 21.25 17.25 20.69 17.25 20C17.25 19.31 16.69 18.75 16 18.75Z"
                      fill="#4F5B92"
                    />
                    <path
                      d="M22 14.75H19C18.04 14.75 17.25 13.96 17.25 13V10C17.25 9.04 18.04 8.25 19 8.25H20.29C20.56 8.25 20.81 8.39 20.94 8.63L22.65 11.63C22.71 11.74 22.75 11.87 22.75 12V14C22.75 14.41 22.41 14.75 22 14.75ZM19 9.75C18.86 9.75 18.75 9.86 18.75 10V13C18.75 13.14 18.86 13.25 19 13.25H21.25V12.2L19.85 9.75H19Z"
                      fill="#4F5B92"
                    />
                  </svg>
                  <h3>VEHICLE(S)</h3>

                  <Link onClick={() => setModalShow(true)}>
                    <FiPlusCircle /> Avaibility
                  </Link>
                </div>

                <Row>
                  <Col md={5} lg={4}>
                    <Row>
                      <Col md={12}>
                        <div className="availBox_box mb-3">
                          <div className="avail_header">
                            <h3>GENERAL CARGO</h3>
                          </div>

                          <h4 className="mt-3 d-flex justify-content-between">Availabilities <span class="badge bg-primary">{availabilities.cargo.length}</span></h4>
                          <div className="materialBox materialBoxin">
                            {availabilities.cargo &&
                            availabilities.cargo.length > 0 ? (
                              availabilities.cargo.map((item, index) => (
                                <div className="matInfoBoxItems">
                                  <div className="fCenter">
                                    <div className="fixWith">
                                      <span>
                                        <h3>
                                          {formatDate(item.from_date)} -{" "}
                                          {formatDate(item.to_date)} :{" "}
                                          {item.city}- {item.country_short}
                                        </h3>
                                      </span>
                                    </div>
                                    <div className="fBottomFlex">
                                      <div className="fixWith">
                                        <h3>{item.number_plate}</h3>
                                      </div>
                                      <div className="fixWith dividerbox">
                                        <div className="divider"></div>
                                      </div>
                                      <div className="fixWith">
                                        <h3>{item.truck_capacity}kg</h3>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="carFlex">
                                    <Link
                                      className="me-1"
                                      onClick={() => {
                                        setselectedAvailability(item);
                                        setModalShowEdit(true);
                                      }}
                                    >
                                      <Image
                                        className="tIcon"
                                        src={EditIcon2}
                                        alt=""
                                      />
                                    </Link>
                                    <Link
                                      onClick={() =>  {
                                        setDelId(item._id)
                                        setConfirmModel(true)
                                      }}
                                    >
                                      <Image
                                        className="editimgt"
                                        src={TrashIcon}
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="noSlot">
                                <sapn>No Slot Added</sapn>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="availBox_box mb-3">
                          <div className="avail_header">
                            <h3>TANKER</h3>
                          </div>
                          <h4 className="mt-3 d-flex justify-content-between">Availabilities <span class="badge bg-primary">{availabilities.tanker.length}</span></h4>
                          <div className="materialBox materialBoxin">
                            {availabilities.tanker &&
                            availabilities.tanker.length > 0 ? (
                              availabilities.tanker.map((item, index) => (
                                <div className="matInfoBoxItems">
                                  <div className="fCenter">
                                    <div className="fixWith">
                                      <span>
                                        <h3>
                                          {formatDate(item.from_date)} -{" "}
                                          {formatDate(item.to_date)} :{" "}
                                          {item.city}- {item.country_short}
                                        </h3>
                                      </span>
                                    </div>
                                    <div className="fBottomFlex">
                                      <div className="fixWith">
                                        <h3>{item.number_plate}</h3>
                                      </div>
                                      <div className="fixWith dividerbox">
                                        <div className="divider"></div>
                                      </div>
                                      <div className="fixWith">
                                        <h3>{item.truck_capacity}L</h3>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="carFlex">
                                    <Link
                                      className="me-1"
                                      onClick={() => {
                                        setselectedAvailability(item);
                                        setModalShowEdit(true);
                                      }}
                                    >
                                      <Image
                                        className="tIcon"
                                        src={EditIcon2}
                                        alt=""
                                      />
                                    </Link>
                                    <Link
                                      onClick={() => {
                                        setDelId(item._id)
                                        setConfirmModel(true)
                                      }}
                                    >
                                      <Image
                                        className="editimgt"
                                        src={TrashIcon}
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="noSlot">
                                <span>No Slot Added</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="availBox_box">
                          <div className="avail_header">
                            <h3>REFRIGERATED</h3>
                          </div>

                          <h4 className="mt-3 d-flex justify-content-between">Availabilities <span class="badge bg-primary">{availabilities.refrigerated.length}</span></h4>
                          <div className="materialBox materialBoxin">
                            {availabilities.refrigerated &&
                            availabilities.refrigerated.length > 0 ? (
                              availabilities.refrigerated.map((item, index) => (
                                <div className="matInfoBoxItems">
                                  <div className="fCenter">
                                    <div className="fixWith">
                                      <span>
                                        <h3>
                                          {formatDate(item.from_date)} -{" "}
                                          {formatDate(item.to_date)} :{" "}
                                          {item.city}- {item.country_short}
                                        </h3>
                                      </span>
                                    </div>
                                    <div className="fBottomFlex">
                                      <div className="fixWith">
                                        <h3>{item.number_plate}</h3>
                                      </div>
                                      <div className="fixWith dividerbox">
                                        <div className="divider"></div>
                                      </div>
                                      <div className="fixWith">
                                        <h3>{item.truck_capacity}kg</h3>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="carFlex">
                                    <Link
                                      className="me-1"
                                      onClick={() => {
                                        setselectedAvailability(item);
                                        setModalShowEdit(true);
                                      }}
                                    >
                                      <Image
                                        className="tIcon"
                                        src={EditIcon2}
                                        alt=""
                                      />
                                    </Link>
                                    <Link
                                      onClick={() => {
                                        setDelId(item._id)
                                        setConfirmModel(true)
                                      }}
                                    >
                                      <Image
                                        className="editimgt"
                                        src={TrashIcon}
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="noSlot">
                                <span>No Slot Added</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={8} md={7}>
                    <div className="truckNew">
                      <Image src={TruckNew} className="img-fluid" alt="" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
        <VehicleModalOne
          show={modalShow}
          onHide={() => setModalShow(false)}
          fetchLatestData={() => fetchAvailabilities()}
        />
        {selectedAvailability ? (
          <VehicleModalEdit
            selectedAvailability={selectedAvailability}
            show={modalShowEdit}
            onHide={() => {
              setModalShowEdit(false);
            }}
            fetchLatestData={() => {
              fetchAvailabilities();
              setselectedAvailability(null);
            }}
          />
        ) : null}
      </div>
      <ToastContainer> </ToastContainer>
    </React.Fragment>
  );
};

export default Availabilities;

function VehicleModalOne(props) {
  const { userDetail } = useContext(AppStoreContext);
  const user_id = userDetail._id;

  const [activeTab, setActiveTab] = useState("tab1");
  const [formData, setFormData] = useState({
    user_id: user_id,
    category: "cargo",
    number_plate: "",
    // total_capacity: "",
    size_of_pallets: "",
    height_of_pallets: "",
    date_range: [new Date(), new Date()],
    location_name: "",
    latitude: "",
    longitude: "",
    city: "",
    country: "",
    country_short: "",
    truck_capacity: "",
    radius: "",
  });
  const [errors, setErrors] = useState({});
  const [isCalendarOpen, setIsCalendarOpen] = useState(true);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setFormData({
      ...formData,
      category:
        tab === "tab1" ? "cargo" : tab === "tab2" ? "tanker" : "refrigerated",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });

    if (name === "category" && value === "tanker") {
      setFormData({
        ...formData,
        size_of_pallets: "",
      });
    }
  };

  const handleDateChange = (update) => {
    setFormData({
      ...formData,
      date_range: update,
    });
  };

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);

    let city = "";
    let country = "";
    let countryShort = "";
    for (const component of results[0].address_components) {
      if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
        countryShort = component.short_name;
      }
    }

    setFormData({
      ...formData,
      location_name: address,
      latitude: latLng.lat,
      longitude: latLng.lng,
      city: city,
      country: country,
      country_short: countryShort,
    });
  };

  const validate = () => {
    let errors = {};
    if (!formData.number_plate)
      errors.number_plate = "Number plate is required";
    // if (!formData.total_capacity)
    //   errors.total_capacity = "Total capacity is required";
    // if (formData.category === "cargo" || formData.category === "refrigerated") {
    //   if (!formData.size_of_pallets)
    //     errors.size_of_pallets = "Size of pallets is required";
    // }
    if (!formData.location_name) errors.location_name = "Location is required";
    if (!formData.radius) errors.radius = "Radius is required";
    if (!formData.truck_capacity)
      errors.truck_capacity = "Truck capacity is required";
    return errors;
  };

  const handleSubmit = async () => {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      const response = await axios.post(
        "https://mottruck.com:3001/add-availability",
        {
          ...formData,
          from_date: formatDate(formData.date_range[0]),
          to_date: formatDate(formData.date_range[1]),
        }
      );
      toast("Availabilities added successfully!");

      setFormData({
        ...formData,
        number_plate: "",
        // total_capacity: "",
        size_of_pallets: "",
        height_of_pallets: "",
        date_range: [new Date(), new Date()],
        location_name: "",
        latitude: "",
        longitude: "",
        city: "",
        country: "",
        country_short: "",
        truck_capacity: "",
        radius: "",
      });

      props.onHide();
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
      onExited={() => {
        props.fetchLatestData();
      }}
    >
      <Modal.Body>
        <h2>Availability</h2>
        <BreadCrumbHeader image={DeliveryIcon} title="VEHICLE INFORMATIONS" />
        <h4 className="modal_label">
          Select a category <span className="requiresLable">*</span>
        </h4>
        <div className="tab-buttons">
          <Button
            onClick={() => handleTabClick("tab1")}
            className={activeTab === "tab1" ? "active" : ""}
          >
            General cargo
          </Button>
          <Button
            onClick={() => handleTabClick("tab2")}
            className={activeTab === "tab2" ? "active" : ""}
          >
            Tanker
          </Button>
          <Button
            onClick={() => handleTabClick("tab3")}
            className={activeTab === "tab3" ? "active" : ""}
          >
            Refrigerated
          </Button>
        </div>

        <div className="tab-content">
          {activeTab === "tab1" && (
            <>
              <div className="inputFlex">
                <div>
                  <TextInputWithLabel
                    name="number_plate"
                    placeholder="0"
                    label="Number plate"
                    reqstart="*"
                    value={formData.number_plate}
                    onChange={handleChange}
                    error={errors.number_plate}
                  />
                </div>
                <div className="kgflex position-relative">
                  <TextInputWithLabel
                    name="truck_capacity"
                    placeholder="0"
                    label="Truck capacity"
                    reqstart="*"
                    value={formData.truck_capacity}
                    onChange={handleChange}
                    error={errors.truck_capacity}
                  />
                  <span className="kgPos">Kg</span>
                </div>
                <div>
                  <TextInputWithLabel
                    name="size_of_pallets"
                    placeholder="0"
                    label="Size of pallets"
                    // reqstart="*"
                    value={formData.size_of_pallets}
                    onChange={handleChange}
                    error={errors.size_of_pallets}
                  />
                </div>
                <div>
                  <TextInputWithLabel
                    name="height_of_pallets"
                    placeholder="0"
                    label="Height of pallets"
                    value={formData.height_of_pallets}
                    onChange={handleChange}
                    error={errors.height_of_pallets}
                  />
                </div>
              </div>
              <h4 className="modal_label mb-4 mt-2">
                Enter availabilities and locations of your vehicle:
              </h4>
              <Row>
                <Col lg={6} md={6}>
                  <div className="datePickerContainer">
                    <label className="labelHead">Select Date Range</label>
                    <DatePicker
                      selectsRange
                      startDate={formData.date_range[0]}
                      endDate={formData.date_range[1]}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      isClearable
                      // open={isCalendarOpen}
                      // onClickOutside={() => setIsCalendarOpen(false)}
                      // onInputClick={() => setIsCalendarOpen(true)}
                    />
                  </div>
                </Col>

                <Col lg={6} md={6}>
                  <div className="calenderBox">
                    <PlacesAutocomplete
                      value={formData.location_name}
                      onChange={(address) =>
                        setFormData({ ...formData, location_name: address })
                      }
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextInputWithLabel
                            {...getInputProps({
                              placeholder: "Enter a city",
                              className: "location-search-input",
                              label: "Truck location",
                              reqstart: "*",
                              error: errors.location_name, // Added error prop here
                            })}
                          />
                          {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <div className="kgflex position-relative mt-3">
                      <div>
                        <TextInputWithLabel
                          name="radius"
                          placeholder="0"
                          label="Around a radius"
                          reqstart="*"
                          value={formData.radius}
                          onChange={handleChange}
                          error={errors.radius}
                        />
                      </div>
                      <span className="kgPos">Km</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {activeTab === "tab2" && (
            <>
              <div className="inputFlex inputFlexUpdate">
                <div>
                  <TextInputWithLabel
                    name="number_plate"
                    placeholder="0"
                    label="Number plate"
                    reqstart="*"
                    value={formData.number_plate}
                    onChange={handleChange}
                    error={errors.number_plate}
                  />
                </div>
                <div className="kgflex">
                  <TextInputWithLabel
                    name="truck_capacity"
                    placeholder="0"
                    label="Truck capacity"
                    reqstart="*"
                    value={formData.truck_capacity}
                    onChange={handleChange}
                    error={errors.truck_capacity}
                  />
                  <span className="kgPos">L</span>
                </div>
              </div>
              <h4 className="modal_label mb-4 mt-2">
                Enter availabilities and locations of your vehicle:
              </h4>
              <Row>
                <Col lg={6} md={6}>
                  <div className="datePickerContainer">
                    <label className="labelHead">Select Date Range</label>
                    <DatePicker
                      selectsRange
                      startDate={formData.date_range[0]}
                      endDate={formData.date_range[1]}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      isClearable
                    />
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="calenderBox">
                    <PlacesAutocomplete
                      value={formData.location_name}
                      onChange={(address) =>
                        setFormData({ ...formData, location_name: address })
                      }
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextInputWithLabel
                            {...getInputProps({
                              placeholder: "Enter a city",
                              className: "location-search-input",
                              label: "Truck location",
                              reqstart: "*",
                              error: errors.location_name, // Added error prop here
                            })}
                          />
                          {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <div className="kgflex position-relative mt-3">
                      <div>
                        <TextInputWithLabel
                          name="radius"
                          placeholder="0"
                          label="Around a radius"
                          reqstart="*"
                          value={formData.radius}
                          onChange={handleChange}
                          error={errors.radius}
                        />
                      </div>
                      <span className="kgPos">Km</span>
                    </div>
                    {/* <div className="calenderBox_btn text-center">
                      <Button className="me-2 cancel" onClick={props.onHide}>Cancel</Button>
                      <Button className="save" onClick={handleSubmit}>Save</Button>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </>
          )}
          {activeTab === "tab3" && (
            <>
              <div className="inputFlex">
                <div>
                  <TextInputWithLabel
                    name="number_plate"
                    placeholder="0"
                    label="Number plate"
                    reqstart="*"
                    value={formData.number_plate}
                    onChange={handleChange}
                    error={errors.number_plate}
                  />
                </div>
                <div className="kgflex position-relative">
                  <TextInputWithLabel
                    name="truck_capacity"
                    placeholder="0"
                    label="Truck capacity"
                    reqstart="*"
                    value={formData.truck_capacity}
                    onChange={handleChange}
                    error={errors.truck_capacity}
                  />
                  <span className="kgPos">Kg</span>
                </div>
                <div>
                  <TextInputWithLabel
                    name="size_of_pallets"
                    placeholder="0"
                    label="Size of pallets"
                    // reqstart="*"
                    value={formData.size_of_pallets}
                    onChange={handleChange}
                    error={errors.size_of_pallets}
                  />
                </div>
                <div>
                  <TextInputWithLabel
                    name="height_of_pallets"
                    placeholder="0"
                    label="Height of pallets"
                    value={formData.height_of_pallets}
                    onChange={handleChange}
                    error={errors.height_of_pallets}
                  />
                </div>
              </div>
              <h4 className="modal_label mb-4 mt-2">
                Enter availabilities and locations of your vehicle:
              </h4>
              <Row>
                <Col lg={6} md={6}>
                  <div className="datePickerContainer">
                    <label className="labelHead">Select Date Range</label>
                    <DatePicker
                      selectsRange
                      startDate={formData.date_range[0]}
                      endDate={formData.date_range[1]}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      isClearable
                    />
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="calenderBox">
                    <PlacesAutocomplete
                      value={formData.location_name}
                      onChange={(address) =>
                        setFormData({ ...formData, location_name: address })
                      }
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextInputWithLabel
                            {...getInputProps({
                              placeholder: "Enter a city",
                              className: "location-search-input",
                              label: "Truck location",
                              reqstart: "*",
                              error: errors.location_name, // Added error prop here
                            })}
                          />
                          {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <div className="kgflex position-relative mt-3">
                      <div>
                        <TextInputWithLabel
                          name="radius"
                          placeholder="0"
                          label="Around a radius"
                          reqstart="*"
                          value={formData.radius}
                          onChange={handleChange}
                          error={errors.radius}
                        />
                      </div>
                      <span className="kgPos">Km</span>
                    </div>
                    {/* <div className="calenderBox_btn text-center">
                      <Button className="me-2 cancel" onClick={props.onHide}>Cancel</Button>
                      <Button className="save" onClick={handleSubmit}>Save</Button>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>

        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={props.onHide}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn-primaryAll btnPrimary"
            onClick={handleSubmit}
          >
            Add this vehicle
          </Button>
        </div>
        {/* <Button onClick={handleSubmit} className="submitButton">
          Submit
        </Button> */}
      </Modal.Body>
    </Modal>
  );
}

function VehicleModalEdit(props) {
  const { userDetail } = useContext(AppStoreContext);
  const user_id = userDetail._id;

  const { selectedAvailability } = props; // Existing vehicle data to be edited

  const [activeTab, setActiveTab] = useState(
    selectedAvailability.category === "cargo"
      ? "tab1"
      : selectedAvailability.category === "tanker"
      ? "tab2"
      : "tab3"
  );
  const [formData, setFormData] = useState({
    id: selectedAvailability._id,
    user_id: user_id,
    category: selectedAvailability.category || "cargo",
    number_plate: selectedAvailability.number_plate || "",
    // total_capacity: selectedAvailability.total_capacity || "",
    size_of_pallets: selectedAvailability.size_of_pallets || "",
    height_of_pallets: selectedAvailability.height_of_pallets || "",
    date_range: [
      new Date(selectedAvailability.from_date),
      new Date(selectedAvailability.to_date),
    ] || [new Date(), new Date()],
    location_name: selectedAvailability.location_name || "",
    latitude: selectedAvailability.latitude || "",
    longitude: selectedAvailability.longitude || "",
    city: selectedAvailability.city || "",
    country: selectedAvailability.country || "",
    country_short: selectedAvailability.country_short || "",
    truck_capacity: selectedAvailability.truck_capacity || "",
    radius: selectedAvailability.radius || "",
  });
  const [errors, setErrors] = useState({});
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setFormData({
      ...formData,
      category:
        tab === "tab1" ? "cargo" : tab === "tab2" ? "tanker" : "refrigerated",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });

    if (name === "category" && value === "tanker") {
      setFormData({
        ...formData,
        size_of_pallets: "",
      });
    }
  };

  const handleDateChange = (update) => {
    setFormData({
      ...formData,
      date_range: update,
    });
  };

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);

    let city = "";
    let country = "";
    let countryShort = "";
    for (const component of results[0].address_components) {
      if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
        countryShort = component.short_name;
      }
    }

    setFormData({
      ...formData,
      location_name: address,
      latitude: latLng.lat,
      longitude: latLng.lng,
      city: city,
      country: country,
      country_short: countryShort,
    });
  };

  const validate = () => {
    let errors = {};
    if (!formData.number_plate)
      errors.number_plate = "Number plate is required";
    // if (!formData.total_capacity)
    //   errors.total_capacity = "Total capacity is required";
    // if (formData.category === "cargo" || formData.category === "refrigerated") {
    //   if (!formData.size_of_pallets)
    //     errors.size_of_pallets = "Size of pallets is required";
    // }
    if (!formData.location_name) errors.location_name = "Location is required";
    if (!formData.radius) errors.radius = "Radius is required";
    if (!formData.truck_capacity)
      errors.truck_capacity = "Truck capacity is required";
    return errors;
  };

  const handleSubmit = async () => {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      const from_date =
        formData.date_range[0] instanceof Date
          ? formatDate(formData.date_range[0])
          : formatDate(new Date(formData.date_range[0]));
      const to_date =
        formData.date_range[1] instanceof Date
          ? formatDate(formData.date_range[1])
          : formatDate(new Date(formData.date_range[1]));

      const response = await axios.post(
        `https://mottruck.com:3001/update-availability`,
        {
          ...formData,
          from_date,
          to_date,
        }
      );
      toast("Vehicle details updated successfully!");

      // Reset form data
      setFormData({
        ...formData,
        number_plate: "",
        // total_capacity: "",
        size_of_pallets: "",
        height_of_pallets: "",
        date_range: [new Date(), new Date()],
        location_name: "",
        latitude: "",
        longitude: "",
        city: "",
        country: "",
        country_short: "",
        truck_capacity: "",
        radius: "",
      });

      props.onHide();
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero based
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return (
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
      onExited={() => {
        setTimeout(() => {
          props.fetchLatestData();
        }, 0);
      }}
    >
      <Modal.Body>
        <h2>Edit Vehicle</h2>
        <BreadCrumbHeader image={DeliveryIcon} title="VEHICLE INFORMATIONS" />
        <h4 className="modal_label">
          Select a category <span className="requiresLable">*</span>
        </h4>
        <div className="tab-buttons">
          <Button
            onClick={() => handleTabClick("tab1")}
            className={activeTab === "tab1" ? "active" : ""}
          >
            General cargo
          </Button>
          <Button
            onClick={() => handleTabClick("tab2")}
            className={activeTab === "tab2" ? "active" : ""}
          >
            Tanker
          </Button>
          <Button
            onClick={() => handleTabClick("tab3")}
            className={activeTab === "tab3" ? "active" : ""}
          >
            Refrigerated
          </Button>
        </div>

        <div className="tab-content">
          {activeTab === "tab1" && (
            <>
              <div className="inputFlex">
                <div>
                  <TextInputWithLabel
                    name="number_plate"
                    placeholder="0"
                    label="Number plate"
                    reqstart="*"
                    value={formData.number_plate}
                    onChange={handleChange}
                    error={errors.number_plate}
                  />
                </div>
                <div className="kgflex position-relative">
                  <TextInputWithLabel
                    name="truck_capacity"
                    placeholder="0"
                    label="Truck capacity"
                    reqstart="*"
                    value={formData.truck_capacity}
                    onChange={handleChange}
                    error={errors.truck_capacity}
                  />
                  <span className="kgPos">Kg</span>
                </div>
                <div>
                  <TextInputWithLabel
                    name="size_of_pallets"
                    placeholder="0"
                    label="Size of pallets"
                    // reqstart="*"
                    value={formData.size_of_pallets}
                    onChange={handleChange}
                    error={errors.size_of_pallets}
                  />
                </div>
                <div>
                  <TextInputWithLabel
                    name="height_of_pallets"
                    placeholder="0"
                    label="Height of pallets"
                    value={formData.height_of_pallets}
                    onChange={handleChange}
                    error={errors.height_of_pallets}
                  />
                </div>
              </div>
              <h4 className="modal_label mb-4 mt-2">
                Enter availabilities and locations of your vehicle:
              </h4>
              <Row>
                <Col lg={6} md={6}>
                  <div className="datePickerContainer">
                    <label className="labelHead">Select Date Range</label>
                    <DatePicker
                      selectsRange
                      startDate={formData.date_range[0]}
                      endDate={formData.date_range[1]}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      isClearable
                      open={isCalendarOpen}
                      onClickOutside={() => setIsCalendarOpen(false)}
                      onInputClick={() => setIsCalendarOpen(true)}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="calenderBox">
                    <PlacesAutocomplete
                      value={formData.location_name}
                      onChange={(address) =>
                        setFormData({ ...formData, location_name: address })
                      }
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextInputWithLabel
                            {...getInputProps({
                              placeholder: "Enter a city",
                              className: "location-search-input",
                              label: "Truck location",
                              reqstart: "*",
                              error: errors.location_name, // Added error prop here
                            })}
                          />
                          {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <div className="kgflex position-relative mt-3">
                      <div>
                        <TextInputWithLabel
                          label="Around a radius"
                          placeholder="Enter a radius"
                          name="radius"
                          reqstart="*"
                          value={formData.radius}
                          onChange={handleChange}
                          error={errors.radius}
                        />
                      </div>
                      <span className="kgPos">Km</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {activeTab === "tab2" && (
            <>
              <div className="inputFlex inputFlexUpdate">
                <div>
                  <TextInputWithLabel
                    name="number_plate"
                    placeholder="0"
                    label="Number plate"
                    reqstart="*"
                    value={formData.number_plate}
                    onChange={handleChange}
                    error={errors.number_plate}
                  />
                </div>
                <div className="kgflex">
                  <TextInputWithLabel
                    name="truck_capacity"
                    placeholder="0"
                    label="Truck capacity"
                    reqstart="*"
                    value={formData.truck_capacity}
                    onChange={handleChange}
                    error={errors.truck_capacity}
                  />
                  <span className="kgPos">L</span>
                </div>
              </div>
              <h4 className="modal_label mb-4 mt-2">
                Enter availabilities and locations of your vehicle:
              </h4>
              <Row>
                <Col lg={6} md={6}>
                  <div className="datePickerContainer">
                    <label className="labelHead">Select Date Range</label>
                    <DatePicker
                      selectsRange
                      startDate={formData.date_range[0]}
                      endDate={formData.date_range[1]}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      isClearable
                    />
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="calenderBox">
                    <PlacesAutocomplete
                      value={formData.location_name}
                      onChange={(address) =>
                        setFormData({ ...formData, location_name: address })
                      }
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextInputWithLabel
                            {...getInputProps({
                              placeholder: "Enter a city",
                              className: "location-search-input",
                              label: "Truck location",
                              reqstart: "*",
                              error: errors.location_name, // Added error prop here
                            })}
                          />
                          {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <div className="kgflex position-relative mt-3">
                      <div>
                        <TextInputWithLabel
                          label="Around a radius"
                          placeholder="Enter a radius"
                          name="radius"
                          reqstart="*"
                          value={formData.radius}
                          onChange={handleChange}
                          error={errors.radius}
                        />
                      </div>
                      <span className="kgPos">Km</span>
                    </div>
                  </div>
                </Col>
              </Row>
             
            </>
          )}
          {activeTab === "tab3" && (
            <>
              <div className="inputFlex">
                <div>
                  <TextInputWithLabel
                    name="number_plate"
                    placeholder="0"
                    label="Number plate"
                    reqstart="*"
                    value={formData.number_plate}
                    onChange={handleChange}
                    error={errors.number_plate}
                  />
                </div>
                <div className="kgflex position-relative">
                  <TextInputWithLabel
                    name="truck_capacity"
                    placeholder="0"
                    label="Truck capacity"
                    reqstart="*"
                    value={formData.truck_capacity}
                    onChange={handleChange}
                    error={errors.truck_capacity}
                  />
                  <span className="kgPos">Kg</span>
                </div>
                <div>
                  <TextInputWithLabel
                    name="size_of_pallets"
                    placeholder="0"
                    label="Size of pallets"
                    // reqstart="*"
                    value={formData.size_of_pallets}
                    onChange={handleChange}
                    error={errors.size_of_pallets}
                  />
                </div>
                <div>
                  <TextInputWithLabel
                    name="height_of_pallets"
                    placeholder="0"
                    label="Height of pallets"
                    value={formData.height_of_pallets}
                    onChange={handleChange}
                    error={errors.height_of_pallets}
                  />
                </div>
              </div>
              <h4 className="modal_label mb-4 mt-2">
                Enter availabilities and locations of your vehicle:
              </h4>
              <Row>
                <Col lg={6} md={6}>
                  <div className="datePickerContainer">
                    <label className="labelHead">Select Date Range</label>
                    <DatePicker
                      selectsRange
                      startDate={formData.date_range[0]}
                      endDate={formData.date_range[1]}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      isClearable
                    />
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="calenderBox">
                    <PlacesAutocomplete
                      value={formData.location_name}
                      onChange={(address) =>
                        setFormData({ ...formData, location_name: address })
                      }
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextInputWithLabel
                            {...getInputProps({
                              placeholder: "Enter a city",
                              className: "location-search-input",
                              label: "Truck location",
                              reqstart: "*",
                              error: errors.location_name, // Added error prop here
                            })}
                          />
                          {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <div className="kgflex position-relative mt-3">
                      <div>
                        <TextInputWithLabel
                          label="Around a radius"
                          placeholder="Enter a radius"
                          name="radius"
                          reqstart="*"
                          value={formData.radius}
                          onChange={handleChange}
                          error={errors.radius}
                        />
                      </div>
                      <span className="kgPos">Km</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>

        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={props.onHide}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn-primaryAll btnPrimary"
            onClick={handleSubmit}
          >
            Update this vehicle
          </Button>
        </div>
      </Modal.Body>

    </Modal>
  );
}
