import React, { useEffect, useState } from "react";
import { PieChart, Pie, Tooltip } from "recharts";
import axios from "axios";

const SALES_COLOR_SCHEME = {
  generalCargo: "#df8244",
  tanker: "#4e73be",
  refrigerated: "#7eab55",
};

const DoughnutChart = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://mottruck.com:3001/doughnut-sales-graph"
        );
        if (response.data.status === 1) {
          const data = response.data.data;
          console.log("Fetched data:", data);
          const formattedData = data.map((item) => ({
            name:
              item.totalAmount != 0
                ? `${item.totalAmount.$numberDecimal} K€`
                : `${item.totalAmount} K€`,
            value: item.totalCount,
            fill:
              item._id === "General cargo"
                ? SALES_COLOR_SCHEME.generalCargo
                : item._id === "Tanker"
                ? SALES_COLOR_SCHEME.tanker
                : SALES_COLOR_SCHEME.refrigerated,
          }));
          console.log("Formatted data:", formattedData);
          setChartData(formattedData);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  const renderCustomizedLabel = ({ x, y, name, fill }) => (
    <text
      style={{ fontSize: 12, fontWeight: 500 }}
      x={fill === '#7eab55' ?  x +  55  : x}
      y={y}
      fill={fill}
      textAnchor="end"
      dominantBaseline="central"
    >
      {name}
    </text>
  );

  return (
    <div style={{ width: "70%", height: "10%" }}>
      {chartData.length > 0 ? (
        <PieChart width={400} height={260}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={chartData}
            cx="40%"
            cy="45%"
            innerRadius={50}
            outerRadius={85}
            label={renderCustomizedLabel}
          ></Pie>
          <Tooltip />
        </PieChart>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default DoughnutChart;
