import React, { createContext, useRef, useState } from "react";

const QuotesDataContext = createContext(null);
const { Provider } = QuotesDataContext;

const QuotesDataProvider = ({ children }) => {
  const [test, setTest] = useState("");
  const [Submit, setSubmit] = useState(false);

  const [activeTab, setActiveTab] = useState("");
  const [reference, setReference] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState();
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [pickupLocation, setPickupLocation] = useState(false);
  const [city, setCity] = useState("");
  const [loadingLatLong, setLoadingLatLong] = useState({});

  const [deliveryRefernce, setDeliveryRefernce] = useState("");
  const [deliveryCompanyName, setDeliveryCompanyName] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [deliveryContactName, setDeliveryContactName] = useState("");
  const [deliveryContactPhone, setDeliveryContactPhone] = useState("");
  const [deliveryContactEmail, setDeliveryContactEmail] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState(false);
  const [deliveryType, setDeliveryType] = useState(null);
  const [deliveryCity, setDeliveryCity] = useState("");
  const [deliveryLatLong, setDeliveryLatLong] = useState({});
  // address
  const [loadingState, setLoadingState] = useState("");
  const [loadingCity, setLoadingCity] = useState("");
  const [loadingPostcode, setLoadingPostcode] = useState("");
  const [loadingCountry, setLoadingCountry] = useState("");

  const [deliveryLoadingState, setDeliveryLoadingState] = useState("");
  const [deliveryLoadingCity, setDeliveryLoadingCity] = useState("");
  const [deliveryLoadingCountry, setDeliveryLoadingCountry] = useState("");
  const [deliveryLoadingPostcode, setDeliveryLoadingPostcode] = useState("");

  const [materialName, setMaterialName] = useState("");
  const [otherInformation, setOtherInformation] = useState("");
  // general
  const [numberOfPallets, setNumberOfPallets] = useState("");
  const [sizeOfPallets, setSizeOfPallets] = useState("");
  const [heightOfPallets, setHeightOfPallets] = useState("");
  const [totalWeightOfPallets, setTotalWeightOfPallets] = useState("");
  // tanker
  const [adrReference, setAdrReference] = useState("");
  const [quantity, setQuantity] = useState("");
  const [radio, setAdrRadio] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  // refigrater
  const [temprature, setTemperature] = useState("");
  const [selectMsds, setSelectMsds] = useState(false);
  // carriers
  const [selectCarriers, setSelectCarriers] = useState([]);
  const [selectedCarriers, setSelectedCarriers] = useState([]);
  const [selectedCarrierIds, setSelectedCarrierIds] = useState([]);

  // ADD Material
  const [materials, setMaterials] = useState([]);
  // Tanker
  const [savedMaterials, setSavedMaterials] = useState([]);
  // Ref
  const [refrigeratedMaterials, setRefrigeratedMaterials] = useState([]);

  const [numberValidationError, setNumberValidationError] = useState("");
  // user Detail
  const [accountDetail, setAccountDetail] = useState({});

  // PhoneNumber Code
  const [selectedCountryCode, setSelectedCountryCode] = useState("+33");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("+33");

  // For Staff - Search a client
  const [selectedCarrierID, setSelectedCarrierID] = useState("");
  const [fileName, setFileName] = useState("");

  return (
    <Provider
      value={{
        test,
        setTest,
        activeTab,
        setActiveTab,
        //Loading hooks
        pickupLocation,
        setPickupLocation,
        reference,
        setReference,
        companyName,
        setCompanyName,
        address,
        setAddress,
        date,
        setDate,
        time,
        setTime,
        contactName,
        setContactName,
        contactPhone,
        setContactPhone,
        contactEmail,
        setContactEmail,
        city,
        setCity,
        loadingLatLong,
        setLoadingLatLong,
        //delivery hooks
        deliveryLocation,
        setDeliveryLocation,
        deliveryType,
        setDeliveryType,
        deliveryRefernce,
        setDeliveryRefernce,
        deliveryCompanyName,
        setDeliveryCompanyName,
        deliveryAddress,
        setDeliveryAddress,
        deliveryDate,
        setDeliveryDate,
        deliveryTime,
        setDeliveryTime,
        deliveryContactName,
        setDeliveryContactName,
        deliveryContactPhone,
        setDeliveryContactPhone,
        deliveryContactEmail,
        setDeliveryContactEmail,
        deliveryCity,
        setDeliveryCity,
        deliveryLatLong,
        setDeliveryLatLong,
        // address
        loadingState,
        setLoadingState,
        loadingCity,
        setLoadingCity,
        loadingPostcode,
        setLoadingPostcode,
        loadingCountry,
        setLoadingCountry,
        deliveryLoadingState,
        setDeliveryLoadingState,
        deliveryLoadingCity,
        setDeliveryLoadingCity,
        deliveryLoadingCountry,
        setDeliveryLoadingCountry,
        deliveryLoadingPostcode,
        setDeliveryLoadingPostcode,

        //General cargo
        materialName,
        setMaterialName,
        numberOfPallets,
        setNumberOfPallets,
        sizeOfPallets,
        setSizeOfPallets,
        heightOfPallets,
        setHeightOfPallets,
        totalWeightOfPallets,
        setTotalWeightOfPallets,
        otherInformation,
        setOtherInformation,

        // tanker
        adrReference,
        setAdrReference,
        quantity,
        setQuantity,
        selectOptions,
        setSelectOptions,
        radio,
        setAdrRadio,
        // refrigerated
        temprature,
        setTemperature,
        selectMsds,
        setSelectMsds,
        //carrier
        selectCarriers,
        setSelectCarriers,
        selectedCarriers,
        setSelectedCarriers,
        selectedCarrierIds,
        setSelectedCarrierIds,
        // add material
        materials,
        setMaterials,
        numberValidationError,
        setNumberValidationError,
        savedMaterials,
        setSavedMaterials,
        refrigeratedMaterials,
        setRefrigeratedMaterials,
        accountDetail,
        setAccountDetail,
        // phoneNumberCode
        selectedCountryCode,
        setSelectedCountryCode,
        unLoadingCountryCode,
        setUnLoadingCountryCode,
        // For Staff - Search a client
        selectedCarrierID,
        setSelectedCarrierID,
        //for MS-- file if  have
        fileName,
        setFileName,
        // submit
        Submit,
        setSubmit,
      }}
    >
      {children}
    </Provider>
  );
};

export { QuotesDataProvider, QuotesDataContext };
